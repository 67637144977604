import React from 'react'

function ParentDashboard() {

    return (
        <div className="p-5">
            <h1 className="text-3xl font-bold mb-6 p-5">Enrolled Children (Guardian View)</h1>

            <div className="flex flex-wrap gap-12 my-8 p-4">
                <div className="w-1/2 md:w-1/4 bg-sky-200 text-lg p-4 shadow-md rounded-lg">
                    <p className='font-bold'>Name: FName LName</p>
                    <p>Grade: 1A</p>
                    <div className="flex justify-center mt-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 text-lg rounded">
                            View Child Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParentDashboard