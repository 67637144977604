import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { AuthContext } from '../../../contexts/auth';

// Toast component
function Toast({ message, visible, onClose }) {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [visible, onClose]);

    return visible ? (
        <div className="fixed top-16 right-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
            {message}
        </div>
    ) : null;
}

function StudentClassEnrollment() {
    const { user } = useContext(AuthContext);
    const [enrollments, setEnrollments] = useState([]);
    const [groupedEnrollments, setGroupedEnrollments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [students, setStudents] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [existingClasses, setExistingClasses] = useState([]); // For edit/remove modals
    const [selectedEnrollmentIds, setSelectedEnrollmentIds] = useState([]); // For removing classes
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [toastVisible, setToastVisible] = useState(false); 
    const [toastMessage, setToastMessage] = useState(''); 

    const enrollmentsPerPage = 10; // Show 10 records per page

    useEffect(() => {
        fetchEnrollments();
        fetchStudents();
        fetchClasses();
    }, []);

    const fetchEnrollments = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/enrollments`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            const enrollmentData = response.data;


            // Get unique grades from the response
            const uniqueGrades = [...new Set(enrollmentData.map(enrollment => enrollment.student_grade))];
            setGrades(uniqueGrades);

            // Group enrollments by student_id
            const grouped = enrollmentData.reduce((acc, enrollment) => {
                const existingStudent = acc.find(item => item.student_id === enrollment.student_id);
                if (existingStudent) {
                    existingStudent.classes.push({
                        class_id:enrollment.class_id,
                        course_name: enrollment.course_name,
                        period_name: enrollment.period_name,
                        enrollment_id:enrollment.enrollment_id,
                    });
                } else {
                    acc.push({
                        student_id: enrollment.student_id,
                        student_name: `${enrollment.student_first_name} ${enrollment.student_middle_name} ${enrollment.student_last_name}`,
                        student_grade: enrollment.student_grade,
                        classes: [{
                            class_id:enrollment.class_id,
                            course_name: enrollment.course_name,
                            period_name: enrollment.period_name,
                            enrollment_id:enrollment.enrollment_id,
                        }],
                    });
                }
                return acc;
            }, []);
            setEnrollments(grouped);
        } catch (error) {
            console.error('Error fetching enrollments:', error);
            setError('Failed to fetch classes');
        }finally {
            setLoading(false);
        }
    };
    const fetchStudents = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/students`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            setStudents(response.data);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const fetchClasses = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/classes`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
    
            // Filter for unique class_id
            const uniqueClasses = [];
            const seenClassIds = new Set();
    
            response.data.forEach((item) => {
                if (!seenClassIds.has(item.class_id)) {
                    seenClassIds.add(item.class_id);
                    uniqueClasses.push(item);
                }
            });
    
            setClasses(uniqueClasses);
        } catch (error) {
            console.error('Error fetching classes:', error);
        }finally {
            setLoading(false);
        }
    };
    

    // Pagination logic
    const indexOfLastEnrollment = currentPage * enrollmentsPerPage;
    const indexOfFirstEnrollment = indexOfLastEnrollment - enrollmentsPerPage;
    const currentEnrollments = groupedEnrollments.slice(indexOfFirstEnrollment, indexOfLastEnrollment);

    const handleEditClick = (student) => {
        resetStates(); // Reset before editing
        setSelectedStudent(student.student_id);
        setExistingClasses(student.classes);
        setEditModalIsOpen(true);
    };

    const handleDeleteClick = (student) => {
        resetStates(); // Reset before editing
        setSelectedStudent(student.student_id);
        setExistingClasses(student.classes);
        setDeleteModalIsOpen(true);
    };


    const handleNextPage = () => {
        if (currentPage < Math.ceil(groupedEnrollments.length / enrollmentsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSendClick = () => {
        resetStates(); // Reset before opening the modal
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    
    const handleEnrollSubmit = async () => {
        setLoading(true);
        try {
            const requestBody = {
                insert: selectedClasses.map(class_id => ({ class_id, student_id: selectedStudent })),
                delete: [] // No deletion on the enroll modal
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/v1/enrollments`, requestBody, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            fetchEnrollments();
            resetStates(); // clear states after submission
            setModalIsOpen(false);

               // Show success toast
               setToastMessage('Enrollment added successfully');
               setToastVisible(true);
        } catch (error) {
            setError('Error enrolling student:', error);
            console.error('Error enrolling student:', error);
        }finally {
            setLoading(false);
        }
    };

    const handleEditSubmit = async () => {
        setLoading(true);
        try {
            const requestBody = {
                insert: selectedClasses.map(class_id => ({ class_id, student_id: selectedStudent })),
                delete: [] // Add specific removal logic if needed
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/v1/enrollments`, requestBody, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            fetchEnrollments();
            resetStates(); // Clear states after submission
            setEditModalIsOpen(false);

               // Show success toast
               setToastMessage('Enrollment updated successfully');
               setToastVisible(true);
        } catch (error) {
            setError('Failed to update the enrollment because enrollment already exist');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteSubmit = async () => {
        setLoading(true);
        try {
            const requestBody = {
                insert: [],
                delete: selectedEnrollmentIds
            };
       
     

            await axios.post(`${process.env.REACT_APP_API_URL}/v1/enrollments`, requestBody, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            fetchEnrollments();
            resetStates(); // Clear states after deletion
            setDeleteModalIsOpen(false);

               // Show success toast
               setToastMessage('Enrollment removed successfully');
               setToastVisible(true);
        } catch (error) {
            console.error('Error deleting enrollments:', error);
            setError('Failed to delete the enrollments');
        } finally {
            setLoading(false);
        }
        
    };
     // Function to reset modal states after use
     const resetStates = () => {
        setSelectedStudent('');
        setSelectedClasses([]);
        setExistingClasses([]);
        setSelectedEnrollmentIds([]);
    };

    useEffect(() => {
        // Filter the enrollments by grade
        const filteredEnrollments = selectedGrade
            ? enrollments.filter(enrollment => enrollment.student_grade === Number(selectedGrade))
            : enrollments;
        setGroupedEnrollments(filteredEnrollments);
    }, [selectedGrade, enrollments]);


    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading enrollment details, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 text-center">{error}</div>;
    }

    return (
        <div className="p-5 ">
            <h1 className="text-4xl font-semibold mb-8 p-5">Student Class Enrollment</h1>

            {/* Filter by Grade */}
            <div className="mb-6">
                <label htmlFor="grade" className="mr-3">Filter by Grade:</label>
                <select
                    name="grade"
                    className="border border-gray-300 p-2 rounded"
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                >
                    <option value="">All Grades</option>
                    {grades.map((grade, index) => (
                        <option key={index} value={grade}>{grade}</option>
                    ))}
                </select>
            </div>

            {/* Enroll Student to Class Button */}
            <div className="flex items-center justify-between gap-4 mb-4">
                <button onClick={handleSendClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Enroll Student to Class
                </button>

                {/* Pagination Controls */}
                <div className="flex gap-4">
                    <button
                        onClick={handlePrevPage}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded"
                        disabled={currentPage >= Math.ceil(groupedEnrollments.length / enrollmentsPerPage)}
                    >
                        Next
                    </button>
                </div>
            </div>

          {/* List of Enrollments */}
<div className="flex flex-col max-h-[48vh] overflow-scroll">
    {currentEnrollments.map((enrollment, index) => (
        <div key={index} className="bg-slate-100 border rounded-md p-4 mb-2 flex justify-between items-center">
            <div>
                <h2 className="font-bold">{enrollment.student_name}</h2>
                <p>Enrolled classes:</p>
                <div className="grid grid-cols-3 gap-2">
                    {/* Check if there are classes */}
                    {enrollment.classes.length > 0 ? (
                        enrollment.classes.map((course, idx) => (
                            <p key={idx} className="text-md font-light">
                                {/* Check if course_name and period_name exist */}
                                {course.course_name ? (
                                    `${course.course_name} (${course.period_name || 'No period assigned'})`
                                ) : (
                                    'No classes assigned'
                                )}
                            </p>
                        ))
                    ) : (
                        <p className="text-md font-light">No classes assigned</p> 
                    )}
                </div>
            </div>
            <div>
                <button
                    onClick={() => handleEditClick(enrollment)}
                    className="bg-cyan-700 hover:bg-cyan-950 text-white font-bold py-1 px-4 rounded mr-2"
                >
                    Edit
                </button>
                <button
                    onClick={() => handleDeleteClick(enrollment)}
                    className="bg-red-700 hover:bg-red-950 text-white font-bold py-1 px-4 rounded"
                >
                    Remove
                </button>
            </div>
        </div>
    ))}
</div>



            {/* Enroll Student Modal */}
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Enroll Student Modal">
                <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
                    <h1 className="text-xl font-bold mb-4">Enroll Student to Class</h1>

                    {/* Grade Dropdown */}
                    <div className="p-3">
                        <label htmlFor="grade">Grade</label>
                        <select
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            onChange={(e) => setSelectedGrade(e.target.value)}
                        >
                            <option value="">Select Grade</option>
                            {grades.map((grade, index) => (
                                <option key={index} value={grade}>{grade}</option>
                            ))}
                        </select>
                    </div>

                    {/* Student Dropdown */}
                    <div className="p-3">
                        <label htmlFor="studentId">Student</label>
                        <select
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={selectedStudent}
                            onChange={(e) => setSelectedStudent(e.target.value)}
                        >
                            <option value="">Select Student</option>
                            {students
                                .filter((student) => student.student_grade === Number(selectedGrade))
                                .map((student) => (
                                    <option key={student.student_id} value={student.student_id}>
                                        {`${student.student_first_name} ${student.student_middle_name} ${student.student_last_name}`}
                                    </option>
                                ))}
                        </select>
                    </div>

                 
                    {/* Class Checkbox */}
                    <div className="p-3">
                        <label htmlFor="class">Class</label>
                        <div className="grid grid-cols-1 md:grid-col-2 xl:grid-cols-4">
                        {classes.map((cls) => (
                            <div key={cls.class_id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={cls.class_id}
                                    value={cls.class_id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedClasses([...selectedClasses, cls.class_id]);
                                        } else {
                                            setSelectedClasses(
                                                selectedClasses.filter((class_id) => class_id !== cls.class_id)
                                            );
                                        }
                                    }}
                                />
                                <label htmlFor={cls.class_id} className="ml-2 truncate">{cls.course_name}</label>
                            </div>
                        ))}
                        </div>
                    </div>

                    <div className='gap-3'>
                    <button onClick={handleEnrollSubmit} className="bg-green-700 hover:bg-green-950 text-white font-bold py-2 px-4 rounded mr-4">
                        Enroll
                    </button>

                    <button onClick={handleCloseModal} className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6">
                        Cancel
                    </button>
                    </div>
                </div>
            </Modal>

             {/* Edit Enrollment Modal */}
<Modal
  isOpen={editModalIsOpen}
  onRequestClose={() => setEditModalIsOpen(false)}
  contentLabel="Edit Enrollment Modal"
  className="w-[70%] mx-auto"
>
  <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
    <h1 className="text-xl font-bold mb-4">Edit Class Enrollments</h1>

    {/* Existing Classes */}
    <div className="p-3">
      <label htmlFor="class">Current Enrollments</label>
      {existingClasses.map((cls) => (
        <div key={cls.class_id} className="flex items-center">
          <input
            type="checkbox"
            id={cls.class_id}
            checked
            disabled
          />
          <label htmlFor={cls.class_id} className="ml-2 truncate">{cls.course_name}</label>
        </div>
      ))}
    </div>

    {/* New Classes Checkbox */}
    <div className="p-3">
      <label htmlFor="class">Add New Classes</label>
      <div className="grid grid-cols-1 md:grid-col-2 xl:grid-cols-4">
        {classes.map((cls) => {
          // Check if the current class is already enrolled
          const isAlreadyEnrolled = existingClasses.some(
            (existingClass) => existingClass.class_id === cls.class_id
          );
        

          return (
            <div key={cls.class_id} className="flex items-center">
              <input
                type="checkbox"
                id={cls.class_id}
                value={cls.class_id}
                disabled={isAlreadyEnrolled} // Disable the checkbox if the class is already enrolled
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedClasses([...selectedClasses, cls.class_id]);
                  } else {
                    setSelectedClasses(
                      selectedClasses.filter((class_id) => class_id !== cls.class_id)
                    );
                  }
                }}
              />
              <label
                htmlFor={cls.class_id}
                className={`ml-2 truncate ${isAlreadyEnrolled ? 'text-gray-400' : ''}`}
              >
                {cls.course_name}
              </label>
            </div>
          );
        })}
      </div>
    </div>

    <button
      onClick={handleEditSubmit}
      className="bg-blue-700 hover:bg-blue-950 text-white font-bold py-2 px-4 mr-4 rounded"
    >
      Update Enrollments
    </button>
    <button
      onClick={() => setEditModalIsOpen(false)}
      className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6"
    >
      Cancel
    </button>
  </div>
</Modal>

           {/* Remove Enrollment Modal */}
<Modal
  isOpen={deleteModalIsOpen}
  onRequestClose={() => setDeleteModalIsOpen(false)}
  contentLabel="Remove Enrollment Modal"
>
  <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
    <h1 className="text-xl font-bold mb-4">Remove Class Enrollments</h1>

    {/* Existing Classes with Removal Option */}
    <div className="p-3">
      <label htmlFor="class">Current Enrollments</label>
      {/** Filter out duplicate classes based on class_id */}
      {[...new Map(existingClasses.map((cls) => [cls.class_id, cls])).values()].map((cls) => (
        <div key={cls.class_id} className="flex items-center">
          <input
            type="checkbox"
            id={cls.class_id}
            value={cls.enrollment_id}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedEnrollmentIds([...selectedEnrollmentIds, cls.enrollment_id]);
              } else {
                setSelectedEnrollmentIds(
                  selectedEnrollmentIds.filter((id) => id !== cls.enrollment_id)
                );
              }
            }}
          />
          <label htmlFor={cls.class_id} className="ml-2">{cls.course_name}</label>
        </div>
      ))}
    </div>

    <button
      onClick={handleDeleteSubmit}
      className="bg-red-700 hover:bg-red-950 text-white font-bold py-2 px-4 mr-4 rounded"
    >
      Remove Selected Classes
    </button>
    <button
      onClick={() => setDeleteModalIsOpen(false)}
      className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6"
    >
      Cancel
    </button>
  </div>
</Modal>

              {/* Toast Notification */}
              <Toast message={toastMessage} visible={toastVisible} onClose={() => setToastVisible(false)} />
        </div>
    );
}

export default StudentClassEnrollment;
