import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

// Toast component
function Toast({ message, visible, onClose }) {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [visible, onClose]);

    return visible ? (
        <div className="fixed top-16 right-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
            {message}
        </div>
    ) : null;
}
function EditCourseModal({ editModalIsOpen, handleEditCloseModal, formData, handleFormChange, handleEditSubmit }) {
    return (
        <Modal isOpen={editModalIsOpen} onRequestClose={handleEditCloseModal} contentLabel="Edit Class Modal">
            <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                <form onSubmit={handleEditSubmit}>
                    <h2 className="text-xl font-bold mb-4">Edit Course</h2>

                {/* Course Name Input */}
                    <div className="p-3">
                        <label htmlFor="courseName">Course Name</label>
                        <input
                            id="courseName"
                            name="name"
                            type="text"
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={formData.name}
                            onChange={handleFormChange}
                        />
                    </div>


                      {/* Grade Level Input */}
                    <div className="p-3">
                        <label htmlFor="gradeLevel">Grade Level</label>
                        <input
                            id="gradeLevel"
                            name="grade_level"
                            type="number"
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={formData.grade_level}
                            onChange={handleFormChange}
                        />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div className="p-3">
                        <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                            Save Changes
                        </button>
                        <button type="button" onClick={handleEditCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

function AddCourseModal({ modalIsOpen, handleCloseModal, formData, handleFormChange, handleFormSubmit }) {
    return (
        <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add Class Modal">
            <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                <form onSubmit={handleFormSubmit}>
                    <h2 className="text-xl font-bold mb-4">Add New Course</h2>

                    {/* Course Name */}
                    <div className="p-3">
                        <label htmlFor="courseName">Course Name</label>
                        <input
                            id="courseName"
                            name="name"
                            type="text"
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={formData.name}
                            onChange={handleFormChange}
                        />
                    </div>

                   {/* Grade Level */}
                   <div className="p-3">
                        <label htmlFor="gradeLevel">Grade Level</label>
                        <input
                            id="gradeLevel"
                            name="grade_level"
                            type="number"
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={formData.grade_level}
                            onChange={handleFormChange}
                        />
                    </div>

                    {/* Submit and Cancel buttons */}
                    <div className="p-3">
                        <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                            Save
                        </button>
                        <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}


function Courses() {
    const { user } = useContext(AuthContext);
    const [classes, setClasses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        grade_level: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [courses, setCourses] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [editingClassId, setEditingClassId] = useState(null); 
    const [toastVisible, setToastVisible] = useState(false); 
    const [toastMessage, setToastMessage] = useState(''); 
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClasses = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/courses`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                });
                const data = response.data;
                setClasses(data);
                setFilteredClasses(data); // Set filtered data to all classes initially
                const uniqueSubjects = [...new Set(data.map((classItem) => classItem.course_name))];
                setSubjects(uniqueSubjects);
                const totalItems = data.length; // Total items available
                setTotalPages(Math.ceil(totalItems / limit));
            } catch (error) {
                setError('Failed to fetch classes');
                console.error('Failed to fetch classes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, [user.user_id, user.token,page,limit]); // Fetch classes on mount


    const handleEditClassClick = (classItem) => {
        setEditingClassId(classItem.course_id); // Store the class_id of the class to be edited
        setFormData({
            name: classItem.course_name,          
            grade_level: classItem.course_grade_level,  
        });
        setEditModalIsOpen(true);
    };

    const handleAddClassClick = () => {
        setModalIsOpen(true);
    };
    const handleEditCloseModal = () => {
        setEditModalIsOpen(false);
        setEditingClassId(null); 
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!editingClassId) return; // Ensure that we are editing an actual class

        setLoading(true);
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/v1/courses/${editingClassId}`,{
                name: formData.name,
                grade_level: formData.grade_level,
            }, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            setEditModalIsOpen(false);
            setFormData({ name: '', grade_level: '' });
            
            // Fetch updated classes and refresh the state
            const updatedClasses = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/courses`, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            setClasses(updatedClasses.data);
            setFilteredClasses(updatedClasses.data);

            // Show success toast
            setToastMessage('Course updated successfully');
            setToastVisible(true);
        } catch (error) {
            setError('Failed to update the course');
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/v1/courses`, formData, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            setModalIsOpen(false);
            setFormData({ name: '', grade_level: '' });
            console.log("🚀 ~ handleFormSubmit ~ formData:", formData)
            // Fetch the updated list of classes after adding
            const updatedClasses = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/courses`, {
                headers: { 'Authorization': `Bearer ${user.token}` },
            });
            setClasses(updatedClasses.data);
            setFilteredClasses(updatedClasses.data); // Update the filtered classes as well
             // Show success toast
             setToastMessage('Course added successfully');
             setToastVisible(true);
            console.log("Course added succesfully")
        } catch (error) {
            setError('Failed to add the Course');
        } finally {
            setLoading(false);
        }
    };

    // Scroll to top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Filtering by subject and searching
    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/courses`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });

            const data = response.data;
            let filtered = data;

            // Filter by subject
            if (selectedSubject) {
                filtered = filtered.filter(classItem => classItem.course_name === selectedSubject);
            }

            // Filter by search term
            if (searchTerm) {
                filtered = filtered.filter(classItem =>
                    classItem.course_name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }

            // Reset page and classes
            setFilteredClasses(filtered);
            setPage(1); // Reset pagination on new search
            setTotalPages(Math.ceil(filtered.length / limit));
            scrollToTop(); // Scroll to top after filtering/search
        } catch (error) {
            setError('Failed to fetch filtered courses');
            console.error('Failed to fetch filtered courses:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle pagination
    const handlePagination = async (newPage) => {
        setPage(newPage);
        scrollToTop(); // to make it scroll to top after pagination
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading Course details, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 text-center">{error}</div>;
    }

    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">List of Courses</h1>
            <div className="flex items-center gap-4 mb-4">
                {/* search by course */}
                <div className="w-1/4 pr-4">
                    <select
                        id="subjectSelect"
                        name="subject"
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedSubject}
                        onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                        <option value="">All Courses</option>
                        {subjects.map((subject, index) => (
                            <option key={index} value={subject}>
                                {subject}
                            </option>
                        ))}
                    </select>
                </div>
                {/* search by course name */}
                <div className="w-1/4">
                    <input
                        type="text"
                        placeholder="Search course by name..."
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <button
                    onClick={handleSearch}
                    className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded"
                >
                    Search
                </button>

                <div className="flex justify-end w-2/3">
                    <button onClick={handleAddClassClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                        Add New Course
                    </button>
                </div>
            </div>

            {/* List of classes */}
            <div className="flex flex-col">
                <div className="flex justify-between mb-4 mt-8">
                    <select value={limit} onChange={(e) => setLimit(Number(e.target.value))} className="border p-2">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                    <div>
                        <button
                            disabled={page <= 1}
                            onClick={() => handlePagination(page - 1)}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                        >
                            Prev
                        </button>
                        <button
                            disabled={page >= totalPages}
                            onClick={() => handlePagination(page + 1)}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    {filteredClasses.slice((page - 1) * limit, page * limit).map((classItem) => (
                        <div key={classItem.class_id} className="bg-slate-100 border rounded-md p-4 mb-2 flex justify-between items-center">
                            <div>
                                <h2 className="font-bold">{classItem.course_name}</h2>
                                
                                <p className='text-sm'> Grade Level: <span className='text-sm font-semibold'> {classItem.course_grade_level}</span></p>
                            </div>
                            <div>
                                <button
                                    className="bg-cyan-700 hover:bg-cyan-950 text-white font-bold py-1 px-4 rounded mr-2"
                                    onClick={() => handleEditClassClick(classItem)}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
             {/* Edit Class Modal */}
             
            <EditCourseModal
                editModalIsOpen={editModalIsOpen}
                handleEditCloseModal={handleEditCloseModal}
                formData={formData}
                handleFormChange={handleFormChange}
                handleEditSubmit={handleEditSubmit}
            />
        
            <AddCourseModal
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                formData={formData}
                handleFormChange={handleFormChange}
                handleFormSubmit={handleFormSubmit}
            />
    

            {/* Toast Notification */}
            <Toast message={toastMessage} visible={toastVisible} onClose={() => setToastVisible(false)} />
        </div>
    );
}

export default Courses;
