import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Layout from './layout';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import languages from './languages.json';
import { AuthProvider } from './contexts/auth';
import Modal from 'react-modal';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "ao",
  fallbackLng: 'en',
  resources: languages
});

Modal.setAppElement('#root');

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <I18nextProvider i18n={i18next}>
            <Layout />
          </I18nextProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);