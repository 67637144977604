import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../../contexts/auth';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Period() {
    const {user, token} = useContext(AuthContext);
    const [periods, setPeriods] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [editPeriod, setEditPeriod] = useState('');
    const [editStartTime, setEditStartTime] = useState('');
    const [editEndTime, setEditEndTime] = useState('');
    const [newPeriod, setNewPeriod] = useState('');
    const [newStartTime, setNewStartTime] = useState('');
    const [newEndTime, setNewEndTime] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch period data
    useEffect(() => {
        const fetchPeriods = async () => {
            if (user && token) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/v1/periods`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    setPeriods(response.data);
                } catch (error) {
                    console.error("Failed to fetch period data. Please try again later.");
                } finally {
					setLoading(false);
				}
            }
        };

        fetchPeriods();
    }, [user, token]);

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleCloseEditModal = () => {
        setEditModalIsOpen(false);
    };

    const handleEditButtonClick = (period) => {
        setSelectedPeriod(period);
        setEditPeriod(period.period);
        setEditStartTime(period.start_time);
        setEditEndTime(period.end_time);
        setEditModalIsOpen(true);
    };

    // Convert time to 24-hour format to send to the API
    const convertTo24HourFormat = (time) => {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}:00`;
    };

    // Conver time to 12-hour fomat to display in the UI
    const convertTo12HourFormat = (time) => {
        let [hours, minutes] = time.split(':');
        const modifier = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        return `${hours}:${minutes} ${modifier}`;
    };
    
    // Handle form submission to edit a period
    const handleEditFormSubmit = async (e) => {
        e.preventDefault();
        if (selectedPeriod && token) {
            try {
                await axios.patch(
                    `${process.env.REACT_APP_API_URL}/v1/periods/${selectedPeriod.id}`,
                    {
                        period: editPeriod,
                        start_time: convertTo24HourFormat(editStartTime),
                        end_time: convertTo24HourFormat(editEndTime),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                // Update the periods state with the edited period
                setPeriods(periods.map(p => p.id === selectedPeriod.id ? { ...p, period: editPeriod, start_time: convertTo24HourFormat(editStartTime), end_time: convertTo24HourFormat(editEndTime) } : p));
                setEditModalIsOpen(false);
                toast.success("Period updated successfully!");
            } catch (error) {
                console.error("Failed to update period. Please try again later.");
                toast.error("Failed to update period. Please try again later.");
            }
        }
    };

    // Handle form submission to add a new period
    const handleAddFormSubmit = async (e) => {
        e.preventDefault();
        if (token) {
            const newPeriodData = [{
                period: newPeriod,
                start_time: convertTo24HourFormat(newStartTime),
                end_time: convertTo24HourFormat(newEndTime),
            }];
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/v1/periods`,
                    newPeriodData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                // Add the new period to the periods state
                setPeriods([...periods, response.data]);
                setModalIsOpen(false);
                setNewPeriod('');
                setNewStartTime('');
                setNewEndTime('');
                toast.success("New period added successfully!");
            } catch (error) {
                console.error("Failed to add new period. Please try again later.", error);
                toast.error("Failed to add new period. Please try again later.");
                if (error.response) {
                    console.error('Response data:', error.response.data); // Log the response data
                }
            }
        }
    };

    if (loading) {
		return (
			<div className="flex items-center justify-center min-h-screen">
				<div className="flex flex-col items-center">
					<p className="text-lg font-semibold text-gray-600">
						Loading Period Schedule...
					</p>
				</div>
			</div>
		);
	}

    return (
        <div className="p-5">
            <ToastContainer />
            <h1 className="text-4xl font-semibold mb-2 p-5">Period Schedule</h1>
            <div className="flex items-center gap-4 mb-2 justify-end">
                <button onClick={handleOpenModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Add New Period
                </button>
            </div>

            {/* List of classes */}
            <div className="flex flex-col">
                {periods.length > 0 ? (
                    periods
                        .sort((a, b) => a.period - b.period) // Sort periods by period number
                        .map(period => (
                            <div key={period.id} className="flex flex-col">
                                <div className="bg-slate-100 border rounded-md p-4 mb-2 flex justify-between items-center">
                                    <div>
                                        <h2 className="font-bold">Period {period.period}</h2>
                                        <p className='text-sm font-semibold'>Time: {convertTo12HourFormat(period.start_time)} - {convertTo12HourFormat(period.end_time)}</p>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => handleEditButtonClick(period)}
                                            className="bg-cyan-700 hover:bg-cyan-950 text-white font-bold py-1 px-4 rounded mr-2">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                ) : (
                    <p>No periods available.</p>
                )}
            </div>

            {/* Adding new period */}
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add Period Modal">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <form onSubmit={handleAddFormSubmit}>
                        <h2 className="text-xl font-bold mb-4">Add New Period</h2>

                        <div className="p-3">
                            <label>Period</label>
                            <input
                                type="number"
                                min="1"
                                value={newPeriod}
                                onChange={(e) => setNewPeriod(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="startTime">Start Time</label>
                            <input
                                type='time'
                                value={newStartTime}
                                onChange={(e) => setNewStartTime(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>
                        <div className="p-3">
                            <label htmlFor="endTime">End Time</label>
                            <input
                                type='time'
                                value={newEndTime}
                                onChange={(e) => setNewEndTime(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>

                        <div className="p-3">
                            <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                                Save
                            </button>
                            <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Editing period */}
            <Modal isOpen={editModalIsOpen} onRequestClose={handleCloseEditModal} contentLabel="Edit Period Modal">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <form onSubmit={handleEditFormSubmit}>
                        <h2 className="text-xl font-bold mb-4">Edit Period</h2>

                        <div className="p-3">
                            <label>Period</label>
                            <input
                                type="number"
                                min="1"
                                value={editPeriod}
                                onChange={(e) => setEditPeriod(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="startTime">Start Time</label>
                            <input
                                type='time'
                                value={editStartTime}
                                onChange={(e) => setEditStartTime(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>
                        <div className="p-3">
                            <label htmlFor="endTime">End Time</label>
                            <input
                                type='time'
                                value={editEndTime}
                                onChange={(e) => setEditEndTime(e.target.value)}
                                className="border border-gray-300 p-2 rounded block w-1/3"
                            />
                        </div>

                        <div className="p-3">
                            <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                                Save
                            </button>
                            <button type="button" onClick={handleCloseEditModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default Period;