import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";

function AdminStudentTimetable() {
    const { user, token } = useContext(AuthContext);
    const [uniqueGrades, setUniqueGrades] = useState([]);
    const [uniqueNames, setUniqueNames] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [displayData, setDisplayData] = useState({});
    const [timeTableData, setTimeTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uniquePeriods, setUniquePeriods] = useState([]);

    const sortedDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    useEffect(() => {
        const fetchTimetable = async () => {
            try {
                const timetableResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/timetable?view=students`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const timetableData = await timetableResponse.json();
                setTimeTableData(timetableData);

                const uniqueGrades = [...new Set(timetableData.map(student => student.student_grade_level))].sort((a, b) => a - b);
                setUniqueGrades(uniqueGrades);
            } 
            catch (error) {
                console.error('Error:', error);
            } 
            finally {
                setLoading(false);
            }
        };
        fetchTimetable();
    }, [token]);

    useEffect(() => {
        if (selectedGrade) {
            const timetableOfSelectedGrade = timeTableData.filter(student => student.student_grade_level === Number(selectedGrade));
            const namesForGrade = timetableOfSelectedGrade.map(student => 
                `${student.student_first_name?.trim()} ${student.student_middle_name?.trim()} ${student.student_last_name?.trim()}`
            );
            setUniqueNames([...new Set(namesForGrade)]);
        } 
        else {
            setUniqueNames([]);
        }
    }, [selectedGrade, timeTableData]);

    useEffect(() => {
        if (selectedName) {
            const student = timeTableData.find(student => 
                `${student.student_first_name} ${student.student_middle_name} ${student.student_last_name}` === selectedName
            );

            if (student) {
                const intersectionData = {};
                const periodsSet = new Set();

                student.timetable.forEach(schedule => {
                    const day = schedule.day_of_week;
                    const period = schedule.period;

                    if (!intersectionData[day]) {
                        intersectionData[day] = {};
                    }
                    intersectionData[day][period] = schedule;
                    periodsSet.add(period);
                });

                setDisplayData(intersectionData);
                // Sort periods based on start_time
                const sortedPeriods = Array.from(periodsSet).sort((a, b) => {
                    const aSchedule = student.timetable.find(schedule => schedule.period === a);
                    const bSchedule = student.timetable.find(schedule => schedule.period === b);
                    return aSchedule.start_time.localeCompare(bSchedule.start_time);
                });
                setUniquePeriods(sortedPeriods);
            }
        } else {
            setDisplayData({});
            setUniquePeriods([]);
        }
    }, [selectedName, timeTableData]);

    const handleGradeChange = (e) => {
        setSelectedGrade(e.target.value);
        setSelectedName('');
    };

    const handleNameChange = (e) => {
        setSelectedName(e.target.value);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading student timetable, please wait...
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="mt-8 p-5">
            <h2 className="text-4xl text-center font-bold mb-12">Student Timetable</h2>

            {/* Container for dropdowns */}
            <div className="flex space-x-4 mb-4">
                {/* Select student by grade */}
                <div className="w-1/4">
                    <select id="gradeSelect" className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded" onChange={handleGradeChange} value={selectedGrade}>
                        <option value="">Select Grade</option>
                        {uniqueGrades.map(grade => (
                            <option key={grade} value={grade}>Grade {grade}</option>
                        ))}
                    </select>
                </div>

                {/* Select student by name */}
                <div className="w-1/4">
                    <select id="nameSelect" className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded" onChange={handleNameChange} value={selectedName}>
                        <option value="">Select Student</option>
                        {uniqueNames.map(name => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex flex-row flex-nowrap gap-4 overflow-x-auto">
                {sortedDays.map(day => (
                    <div key={day} className="flex flex-col items-center bg-sky-200 p-4 w-1/6 min-w-[150px]">
                        <div className="font-bold text-lg">{day.charAt(0).toUpperCase() + day.slice(1)}</div>
                        {uniquePeriods.map(period => (
                            <div key={period} className="mt-2 bg-sky-50 p-4 w-full text-center">
                                {displayData[day] && displayData[day][period] ? (
                                    <div>
                                        <p className="text-sm font-light">Period - {period}</p>
                                        <p className='font-bold'>{displayData[day][period].course_name}</p>
                                        <p className="text-sm font-light">{`${displayData[day][period].start_time} - ${displayData[day][period].end_time}`}</p>
                                        <p className='text-sm font-light'>Room {`${displayData[day][period].room_number}`}</p>
                                        <p className='text-sm font-light'>{displayData[day][period].teacher_first_name} {displayData[day][period].teacher_middle_name}</p>
                                    </div>
                                ) : (
                                    <p>No Class</p>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminStudentTimetable;