import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';

function TeacherClassDetail() {

    // Modal - for pop up
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);

    const handleOpenAddModal = () => setAddModalIsOpen(true);
    const handleCloseAddModal = () => setAddModalIsOpen(false);

    const handleOpenViewModal = () => setViewModalIsOpen(true);
    const handleCloseViewModal = () => setViewModalIsOpen(false);

    return (
        <div className="p-5">
            <h1 className="text-3xl font-bold mb-6 p-5">Math 5A</h1>

            <div className="flex justify-end p-5 space-x-4">
                <button onClick={handleOpenAddModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Add New Assignment
                </button>
            </div>

            {/* Add New Assignment popup */}
            <Modal isOpen={addModalIsOpen} onRequestClose={handleCloseAddModal} contentLabel="Assignment Modal" >
                <div className="bg-white p-5">
                    <h1 className="text-xl font-bold mb-4 text-center">Add New Assignment</h1>
                    
                    <div className="mb-4">
                        <label className="block mb-2">Category:</label>
                        <select className="block w-1/3 p-2 border border-gray-300 rounded">
                            <option value="">Select a category</option>
                            <option value="homework">Homework</option>
                            <option value="quiz">Quiz</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Assignment Title:</label>
                        <input className="block w-1/3 p-2 border border-gray-300 rounded"/>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Due Date:</label>
                        <input type="date" className="block w-1/3 p-2 border border-gray-300 rounded"/>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Maximum Point:</label>
                        <input type="number" className="block w-1/3 p-2 border border-gray-300 rounded"/>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Content:</label>
                        <textarea className="block w-full p-2 border border-gray-300 rounded h-40"/>
                    </div>

                    <button className="border border-cyan-800 hover:bg-cyan-500 text-black py-2 px-4 rounded block mb-4">
                        Upload Document
                    </button>

                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded">
                            Add Assignment
                        </button>
                        <button type="button" onClick={handleCloseAddModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </div>      
            </Modal>

            {/* Up Coming Assignment */}
            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                                Up Coming Assignments
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                                <div>
                                    <p>Sample Homework 1</p>
                                    <p className='text-sm text-gray-600'>Due Date: Jan 1 | 10 pts</p>
                                </div>
                                <button onClick={handleOpenViewModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                    View
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Past Assignment */}
            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                                Past Assignments
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                            <div>
                                <p>Sample Homework 1</p>
                                <p className='text-sm text-gray-600'>Due Date: Jan 1 | 10 pts</p>
                            </div>
                                <button onClick={handleOpenViewModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                    View
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* View Assignment popup */}
            <Modal isOpen={viewModalIsOpen} onRequestClose={handleCloseViewModal} contentLabel="View Assignment Modal" >
                <div className="bg-white p-5">
                    <h1 className="text-xl font-bold mb-4 text-center">Sample Assignment</h1>
                    <div className="flex items-center space-x-2 mb-6">
                        <p className="font-bold"> Category:</p>
                        <p>Homework</p>
                    </div>      
                    <div className="flex items-center space-x-2 mb-6">
                        <p className="font-bold">Assignment Title:</p>
                        <p>Sample Homework 1</p>
                    </div>
                    <div className="flex items-center space-x-2 mb-6">
                        <p className="font-bold">Due Date:</p>
                        <p>01/01/2025</p>
                    </div>
                    <div className="flex items-center space-x-2 mb-6">
                        <p className="font-bold">Maximum Point:</p>
                        <p>10</p>
                    </div>
                    <div className="flex items-center space-x-2 mb-8">
                        <p className="font-bold">Assignment Detail: </p>
                        <p>This is a sample homework.</p>
                    </div>
                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded">
                            Edit Assignment
                        </button>
                        <button type="button" onClick={handleCloseViewModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </div>      
            </Modal>
        </div>
    )
}

export default TeacherClassDetail