import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";

function TeacherTimetable() {
    const { user, token } = useContext(AuthContext);
    const [classes, setClasses] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [uniquePeriods, setUniquePeriods] = useState([]);

    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const ttResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!ttResponse.ok) {
                    throw new Error('Failed to fetch classes');
                }

                const ttData = await ttResponse.json();
                setClasses(ttData);
                tabulateSchedule(ttData);
            } 
            catch (error) {
                console.error('Error fetching classes:', error);
            }
        };
        fetchClasses();
    }, [token, user.user_id]);

    const tabulateSchedule = (classData) => {
        const periods = new Set();
        classData.forEach(cls => {
            periods.add(cls.period);
        });
        const uniquePeriodsArray = Array.from(periods).sort((a, b) => a.localeCompare(b));
        setUniquePeriods(uniquePeriodsArray);

        // Formatting timetable content
        const tabulatedSchedule = daysOfWeek.map(day => {
            const dayClasses = classData.filter(cls => cls.day_of_week.toLowerCase() === day.toLowerCase());

            return {
                day,
                periods: uniquePeriodsArray.map(period => {
                    return dayClasses.filter(cls => cls.period === period);
                }),
            };
        });
        setSchedule(tabulatedSchedule);
    };

    return (
        <div className="mt-8 p-5">
            <h2 className="text-4xl text-center font-semibold mb-12">Class Schedule</h2>

            <div className="flex flex-row flex-nowrap gap-4 overflow-x-auto">
                {daysOfWeek.map(day => (
                    <div key={day} className="flex flex-col items-center bg-sky-200 p-4 w-1/6 min-w-[150px]">
                        <div className="font-bold text-lg">{day.charAt(0).toUpperCase() + day.slice(1)}</div>
                        {uniquePeriods.map((period, index) => (
                            <div key={period} className="mt-2 bg-sky-50 p-4 w-full text-center">
                                {schedule.length > 0 && schedule.find(d => d.day === day).periods[index].length > 0 ? (
                                    schedule.find(d => d.day === day).periods[index].map((classDetail, classIndex) => (
                                        <div key={classIndex} className="mt-2">
                                            <p className='font-semi-bold'>Period {period}</p>
                                            <p className='font-bold'>{classDetail.course_name}</p>
                                            <p className="text-sm font-light">{classDetail.start_time} - {classDetail.end_time}</p>
                                            <p className='text-sm font-light'>{classDetail.room_number}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>No Class</p>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeacherTimetable;