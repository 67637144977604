import React, { useState, useContext, useRef, useEffect } from "react";
import { SidebarContext } from "../contexts/sidebar";
import { AuthContext } from "../contexts/auth";

import { VscThreeBars } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header() {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { user, setUser } = useContext(AuthContext);
	const {
		isNavigationSidebarOpen,
		setNavigationSidebarOpen,
	} = useContext(SidebarContext);

	const [menuOpen, setMenuOpen] = useState(false);
	const [stationName, setStationName] = useState(false);

	const menuRef = useRef();

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	function handleLogout(event) {
		localStorage.removeItem("user");
		console.log("the user is removed by choice", user);
		setUser(null);
		// navigate('/signin');
		setMenuOpen(!menuOpen);
	}

	function ContextMenu() {
		return (
			<div
				ref={menuRef}
				className="absolute right-2 mt-32 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
			>
				<div
					className="py-1"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					<button
						className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
						role="menuitem"
						onClick={() => navigate("/profile")}
					>
						<VscThreeBars className="h-6 w-6" />
						<p>Update profile</p>
					</button>
					<button
						className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
						role="menuitem"
						onClick={handleLogout}
					>
						<VscThreeBars className="h-6 w-6" />
						<p>Logout</p>
					</button>
				</div>
			</div>
		);
	}

	return (
		<>
			<header className="flex items-center justify-between p-2 bg-cyan-950 text-white">
				<div className="flex items-center space-x-2">
					<button
						className="text-white"
						onClick={() => setNavigationSidebarOpen(!isNavigationSidebarOpen)}
					>
						<VscThreeBars className="h-6 w-6" />
					</button>
					<p>{user.school_name + " | " + (user.role_name.charAt(0).toUpperCase() + user.role_name.slice(1))}</p>
				</div>
				<div className="flex space-x-5">
					<select
						className={`text-white bg-cyan-950 py-2 focus:outline-none `}
						value={i18n.language}
						onChange={(e) => i18n.changeLanguage(e.target.value)}
					>
						<option value="en">English</option>
						<option value="am">Amharic</option>
						<option value="or">Afaan Oromo</option>
					</select>
					<button
						className="flex items-center text-white space-x-4"
						onClick={() => setMenuOpen(!menuOpen)}
					>
						<p>{user.first_name + " " + user.middle_name}</p>
						<img
							className="w-14 h-14 rounded-full cursor-pointer"
							src={
								process.env.REACT_APP_API_URL +
								"/v1" +
								user.user_profile.image_profile
							}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = "SiyahaSmallLogo.png";
							}}
							alt="Circle Image"
						/>
					</button>
				</div>
				{menuOpen && <ContextMenu />}
			</header>
		</>
	);
}

export default Header;
