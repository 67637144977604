import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/auth";

function StudentAttendance() {
	const { user, token } = useContext(AuthContext); // Fetch user and token from context
	const [attendances, setAttendances] = useState([]);
	const [filteredAttendances, setFilteredAttendances] = useState([]);
	const [selectedClass, setSelectedClass] = useState("");
	const [selectedMonth, setSelectedMonth] = useState("");
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Fetch attendance data on component mount
	useEffect(() => {
		const fetchAttendance = async () => {
			if (user && token) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/attendances`,
						{
							headers: {
								Authorization: `Bearer ${token}`, // Add authorization header
							},
						},
					);
					setAttendances(response.data);
				} catch (error) {
					setError("Failed to fetch attendance data. Please try again later.");
				} finally {
					setLoading(false);
				}
			}
		};

		fetchAttendance();
	}, [user, token]);

	// Filter attendances by selected class and month
	useEffect(() => {
		if (selectedClass && selectedMonth) {
			const filtered = attendances.filter((att) => {
				const classMatches = selectedClass
					? att.course_name === selectedClass
					: true;
				const monthMatches = selectedMonth
					? // biome-ignore lint/style/useNumberNamespace: <explanation>
						new Date(att.date).getMonth() + 1 === parseInt(selectedMonth)
					: true;

				return classMatches && monthMatches;
			});
			setFilteredAttendances(filtered);
		}
	}, [selectedClass, selectedMonth, attendances]);

	// Handle class selection
	const handleClassChange = (e) => {
		setSelectedClass(e.target.value);
	};

	// Handle month selection
	const handleMonthChange = (e) => {
		setSelectedMonth(e.target.value);
	};

	if (loading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<p className="text-xl text-gray-600">Loading attendance...</p>
			</div>
		);
	}

	if (error) {
		return <p className="text-red-500 text-center">{error}</p>;
	}

	return (
		<div className="p-5">
			<h1 className="text-4xl font-bold mb-6 p-5">Attendance</h1>

			{/* Select Class */}
			<div className="flex items-center gap-4 mb-4">
				<div className="p-4 w-1/4">
					<select
						id="classSelect"
						name="class"
						className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
						onChange={handleClassChange}
						value={selectedClass}
					>
						<option value="">Select Class</option>
						{/* Dynamic class options */}
						{[...new Set(attendances.map((att) => att.course_name))].map(
							(course, index) => (
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								<option key={index} value={course}>
									{course}
								</option>
							),
						)}
					</select>
				</div>

				{/* Select Month */}
				<div className="p-4 w-1/4">
					<select
						id="monthSelect"
						name="month"
						className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
						onChange={handleMonthChange}
						value={selectedMonth}
					>
						<option value="">Select a Month</option>
						{Array.from({ length: 12 }, (_, i) => (
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							<option key={i + 1} value={i + 1}>
								{new Date(0, i).toLocaleString("en-US", { month: "long" })}
							</option>
						))}
					</select>
				</div>
			</div>

			{/* Show attendance for the selected class and month */}
			<div className="p-4">
				<table className="w-2/3">
					<thead>
						<tr>
							<th className="py-3 p-2 text-left">Date</th>
							<th className="py-3 p-2 text-left">Attendance</th>
						</tr>
					</thead>
					<tbody>
						{selectedClass &&
						selectedMonth &&
						filteredAttendances.length > 0 ? (
							filteredAttendances.map((attendance) => (
								<tr
									key={attendance.attendance_id}
									className="odd:bg-slate-100 even:bg-white"
								>
									<td className="py-3 p-2">
										{new Date(attendance.date).toLocaleDateString()}
									</td>
									<td className="py-3 p-2 capitalize">{attendance.status}</td>
								</tr>
							))
						) : selectedClass && selectedMonth ? (
							<tr>
								<td colSpan="2" className="py-3 p-2 text-center">
									No attendance records found
								</td>
							</tr>
						) : (
							<tr>
								<td colSpan="2" className="py-3 p-2 text-center">
									Please select a class and month
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default StudentAttendance;
