import React, { useState, useEffect, useContext } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { AuthContext } from "../../../contexts/auth";

function TeacherCalendar() {
    const { user, token } = useContext(AuthContext);
    const [upcomingAssignments, setUpcomingAssignments] = useState([]);
    const [loading, setLoading] =useState(true); //set loading status to true
    const [currentDate, setCurrentDate] = useState(new Date());

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const firstDayOfMonth = () => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    };

    const renderDaysOfWeek = () => {
        return dayNames.map(day => (
            <div key={day} className="border p-4 font-semibold bg-slate-200">
                {day}
            </div>
        ));
    };

    const renderCalendarDays = () => {
        const days = [];
        const numDays = daysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDay = firstDayOfMonth();

        for (let i = 0; i < firstDay; i++) {
            days.push(<div key={`empty-${i}`} className="border p-4 bg-gray-50"></div>);
        }
        for (let day = 1; day <= numDays; day++) {
            days.push(
                <div key={day} className={`border p-4 ${day === currentDate.getDate() ? 'bg-blue-200' : 'bg-white'}`}>
                    {day}
                </div>
            );
        }
        return days;
    };

    const handlePreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    useEffect(() => {
        const fetchUpcomingAssignments = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/assignments`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Sorry, data fetching unsuccessful');
                }

                const calendarResponse = await response.json();
                setUpcomingAssignments(calendarResponse);
               console.log(calendarResponse);
            } 
            catch (error) {
                console.error("Error fetching assignments:", error);
            }
            finally{
                setLoading(false);
            }
        };

        fetchUpcomingAssignments();
    }, []);

    /*Filtering assignment with selected months due date */

    const filteredAssignments = upcomingAssignments.filter(assignment => {
        const dueDate = new Date(assignment.assignment_due_date);
            return dueDate.getFullYear() === currentDate.getFullYear() && dueDate.getMonth() === currentDate.getMonth();
        });

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading, please wait...
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-col items-center">
                <div className="mt-12 w-full max-w-4xl">
                    <div className="flex justify-between items-center mb-4">
                        <button onClick={handlePreviousMonth} className="p-4 bg-slate-200 rounded"> <FaArrowLeft size={20} /> </button>
                        <h2 className="text-4xl font-semibold text-center">
                            {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
                        </h2>
                        <button onClick={handleNextMonth} className="p-4 bg-slate-200 rounded"> <FaArrowRight size={20} /> </button>
                    </div>
                    <div className="grid grid-cols-7 gap-4 mt-3">
                        {renderDaysOfWeek()}
                        {renderCalendarDays()}
                    </div>
                </div>
            </div> 

            {/* Up coming Exam for that month */}
            <div className="bg-slate-200 mt-8 ml-8 mr-8 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold mb-4">Up Coming Exam/Quiz for This Month</h2>
                <table className="w-full">
                    <thead>
                        <tr className="text-left">
                            <th>No.</th>
                            <th>Title</th>
                            <th>Class</th>
                            <th>Due Date</th>
                            <th>Point</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredAssignments.map((assignment, index) => (
                        <tr key={index} className="border-b border-gray-200">
                            <td className="py-2">{index +1}</td>
                            <td className="py-2">{assignment.assignment_name}</td>
                            <td className="py-2">{assignment.course_name}</td>
                            <td className="py-2">{new Date(assignment.assignment_due_date).toLocaleDateString()}</td>
                            <td className="py-2">{assignment.assignment_total} pts</td>
                        </tr>
                    ))}
                    {filteredAssignments.length === 0 && (
                            <tr>
                                <td colSpan="4" className="py-2 text-center">No upcoming assignments for {currentDate.toLocaleString('default', { month: 'long' })} of {currentDate.getFullYear()} Academic year</td>
                            </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TeacherCalendar;