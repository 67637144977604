import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../../../contexts/auth';

function TeacherGradeReport() {
    const { user, token } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [classes, setClasses] = useState([]);
    const [uniqueClasses, setUniqueClasses] = useState([]);
    const [grades, setGrades] = useState([]);
    const [filteredGrade, setFilteredGrade] = useState([]);
    const [totalScore, setTotalScore] = useState([]);
    const [classTotalScore, setClassTotalScore] = useState(0);
    const [classTotalMaximumPoint, setClassTotalMaximumPoint] = useState(0);
    const [classScorePercentage, setClassScorePercentage] = useState(0);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
    const [studentAssignments, setStudentAssignments] = useState([]);
    const [studentGrades, setStudentGrades] = useState([]);
    const [sumScore, setSumScore] = useState(0);
    const [totalMaxScore, setTotalMaxScore] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState(0);

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch classes');
                const clssData = await response.json();
                setClasses(clssData);
            } 
            catch (error) {
                console.error('Error fetching classes:', error);
            } 
            finally {
                setLoading(false);
            }
        };
        fetchClasses();
    }, [user.user_id, token]);

    useEffect(() => {
        const uniqueClasses = Array.from(new Set(classes.map(clss => clss.class_id)))
            .map(id => classes.find(clss => clss.class_id === id));
        setUniqueClasses(uniqueClasses);
    }, [classes]);

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/grades`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch grades');
                const gradeData = await response.json();
                setGrades(gradeData);
            } 
            catch (error) {
                console.error('Error fetching grades:', error);
            }
        };
        fetchGrades();
    }, [user.user_id, token]);

    const handleClassSelect = (e) => {
        const selectedClassId = e.target.value;
        const filteredGrades = grades.filter(grade => grade.class_id === selectedClassId);
        setFilteredGrade(filteredGrades);
    };

    useEffect(() => {
        const totalGradeCalculator = filteredGrade.reduce((acc, current) => {
            const key = current.student_enrollment_id;
            if (!acc[key]) {
                acc[key] = {
                    student_enrollment_id: current.student_enrollment_id,
                    student_first_name: current.student_first_name,
                    student_middle_name: current.student_middle_name,
                    student_last_name: current.student_last_name,
                    course_name: current.course_name,
                    grade_score_sum: 0,
                    assignment_total_sum: 0
                };
            }
            acc[key].grade_score_sum += current.grade_score;
            acc[key].assignment_total_sum += current.assignment_total;
            return acc;
        }, {});

        setTotalScore(Object.values(totalGradeCalculator));
    }, [filteredGrade]);

    useEffect(() => {
        const classTotal = filteredGrade.reduce((acc, curr) => acc + curr.grade_score, 0);
        const classMaximumPoint = filteredGrade.reduce((acc, curr) => acc + curr.assignment_total, 0);

        setClassTotalScore(classTotal);
        setClassTotalMaximumPoint(classMaximumPoint);

        if (classMaximumPoint > 0) {
            const percentage = ((classTotal / classMaximumPoint) * 100).toFixed(2);
            setClassScorePercentage(percentage);
        } 
        else {
            setClassScorePercentage(0);
        }
    }, [filteredGrade]);

    const handleOpenViewModal = (enrollmentId) => {
        setViewModalIsOpen(true);
        const assignments = grades.filter(record => record.student_enrollment_id === enrollmentId);
        setStudentAssignments(assignments);
        setStudentGrades(assignments); 
    };

    useEffect(() => {
        if (studentGrades.length > 0) {
            const sum = studentGrades.reduce((acc, curr) => acc + curr.grade_score, 0);
            const maxScore = studentGrades.reduce((acc, curr) => acc + curr.assignment_total, 0);
            const percentage = maxScore > 0 ? ((sum / maxScore) * 100).toFixed(2) : 0;

            setSumScore(sum);
            setTotalMaxScore(maxScore);
            setTotalPercentage(percentage);
        }
    }, [studentGrades]);

    const handleScoreChange = (gradeId, newScore) => {
        const updatedGrades = studentGrades.map((grade) => 
            grade.grade_id === gradeId ? { ...grade, grade_score: newScore, modified: true } : grade
        );
        setStudentGrades(updatedGrades);
    };

    const handleDescriptionChange = (gradeId, newDescription) => {
        const updatedGrades = studentGrades.map((grade) => 
            grade.grade_id === gradeId ? { ...grade, assignment_description: newDescription, modified: true } : grade
        );
        setStudentGrades(updatedGrades);
    };

    const handleSaveViewChanges = async () => {
        const updatedGrades = studentGrades.filter(grade => grade.modified).map(({ grade_id, grade_score, assignment_description }) => ({
            grade_id,
            score: grade_score,
            description: assignment_description
        }));
    
        if (updatedGrades.length === 0) {
            alert('No grades were modified.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/grades`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedGrades)
            });
    
            if (!response.ok) {
                throw new Error('Failed to update grades');
            }
            alert('All grades updated successfully!');
        } 
        catch (error) {
            console.error('Error updating grades:', error);
        }
    };

    const handleCloseViewModal = () => {
        setViewModalIsOpen(false);
    };

    //Adding new mark
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [classToAddFor, setClassToAddFor] = useState(''); //all unique classes
    const [assignmentsFound, setAssignmentsFound] = useState([]);
    const [selectedClassToAddFor, setSelectedClassToAddFor] = useState('');
    const [assignmentToAddFor, setAssignmentToAddFor] = useState([]);
    const [selectedAssignmentToAddFor, setSelectedAssignmentToAddFor] = useState('');
    const [newGrades, setNewGrades] = useState([]);
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);

    const handleOpenAddModal = () => {
        setAddModalIsOpen(true);
    };

    useEffect(() => {
        const clssToAdd = [...new Set(classes.map(cls => cls.class_id))].map(id => classes.find(c => c.class_id === id));
        setClassToAddFor(clssToAdd);
      }, [classes]);

      useEffect(() => {
      const fetchAssignments = async () => {
        try {
          const assignmentResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/assignments`,{
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            });
          const assignmentData = await assignmentResponse.json();
          //console.log("Assignments:", assignmentData);
          setAssignmentsFound(assignmentData);
         } 
        catch (error) {
          console.log('Error fetching students!', error);
        }
      };
      fetchAssignments();
     }, [user.user_id, token]);
  
      useEffect(() => {
      const fetchStudents = async () => {
        try {
          const studentResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/students`,{
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            });
          const studentData = await studentResponse.json();
          //console.log("Students:", studentData);
          setStudents(studentData);
         } 
        catch (error) {
          console.log('Error fetching students!', error);
        }
      };
      fetchStudents();
     }, [user.user_id, token]);

     const handleAddClassSelect = (event) => {
        const selectedClassId = event.target.value;
        setSelectedClassToAddFor(selectedClassId);
        const selectedAssignments = assignmentsFound.filter(assignment => assignment.class_id === selectedClassId);
        setAssignmentToAddFor(selectedAssignments);
        const filtered = students.filter(student => student.class_id === selectedClassId);
        setFilteredStudents(filtered);
    };

    const handleAddAssignmentSelect = (selectedAssignmentId) => {
        setSelectedAssignmentToAddFor(selectedAssignmentId);
    };

     
     const handleAddScore = (enrollmentId, score) => {
          setNewGrades(prevGrades => ({...prevGrades, [enrollmentId]: 
                                  {
                                      ...prevGrades[enrollmentId],
                                      score,
                                  },
            }));
        };
  
    const handleAddDescription = (enrollmentId, description) => {
          setNewGrades(prevGrades => ({
                      ...prevGrades,[enrollmentId]: {
                      ...prevGrades[enrollmentId],
                          description,
              },
          }));
      };
     
      const handleAddMark = async () => {
        try {
            const selectedAssignment = assignmentToAddFor.find(assignment => assignment.assignment_id === selectedAssignmentToAddFor);
            if (!selectedAssignment) {
                throw new Error('Selected assignment not found');
            }

            const gradesData = Object.entries(newGrades).map(([enrollment_id, grade]) => ({
                enrollment_id,
                assignment_id: selectedAssignment.assignment_id,
                score: grade.score,
                description: grade.description,
            }));
    
            if (gradesData.length === 0) {
                alert('No grades to save.');
                return;
            }
    
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/grades`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(gradesData),
            });
    
            if (!response.ok) {
                const errorData = await response.json(); 
                console.error(errorData);
                throw new Error(`Failed to save grades: ${errorData.message || response.statusText}`);
            }
            alert('Grades saved successfully!');
            handleCloseAddModal();
        } 
        catch (error) {
            console.error('Error saving grades:', error);
        }
    };


    const handleCloseAddModal = () => {
        setAddModalIsOpen(false);
        setNewGrades({});
        setSelectedAssignmentToAddFor('');
        setFilteredStudents([]);
    };

    //Editing existing mark
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [uniqueClassEdit, setUniqueClassEdit] = useState([]); // to generate class options
    const [selectedClassEdit, setSelectedClassEdit] = useState('');
    const [uniqueAssignmentEdit, setUniqueAssignmentEdit] = useState([]); // to generate assignment options
    const [selectedAssignmentEdit, setSelectedAssignmentEdit] = useState('');
    const [gradesToEdit, setGradesToEdit] = useState([]); // holds editable grades

    const handleOpenEditModal = () => {
        setEditModalIsOpen(true);
    };

    useEffect(() => {
        const uniqueClss = [...new Set(grades.map(item => item.class_id))].map(id => grades.find(grade => grade.class_id === id));
        setUniqueClassEdit(uniqueClss);
    }, [grades]);

    const handleEditClassChange = (e) => {
        const classId = e.target.value;
        setSelectedClassEdit(classId);
        const uniqueAssignments = Array.from(new Set(grades
            .filter(item => item.class_id === classId)
            .map(item => item.assignment_id)
        )).map(id => grades.find(item => item.assignment_id === id));
        setUniqueAssignmentEdit(uniqueAssignments);
        setSelectedAssignmentEdit('');
        setGradesToEdit([]);
    };

    const handleEditAssignmentChange = (e) => {
        const assignmentId = e.target.value;
        setSelectedAssignmentEdit(assignmentId);
        const editableGrades = grades.filter(record => record.assignment_id === assignmentId);
        setGradesToEdit(editableGrades);
    };
    
    const handleEditScore = (gradeId, newScore) => {
        setGradesToEdit(prevGrades =>
            prevGrades.map(grade =>
                grade.grade_id === gradeId ? { ...grade, grade_score: newScore, modified: true} : grade
            )
        );
    };
    
    const handleEditDescription = (gradeId, newDescription) => {
        setGradesToEdit(prevGrades =>
            prevGrades.map(grade =>
                grade.grade_id === gradeId ? { ...grade, assignment_description: newDescription, modified: true } : grade
            )
        );
    };
    
    const handleSaveGradeChanges = async () => {
        const updatedGrds = gradesToEdit.filter(grade => grade.modified).map(({ grade_id, grade_score, assignment_description }) => ({
            grade_id,
            score: grade_score,
            description: assignment_description
        }));
    
        if (updatedGrds.length === 0) {
            alert('No grades were modified.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/grades`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedGrds)
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to update grades: ${errorData.message || response.statusText}`);
            }
            alert('All grades updated successfully!');
            handleCloseEditModal();
        } 
        catch (error) {
            console.error('Error updating grades:', error);
        }
    };

    const handleCloseEditModal = () => {
        setEditModalIsOpen(false);
        setSelectedClassEdit('');
        setSelectedAssignmentEdit('');
        setGradesToEdit([]);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading, please wait...
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="p-5">
            <h1 className="text-4xl font-bold mb-8 p-5">Grade Report</h1>
            <div className="flex items-center gap-4 mb-4">
                <div className="w-1/4 pr-4">
                    <select id="classSelect" name="classSelect" onChange={handleClassSelect} className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded">
                        <option value="">Select Class</option>
                        {uniqueClasses.map((clss) => (
                            <option key={clss.class_id} value={clss.class_id}>{clss.course_name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex justify-end p-5 space-x-4">
                <button onClick={handleOpenAddModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Add New Result
                </button>
                <button onClick={handleOpenEditModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Edit Result
                </button>
            </div>

            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-slate-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Student Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Point</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Maximum Point</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Percentage</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {totalScore.length > 0 ? (
                            totalScore.map((score) => (
                                <tr key={score.student_enrollment_id}>
                                    <td className="px-6 py-4">{score.student_first_name} {score.student_middle_name} {score.student_last_name}</td>
                                    <td className="px-6 py-4 text-center">{score.grade_score_sum}</td>
                                    <td className="px-6 py-4 text-center">{score.assignment_total_sum}</td>
                                    <td className="px-6 py-4 text-center">{((score.grade_score_sum / score.assignment_total_sum) * 100).toFixed(2)}%</td>
                                    <td className="px-6 py-4">
                                        <button onClick={() => handleOpenViewModal(score.student_enrollment_id)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center py-4">No data available</td>
                            </tr>
                        )}
                        <tr>
                            <td className="px-6 py-4 font-bold">Class Average</td>
                            <td className="px-6 py-4 font-bold text-center">{classTotalScore}</td>
                            <td className="px-6 py-4 font-bold text-center">{classTotalMaximumPoint}</td>
                            <td className="px-6 py-4 font-bold text-center">{classScorePercentage}%</td>
                            <td className="px-6 py-4"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Detailed Grade by individual */}
            {studentAssignments.length > 0 && (
                <Modal isOpen={viewModalIsOpen} onRequestClose={handleCloseViewModal} contentLabel="Grade Detail Modal">
                    <div className="bg-slate-200 mt-2 p-1 shadow-md rounded-md">
                        <h1 className="text-xl font-bold mb-4">{studentAssignments[0].student_first_name} {studentAssignments[0].student_middle_name} {studentAssignments[0].student_last_name} - {studentAssignments[0].course_name}</h1>
                        <div className="flex justify-end p-5 space-x-4">
                            <button onClick={handleSaveViewChanges} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                Update Result
                            </button>
                        </div>

                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Assignment</th>
                                    <th className="px-2 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Point</th>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Max Point</th>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Percentage</th>
                                    <th className="px-2 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentAssignments.map((grade) => (
                                    <tr key={grade.grade_id} className="border-b border-gray-300">
                                        <td className="py-2 pl-0 ml-4">{grade.assignment_type}</td>
                                        <td className="py-2">
                                            <input
                                                type="number"
                                                className="border border-gray-300 rounded px-2 py-1 w-1/3"
                                                defaultValue={grade.grade_score}
                                                min={0}
                                                onChange={(e) => handleScoreChange(grade.grade_id, e.target.value)}
                                            />
                                        </td>
                                        <td className="py-2">{grade.assignment_total}</td>
                                        <td className="py-2">{((grade.grade_score / grade.assignment_total) * 100).toFixed(2)}%</td>
                                        <td className="py-2">
                                            <textarea
                                                className="border border-gray-300 rounded px-2 py-1 w-full"
                                                defaultValue={grade.assignment_description}
                                                onChange={(e) => handleDescriptionChange(grade.grade_id, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 pl-0 ml-4 font-bold">Total</td>
                                    <td className="py-2 font-bold">{sumScore}</td>
                                    <td className="py-2 font-bold">{totalMaxScore}</td>
                                    <td className="py-2 font-bold">{totalPercentage}%</td>
                                </tr>
                            </tbody>
                        </table>

                        <button type="button" onClick={handleCloseViewModal} className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6">
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}
          
            {/* Add New Result Popup */}
            <Modal isOpen={addModalIsOpen} onRequestClose={handleCloseAddModal} contentLabel="Add Grade Modal" >
                <div className="bg-white p-5">
                    <h1 className="text-xl font-bold mb-8 text-center">Add New Result</h1>
                    
                    {/* Container for dropdowns */}
                    <div className="flex space-x-4 mb-4">
                        {/* Dropdown for selecting class */}
                        <div className="w-1/4">
                            <select 
                                id="gradeSelect" 
                                name="grade"
                                onChange={handleAddClassSelect} 
                                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded">
                                <option value="">Select Class</option>
                                    {classToAddFor.map((clss) => (
          		   	                    <option key={clss.class_id} value={clss.class_id}>
                                            {clss.course_name}
                                        </option>
        		                    ))}
                            </select>
                        </div>
                        {/* Dropdown for selecting Assignment */}
                        <div className="w-1/4">
                            <select 
                                id="assignmentSelect" 
                                name="assignment" 
                                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                                value={selectedAssignmentToAddFor}
				                onChange={(e) => handleAddAssignmentSelect(e.target.value)}
                            >
                                <option value="">Select Assignment</option>
                                {assignmentToAddFor.map(assignment => (
              				        <option key={assignment.assignment_id} value={assignment.assignment_id}>
                				        {assignment.assignment_name}
              				        </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Display selected Assignment name and Maximum point */}
                    {selectedAssignmentToAddFor && assignmentToAddFor.length > 0 && (
                    <>
                    <div className="w-1/4 pr-4 flex items-center space-x-4 ml-8">
                            <p className="font-semibold text-lg mt-1 pl-3 pr-10 py-2 rounded-md whitespace-nowrap">{classToAddFor.class_name} - {assignmentToAddFor.find(a => a.assignment_id === selectedAssignmentToAddFor)?.assignment_name}</p>
                            <p className="font-semibold text-lg mt-1 pl-3 pr-10 py-2 rounded-md whitespace-nowrap">Maximum Point - {assignmentToAddFor.find(a => a.assignment_id === selectedAssignmentToAddFor)?.assignment_total}</p>
                        </div><div className="p-1">
                                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                    <thead className="bg-slate-200">
                                        <tr>
                                            <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Student Name </th>
                                            <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Point </th>
                                            <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Comment </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredStudents.length > 0 ? (
                                            (filteredStudents.map((student) => (
                                                <tr key={student.enrollment_id}>
                                                    <td className="px-2 py-4">{student.student_first_name} {student.student_middle_name} {student.student_last_name}</td>
                                                    <td className="px-2 py-4">
                                                        <input type="number" 
                                                            placeholder="Enter Point" 
                                                            className="border-2 border-gray-300 rounded-md p-1"
                                                            value={newGrades[student.enrollment_id]?.score || ''}
                                                            onChange={(e) => handleAddScore(student.enrollment_id, e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-4">
                                                        <textarea
                                                            placeholder="Type Comment"
                                                            className="border-2 border-gray-300 rounded-md p-1 w-full resize-y"
                                                            rows="2"
                                                            value={newGrades[student.enrollment_id]?.description || ''}
                                                            onChange={(e) => handleAddDescription(student.enrollment_id, e.target.value)}
                                                        ></textarea>
                                                    </td>
                                                </tr>
                                                )))
                                                ) : (
                                                <tr>
                                                    <td colspan-3>No student available</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                     )}
                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded" onClick={handleAddMark}>
                            Submit Grade
                        </button>
                        <button type="button" onClick={handleCloseAddModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </div>      
            </Modal>


            <Modal isOpen={editModalIsOpen} onRequestClose={handleCloseEditModal} contentLabel="Edit Grade Modal">
                <div className="bg-white p-5">
                    <h1 className="text-xl font-bold mb-8 text-center">Edit Grade</h1>

                    {/* Container for dropdowns */}
                    <div className="flex space-x-4 mb-4">
                        {/* Dropdown for selecting class */}
                        <div className="w-1/4">
                            <select
                                id="classSelect"
                                name="classSelect"
                                value={selectedClassEdit}
                                onChange={handleEditClassChange}
                                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                            >
                                <option value="">Select Class</option>
                                {uniqueClassEdit.map(grade =>
                                    <option key={grade.class_id} value={grade.class_id}>
                                        {grade.course_name}
                                    </option>
                                )}
                            </select>
                        </div>
                        {/* Dropdown for selecting Assignment */}
                        <div className="w-1/4">
                            <select
                                id="assignmentSelect"
                                name="assignment"
                                value={selectedAssignmentEdit}
                                onChange={handleEditAssignmentChange}
                                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                            >
                                <option value="">Select Assignment</option>
                                {uniqueAssignmentEdit.map(grade =>
                                    <option key={grade.assignment_id} value={grade.assignment_id}>
                                        {grade.assignment_name}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>

                    {/* Display selected Assignment name and Maximum point */}
                    {selectedAssignmentEdit && (
                        <div className="w-1/4 pr-4 flex items-center space-x-4 ml-8">
                            <p className="font-semibold text-lg mt-1 pl-3 pr-10 py-2 rounded-md whitespace-nowrap">
                                {uniqueAssignmentEdit.find(a => a.assignment_id === selectedAssignmentEdit)?.assignment_name}
                            </p>
                            <p className="font-semibold text-lg mt-1 pl-3 pr-10 py-2 rounded-md whitespace-nowrap">
                                Maximum Point - {uniqueAssignmentEdit.find(a => a.assignment_id === selectedAssignmentEdit)?.assignment_total}
                            </p>
                        </div>
                    )}

                    <div className="p-1">
                        <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                            <thead className="bg-slate-200">
                                <tr>
                                    <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Student Name</th>
                                    <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Point</th>
                                    <th className="px-4 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Comment</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {gradesToEdit.map(record => (
                                    <tr key={record.grade_id}>
                                        <td className="px-2 py-4">{record.student_first_name} {record.student_middle_name} {record.student_last_name}</td>
                                        <td className="px-2 py-4">
                                            <input
                                                type="number"
                                                placeholder="Enter Point"
                                                value={record.grade_score || ''}
                                                className="border-2 border-gray-300 rounded-md p-1"
                                                onChange={(e) => handleEditScore(record.grade_id, e.target.value)}
                                            />
                                        </td>
                                        <td className="px-2 py-4">
                                            <textarea
                                                placeholder="Type Comment"
                                                className="border-2 border-gray-300 rounded-md p-1 w-full resize-y"
                                                value={record.assignment_description || ''}
                                                onChange={(e) => handleEditDescription(record.grade_id, e.target.value)}
                                                rows="2"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded" onClick={handleSaveGradeChanges}>Save Changes</button>
                        <button type="button" onClick={handleCloseEditModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">Cancel</button>
                    </div>
                </div>
            </Modal>


        </div>
    );
}

export default TeacherGradeReport;