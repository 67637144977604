import React, { createContext, useState , useEffect} from 'react';

export const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [isNavigationSidebarOpen, setNavigationSidebarOpen] = useState(true);
  
  useEffect(() => {
    // Function to check if the screen is mobile-sized and update state
    const checkScreenSize = () => {
      if (window.innerWidth < 768) {
        // It's a mobile screen
        setNavigationSidebarOpen(false);
      } else {
        // It's not a mobile screen
        setNavigationSidebarOpen(true);
      }
    };

    // Check screen size on mount
    checkScreenSize();

    // Optional: Add event listener for window resize to adjust the sidebar visibility
    window.addEventListener('resize', checkScreenSize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []); // Empty dependency array means this effect runs only on mount and unmount


  return (
    <SidebarContext.Provider value={{ isNavigationSidebarOpen, setNavigationSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
}