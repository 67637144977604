import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AuthContext } from "../../contexts/auth";
import axios from "axios";

export default function SignIn() {
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [mobileError, setMobileError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const { setUser } = useContext(AuthContext);

	//check for existing user in localStorage and set user context
	useEffect(() => {
		const userData = localStorage.getItem("user");
		if (userData) {
			const data = JSON.parse(userData);
			setUser(data);
			navigate("/"); // After setting user, navigate to the default route
		}
	}, [setUser, navigate]);

	async function handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);

		setMobileError("");
		setPasswordError("");
		setError(null);
		let isValid = true;

		const phoneNumberRegex = /^(7|9)\d{8}$/;
		if (!phoneNumberRegex.test(formData.get("mobile").trim())) {
			setMobileError("Invalid mobile number format. Must start with 9 or 7.");
			isValid = false;
		}

		if (formData.get("password").trim() === "") {
			setPasswordError("Password is required");
			isValid = false;
		}

		if (!isValid) return;

		setIsLoading(true);

		const data = {
			mobile: "+251" + formData.get("mobile"),
			password: formData.get("password"),
		};

		try {
			// Use Axios to send the login request
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/v1/auth/validate_password`,
				data,
				{ headers: { "Content-Type": "application/json" } },
			);
			console.log("🚀 ~ handleSubmit ~ response:", response);

			if (response.status === 200) {
				const userData = response.data;
				userData["user_profile"] = {
					image_profile:
						"https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
				};
				//save user data and token in the context
				localStorage.setItem("user", JSON.stringify(userData));
				setUser(userData);

				navigate("/");
				// Navigate based on role
			} else {
				throw new Error("Invalid response from server");
			}
		} catch (error) {
			setIsLoading(false);
			setError(
				"Either phone number or password is incorrect. Please try again.",
			);
		} finally {
			setIsLoading(false);
		}
	}

	if (isLoading) {
		return (
			<div className="flex items-center justify-center h-screen">
				<span className="ml-2 text-gray-500">
					Authenticating User, please wait...
				</span>
			</div>
		);
	}

	return (
		<div className="flex flex-col justify-center items-center min-h-screen w-full bg-cyan-100">
			<div className="w-full md:w-1/3 p-4 bg-cyan-50 shadow-md rounded-md">
				<img
					src={"/SiyahaSmallLogo.png"}
					alt="siyaha logo"
					className="rounded-full w-13 h-13 mx-auto p-5"
				/>
				<form className="space-y-10 p-5" onSubmit={handleSubmit}>
					<div>
						<label
							htmlFor="mobile"
							className="block text-sm font-medium leading-6 text-gray-500"
						>
							Mobile
						</label>
						<div className="mt-2 flex items-center space-x-4">
							<p className="flex mr-3">
								<img className="w-10 mr-1" src="eth.png" alt="ETH Flag" />
								+251
							</p>
							<input
								id="mobile"
								name="mobile"
								type="tel"
								autoComplete="mobile"
								placeholder="ENTER YOUR MOBILE NUMBER"
								className="p-3 rounded block w-full border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
						{mobileError && (
							<p className="mt-2 font-bold text-red-500 text-xs">
								{mobileError}
							</p>
						)}
					</div>

					<div>
						<label
							htmlFor="password"
							className="block text-sm font-medium leading-6 text-gray-500"
						>
							Password
						</label>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								placeholder="ENTER YOUR PASSWORD"
								className="p-3 rounded block w-full border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
							{passwordError && (
								<p className="mt-2 font-bold text-red-500 text-xs">
									{passwordError}
								</p>
							)}
						</div>
					</div>

					<div>
						<button
							type="submit"
							className="rounded flex w-full justify-center bg-gray-800 p-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus:outline-none"
						>
							Sign in
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
