import React, {  useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

function TeacherAnnouncement() {
    const {user, token } = useContext(AuthContext);
    //for pop up modal
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        classes: [],
        subject: '',
        content: ''
    });
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] =useState(true); //set loading status to true
  const [updateMode, setUpdateMode] = useState(false); //to identify operation
  const [updateId, setUpdateId] = useState(null); //to identify record to be modified

  const [classes, setClasses] = useState([]); //to keep all possible class
  const [selectedClasses, setSelectedClasses] = useState([]); //hold class for which announcement to be released

  useEffect(() => {
  const fetchAnnouncements = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/announcements`,{
              headers: {
                  'Authorization':`Bearer ${token}`,
                  'Content-Type':'application/json'
              }
          });
      if (!response.ok) {
         throw new Error('Network response was not ok');
      }
      const announcementData = await response.json();
      setAnnouncements(announcementData);
     } 
    catch (err) {
      console.log("Error message", err);
    } 
    finally {
      setLoading(false);
    }
  }
   fetchAnnouncements();
});

  //filtering out school announcement and class announcements
  const schAnnouncements = announcements.filter(announcement=>announcement.announcement_category ==='school');
  const clsAnnouncements = announcements.filter(announcement=>announcement.announcement_category !== 'school');

   /*fetching class list so as to use as select option while posting announcement*/
  useEffect(() => {
   const fetchClasses = async () => {
    try {
      const classResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`,{
              headers: {
                  'Authorization':`Bearer ${token}`,
                  'Content-Type':'application/json'
              }
          });
      if (!classResponse.ok) {
          throw new Error('Network response was not ok');
       }
      const classData = await classResponse.json();
      console.log(classData);
      //const uniqueClasses = Array.from(new Set(classData.map(clss => clss.class_id))).map(id => classData.find(clss => clss.class_id === id));
      setClasses(classData);
     } 
    catch (err) {
      console.log("Error:", err);
    } 
    finally{
        setLoading(false);
    }
  };
   fetchClasses();
  });

 //selected classes
     const handleClassSelection = (class_id) => {
      setSelectedClasses((prevSelectedClasses) => {
          const newSelectedClasses = prevSelectedClasses.includes(class_id)
              ? prevSelectedClasses.filter((id) => id !== class_id)
              : [...prevSelectedClasses, class_id];
  
          setFormData((prevData) => ({
              ...prevData,
              classes: newSelectedClasses
          }));
          return newSelectedClasses;
      });
  };

  const handleAddAnnouncementClick = () => {
      setModalIsOpen(true);
  };

  const handleCloseModal = () => {
  setFormData({
          classes: [],
          subject: '',
          content: ''
        });
      setUpdateId(null);
      setUpdateMode(false);
      setModalIsOpen(false);
  };

  //accepting value from form
 const handleFormChange = (e) => {
     setFormData({ 
        ...formData, [e.target.name]: e.target.value 
    });
  };

 //performing operation
  const handleFormSubmit = async (e) => {
      e.preventDefault();
  try{
           if(updateMode) {
                const update = await fetch(`${process.env.REACT_APP_API_URL}/v1/announcements/${updateId}`,{
                          method:'PATCH',
                          headers:{
                              'Authorization':`Bearer ${token}`,
                              'Content-Type':'application/json'
                              },
                        body:JSON.stringify(formData)
                   });
                if (update.ok) {
                      alert('Announcement updated successfully!');       
                  }
                else {
                      alert('Sorry, operation cancelled.');
                  }
               handleCloseModal();    
             }

          else {
             const create = await fetch(`${process.env.REACT_APP_API_URL}/v1/announcements`,{
                    method: 'POST',
                    headers: {
                       'Authorization':`Bearer ${token}`,
                       'Content-Type':'application/json'
                      },
                    body:JSON.stringify(formData)
                  });
                 if (create.ok) {
                      alert('Announcement added successfully!');
                    } 
                 else {
                      alert('Sorry, operation cancelled.');
                  }   
               handleCloseModal(); 
              }
          }
     catch(error){
                 console.error(error);
          }
   };

  //handling updates
    const handleUpdate = (announcement) => {
      setUpdateId(announcement.announcement_id);
      setUpdateMode(true);
      setModalIsOpen(true);
      setFormData({
            classes: selectedClasses,
            subject: announcement.announcement_subject,
            content: announcement.announcement_content
      });
  };

  if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center">
                <svg
                    className="animate-spin h-10 w-10 text-blue-500 mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                </svg>
                <p className="text-lg font-semibold text-gray-600">
                    Loading announcement, please wait...
                </p>
            </div>
        </div>
    );
}

  return (
    <div className="p-5">
        {/* Add Announcement button */}
        <div className="flex justify-end mt-8">
            <button onClick={handleAddAnnouncementClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                Add Class Announcement
            </button>
        </div>

        {/*School Announcements */}
        <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md"> 
            <h1 className="text-xl font-bold uppercase tracking-wider mb-2">School Announcements</h1>
            <div className="flex flex-col gap-2 h-64 overflow-y-auto"> 
                {schAnnouncements.length === 0 ? (
                    <div className="text-center py-4">No school announcements available.</div>
                    ) : (
                    schAnnouncements.map((announcement, index) => (
                        <div className="bg-slate-100 p-4 shadow-md rounded-md" key={index}>
                            <h2 className="text-lg font-semibold">{index + 1}. {announcement.announcement_subject}</h2>
                            <p className="text-gray-700">{announcement.announcement_content}</p>
                            <p className="text-gray-700">Posted on Date: {new Date(announcement.announcement_created_at).toLocaleString()}</p>
                        </div>
                    ))
                 )}
            </div>
        </div>

        {/* Class Announcement */}
        <div className="bg-slate-200 mt-4 p-4 shadow-md rounded-md">
            <h1 className="text-xl font-bold uppercase tracking-wider mb-2">Class Announcements</h1>
            <div className="flex flex-col gap-2 h-96 overflow-y-auto">
                { clsAnnouncements.length === 0 ? (
                    <div className="text-center py-4">No class announcements available.</div>
                        ) : (
                    clsAnnouncements.map((announcement, index) => (
                       <div key={announcement.announcement_id} className="bg-slate-100 p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold">{announcement.announcement_category}</h2>
                            <p className="text-lg font-semibold text-gray-700">{announcement.announcement_subject}</p>
                        <p className="text-gray-700">{announcement.announcement_content}</p>
                        <p className="text-gray-700">{new Date(announcement.announcement_created_at).toLocaleString()}</p>
                            <div className="flex justify-end gap-2 mt-2">
                                <button className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-1 px-2 rounded"
                                    onClick ={() => handleUpdate(announcement)} >Edit</button>
                                <button className="bg-red-700 hover:bg-red-500 text-white font-bold py-1 px-2 rounded">Delete </button>
                           </div>
                       </div>
                    ))
                  )}
            </div>
        </div>
        
        {/* Add class Announcement popup */}
        
        <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add announcement Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4">{updateMode ? 'Edit Announcement':'Add New Announcement'}</h1>
                <form onSubmit={handleFormSubmit}>
                    <div className="p-3 w-1/3">
                        <label>Target Classes:</label>
                            <div>
                                {classes.map((clss) => (
                                    <div key={clss.class_id}>
                                        <input 
                                            type="checkbox" 
                                            id={clss.class_id}
                                            onChange={() => handleClassSelection(clss.class_id)}
                                            checked={selectedClasses.includes(clss.class_id)}
                                        />
                                        <label htmlFor={clss.class_id}>{clss.course_name.charAt(0).toUpperCase() + clss.course_name.slice(1)}</label>
                                    </div>
                                ))}
                            </div>
                    </div>

                    <div className="p-3">
                        <label htmlFor="title" >Announcement Title:</label>
                        <input
                            id="subject"
                            name="subject"
                            type="text"
                            required
                            className="border border-gray-300 p-2 rounded block w-1/3"
                            value={formData.subject}
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="p-3">
                        <label htmlFor="content" >Announcement Content:</label>
                        <textarea
                            id="content"
                            name="content"
                            className="p-3 rounded block w-full border border-gray-300"
                            value={formData.content}
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="p-3">
                        <button type="submit" className="bg-cyan-800 hover:bg-cyan-950 text-white  py-2 px-4 rounded" >
                            {updateMode ? 'Save Changes': 'Add Announcement'}
                        </button>
                        <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>    
    </div>
);
  
}

export default TeacherAnnouncement;


