import React, { useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

function teacherAttendance() {
    const {user, token } = useContext(AuthContext);
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [uniqueCourses, setUniqueCourses] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [totals, setTotals] = useState({totalPresent: 0, 
      totalAbsent: 0, 
      totalExcuse: 0, 
      totalLate: 0, 
      presentPercentage: 0, 
      absentPercentage: 0, 
      excusePercentage: 0, 
      latePercentage: 0});
      const [loading, setLoading] =useState(true);

    useEffect(() => {
        const fetchAttendanceRecords = async () => {
         try{ 
            const attendanceResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/attendances`,{
                        headers: {
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                        }
                });
          const attData = await attendanceResponse.json();
          setAttendanceRecords(attData); 
        }
        catch (error) {
          console.error('Error:', error);
        }
       finally{
          setLoading(false);
        }
      };
        fetchAttendanceRecords();
      }, [token, user.user_id]);

      useEffect(() => {
        if (attendanceRecords.length > 0) {
            const unique_courses = [...new Set(attendanceRecords.map(record => record.course_name))];
            setUniqueCourses(unique_courses);
        }
      }, [attendanceRecords]);
    
    useEffect(() => {
          let filteredAttendance = attendanceRecords;
          if (selectedClass) {
            filteredAttendance = attendanceRecords.filter(record => record.course_name === selectedClass);
            }
            setFilteredAttendance(filteredAttendance);
          }, [attendanceRecords, selectedClass]);

      /* attendance status for groups those satisfy selection criteria */
      const calculateTotals = () => {
        const totalPresent = filteredAttendance.filter((attendance) => attendance.status === 'present').length;
        const totalAbsent = filteredAttendance.filter((attendance) => attendance.status === 'absent').length;
        const totalExcuse = filteredAttendance.filter((attendance) => attendance.status === 'excused').length;
        const totalLate = filteredAttendance.filter((attendance) => attendance.status === 'late').length;

        const totalRecords = filteredAttendance.length;
        const presentPercentage = totalPresent > 0 ? (((totalPresent/totalRecords) * 100).toFixed(2)) : 0;
        const absentPercentage = totalAbsent > 0 ? (((totalAbsent / totalRecords) * 100).toFixed(2)): 0;
        const excusePercentage = totalExcuse > 0 ? (((totalExcuse / totalRecords) * 100).toFixed(2)) :0;
        const latePercentage = totalLate >0 ? ((totalLate / totalRecords) * 100).toFixed(2) : 0;
        setTotals({ totalPresent, totalAbsent, totalExcuse, totalLate, presentPercentage, absentPercentage, 
          excusePercentage, latePercentage});
      };

      useEffect(() => {
        calculateTotals();
      }, [filteredAttendance]);

      /* attendance status for individuals those satisfy selection criteria */
      const calculateTotalsForStudents = () => {
        const studentTotals = {};
        filteredAttendance.forEach((record) => {
        const { student_id, status, student_first_name, student_middle_name, student_last_name } = record;
        if (!studentTotals[student_id]) {
              studentTotals[student_id] = {
                studentId: student_id,
                firstName: student_first_name,
                middleName: student_middle_name,
                lastName: student_last_name,
                totalPresent: 0,
                totalAbsent: 0,
                totalExcuse: 0,
                totalLate: 0,
              };
            }
          switch (status) {
            case 'present':
              studentTotals[student_id].totalPresent++;
              break;
            case 'absent':
              studentTotals[student_id].totalAbsent++;
              break;
            case 'excused':
              studentTotals[student_id].totalExcuse++;
              break;
            case 'late':
              studentTotals[student_id].totalLate++;
              break;
            default:
              break;
          }
        });
      return Object.values(studentTotals);
    };

    const uniqueStudentTotals = calculateTotalsForStudents();

    /*Edit attendance*/
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [uniqueCoursesEdit,setUniqueCoursesEdit] = useState([]);
    const [uniqueDatesEdit, setUniqueDatesEdit] = useState([]);
    const [selectedClassEdit, setSelectedClassEdit] = useState(null);
    const [selectedDateEdit, setSelectedDateEdit] = useState('');
    const [filteredAttendanceEdit, setFilteredAttendanceEdit] = useState([]);
    const [localStatuses, setLocalStatuses] = useState({});

    useEffect(() => {
      if (attendanceRecords.length > 0) {
          const unique_courses_edit = [...new Set(attendanceRecords.map(record => record.course_name))];
          setUniqueCoursesEdit(unique_courses_edit);

          const unique_date_edit = [...new Set(attendanceRecords.map(record => record.date))];
          setUniqueDatesEdit(unique_date_edit);
      }
    }, [attendanceRecords]);
  
  useEffect(() => {
        let filteredAttendanceEdit = attendanceRecords;
        if (selectedClassEdit) {
            filteredAttendanceEdit = attendanceRecords.filter(record => record.course_name === selectedClassEdit);
          }
        if(selectedDateEdit){
            filteredAttendanceEdit = filteredAttendanceEdit.filter(record => record.date === selectedDateEdit);
          }
          setFilteredAttendanceEdit(filteredAttendanceEdit);

          const initialStatuses = {};
            filteredAttendance.forEach(record => {
              initialStatuses[record.attendance_id] = null; // Start with no status
          });
          setLocalStatuses(initialStatuses);

        }, [attendanceRecords, selectedClassEdit, selectedDateEdit]);

    const handleEditModal = () => {
      setEditModalIsOpen(true);
    }

    const handleAttendanceStatusChange = (attendanceId, newStatus) => {
      setLocalStatuses(prevStatuses => ({
          ...prevStatuses,
            [attendanceId]: newStatus // Update local status immediately
          }));
      };

    const handleStatusEdit = async () => {
      //checking for atleast one attendance record is modified to avoid unnecessary api call
      const attendanceToUpdate = filteredAttendanceEdit.filter(record =>
        localStatuses[record.attendance_id] && record.status !== localStatuses[record.attendance_id]
    );

        if (attendanceToUpdate.length === 0) {
          alert('No changes to save.');
          return;
      }

      //send only list of attendance modified to api
      const dataToSend = attendanceToUpdate.map(record => ({
        attendance_id: record.attendance_id,
        date: record.date,
        status: localStatuses[record.attendance_id],
      }));

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/attendances`,{
                      method: 'PATCH',
                      headers: {
                          'Authorization':`Bearer ${token}`,
                          'Content-Type':'application/json'
                      },
                      body: JSON.stringify(dataToSend),
              });
  
        if (!response.ok) {
          throw new Error('Failed to update attendance record');
        }
        alert("Attendance Status Change Saved successfully!");
        setLocalStatuses({});
      } 
      catch (error) {
        console.error('Error updating attendance record:', error);
      }
    };

    const handleCloseEditModal = () => {
      setSelectedClassEdit('');
      setSelectedDateEdit('');
      setEditModalIsOpen(false);
    }

    /*Take attendance*/
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [classes,setClasses] = useState([]); //collect classes of logged in user
    const [uniqueClss, setUniqueClss] = useState([]); //unique classes returned
    const [chosenClass,setChosenClass] = useState(''); // hold selected class to fill attendance
    const [scheduleId, setScheduleId] = useState(''); //keep schedule id of chosen class
    const [attendanceAddDate, setAttendanceAddDate] = useState(''); //hold new attendance date
    //const [periods, setPeriods] = useState([]); //holds set of periods available for course and date
    // const [chosenPeriods, setChosenPeriods] = useState(''); //holds chosen period
    const [students, setStudents] = useState([]); //holds students data all belongs to logged in teacher
    const [selectedStudents, setSelectedStudents] = useState([]); //students of chosen class
    const [dayOfWeek, setDayOfWeek] = useState(''); //to get day name from entered date to use it for identifying list of avialable periods for entered date
    const [courseName, setCourseName] = useState(''); //to identify name of class selected from class id
    const [newAttendances, setNewAttendances] = useState([]); //to hold new attendance status
    const [studentStatuses, setStudentStatuses] = useState({}); // Track statuses per student

    const handleAddModal = () => {
      setAddModalIsOpen(true);
       }

    /*Get all students*/
    useEffect(() => {
      const fetchStudentData = async () => {
        try {
          const stdResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/students`, {
                  headers: {
                      'Authorization':`Bearer ${token}`,
                      'Content-Type':'application/json'
                  }
                });
          const stdData = await stdResponse.json();
          setStudents(stdData);
          setLoading(false);
         } 
        catch (error) {
          console.error('Error fetching student data: ', error);
        }
      };
      fetchStudentData();
    }, [token, user.user_id]);

    /*Get all classes*/
    useEffect(() => {
      const fetchClasses = async () => {
        try {
          const clssResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`, {
                  headers: {
                      'Authorization':`Bearer ${token}`,
                      'Content-Type':'application/json'
                  }
                });
          if (!clssResponse.ok) {
            throw new Error('Failed to fetch classes');
          }
  
          const classInfo = await clssResponse.json();
          setClasses(classInfo);
        } catch (error) {
          console.error('Error fetching classes:', error);
        }
      };
  
      fetchClasses();
    }, [token, user.user_id]);

    //unique classes
    useEffect(()=>{
      if(classes){
        const unique_clss = Array.from(new Set(classes.map(clss => clss.class_id))).map(id => classes.find(clss => clss.class_id === id));
        setUniqueClss(unique_clss);
      }
    },[classes]);
    
    //obtaining students of chosen class
    useEffect(()=>{
      if(chosenClass){
          const chosenStudents = students.filter(student => student.class_id === chosenClass);
          setSelectedStudents(chosenStudents);
      }
    },[students, chosenClass]);

    /*boosting start*/

    /*boosting end*/

   //getting date of week from entered date
    useEffect(()=>{
      if(attendanceAddDate){
        const date = new Date(attendanceAddDate);
        const options = { weekday: 'long' };
        setDayOfWeek(date.toLocaleDateString('en-US', options));
      }
    }, [attendanceAddDate]);

    useEffect(()=>{
      if (chosenClass) {
        const cours = classes.find((cls) => cls.class_id === chosenClass);
        setCourseName(cours.course_name);
        setScheduleId(cours.schedule_id);
      }
    }, [chosenClass]);

    /*Get all periods*/

    const handleStatusAdd = (studentId, scheduleId, attendanceDate, status) =>{
      const newRecord = {
        student_id: studentId,
        schedule_id: scheduleId,
        date: attendanceDate,
        status: status,
      };
      setNewAttendances((prevRecords) => [...prevRecords, newRecord]);

      // Update local status - set the status for the specific student
      setStudentStatuses((prevStatuses) => ({
          ...prevStatuses,
              [studentId]: status, 
        }));
    }

    const handleSaveAttendance = async() => {
      try {
        const statusResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/attendances`,{
              method: 'POST',
              headers: {
                  'Authorization':`Bearer ${token}`,
                  'Content-Type':'application/json'
              },
              body: JSON.stringify(newAttendances)
            });

          if (!statusResponse.ok) {
                throw new Error('Network response was not ok');
            }
          alert('Attendace Status Saved successfully!');
        } 
      catch (error) {
          console.error('Error:', error);
      }  
    }

     const handleCloseAddModal = () => {
          setChosenClass('');
          setAttendanceAddDate('');
          setNewAttendances([]);
          setAddModalIsOpen(false);
       }
     
    if (loading) {
      return (
          <div className="flex items-center justify-center min-h-screen">
              <div className="flex flex-col items-center">
                  <svg
                      className="animate-spin h-10 w-10 text-blue-500 mb-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                  >
                      <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                      />
                      <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      />
                  </svg>
                  <p className="text-lg font-semibold text-gray-600">
                      Loading list of attendance, please wait...
                  </p>
              </div>
          </div>
      );
  }
    
    return (
        <div className="p-5">
            <h1 className="text-3xl font-bold mb-6 p-5">Attendance Summary</h1>

            <div className="flex justify-end p-5 space-x-4">
		        <button onClick={handleAddModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    	Add Attendance
                    </button>

                    <button onClick={handleEditModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    	Edit Attendance
                    </button>
                </div>

            {/* Select Class */}
            <div className="flex items-center gap-4 mb-4">
                <div className="w-1/4 pr-4">
                    <select id="class" 
                        name="class" 
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedClass}
                        onChange ={(e) => setSelectedClass(e.target.value)}>
                      <option value="">All Courses</option>
                        {uniqueCourses.map((course, index) => (
                            <option key={index} value={course}>{course}</option>
                        ))}
                    </select>
                  </div>
            </div>
            
            {/* List of students for selected class */}
            <div className="mt-8">
                  <div className="overflow-x-auto h-[65vh]">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                      <thead className="bg-slate-200">
                        <tr>
				                  <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Student Name</th>
            			        <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Present</th>
            			        <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Late</th>
                          <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Excused</th>
                          <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Absent</th>
          		          </tr>
        	           </thead>
        		   <tbody className="bg-white divide-y divide-gray-200">
          		      {uniqueStudentTotals.map((student) => (
                      <tr key={student.student_id}>
                          <td className="px-6 py-4">{student.firstName} {student.middleName} {student.lastName}</td>
                          <td className="px-6 py-4">{student.totalPresent}({((student.totalPresent/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                          <td className="px-6 py-4">{student.totalLate}({((student.totalLate/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                          <td className="px-6 py-4">{student.totalExcuse}({((student.totalExcuse/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                          <td className="px-6 py-4">{student.totalAbsent}({((student.totalAbsent/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                          
                        </tr>
                      ))}
	    			    <tr>
              		  <td className="px-6 py-6 font-bold">Total</td>
            			  <td className="px-6 py-6 font-bold">{totals.totalPresent}({totals.presentPercentage}%)</td>
            			  <td className="px-6 py-6 font-bold">{totals.totalLate}({totals.latePercentage}%)</td>
            			  <td className="px-6 py-6 font-bold">{totals.totalExcuse}({totals.excusePercentage}%)</td>
            			  <td className="px-6 py-6 font-bold">{totals.totalAbsent}({totals.absentPercentage}%)</td>
            			</tr>
        	         </tbody>
      		    </table>
   	        </div>
           </div>
        
        <Modal isOpen={addModalIsOpen} onRequestClose={handleCloseAddModal} contentLabel="Add Attendance">		
	        <div className="p-5">
	            <h1 className="text-4xl font-semibold p-5">Take Attendance</h1>
            	<div className="flex items-center gap-4 mb-4">
                   <div className="w-1/4 pr-4">
      			          <select 
                        id="classAdd" 
                        name="classAdd" 
                        value={chosenClass}       
				                onChange ={(e) => setChosenClass(e.target.value)}
				                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
			                >
				            <option value="">All Courses</option>
        			        {uniqueClss.map((classData) => (
          			            <option key={classData.class_id} value={classData.class_id}>
            				        {classData.course_name}
          			            </option>
        			        ))}
      			        </select>
		            </div>

		            <div className="w-1/4 pr-4">
			              <input 
				                type="date"
                        name="dateAdd" 
                        placeholder='Enter Date'
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={attendanceAddDate}
                        onChange ={(e) => setAttendanceAddDate(e.target.value)}
			              />
		            </div>
		        </div>

            {chosenClass && attendanceAddDate &&(
		            <>
              <div>
                <p>Course: {courseName} - Date: {attendanceAddDate} - Day of Week Name: {dayOfWeek} </p>
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                  <thead className="bg-slate-500">
                    <tr>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">No</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Name</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Chosen Status</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Candidate Status</th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedStudents.map((record, index) => (
                      <tr key={record.student_id}>
                        <td className="px-6 py-2">{index + 1}</td>
                        <td className="px-6 py-2">{record.student_first_name} {record.student_middle_name} {record.student_last_name}</td>
                        <td className="px-6 py-2">{studentStatuses[record.student_id] || 'Not Marked'}</td>
                        <td className="py-2 pl-0 -ml-4 flex space-x-4">
                          <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-1 px-2 rounded" onClick={() => handleStatusAdd(record.student_id, scheduleId, attendanceAddDate, 'present')}>Present</button>
                          <button className="bg-yellow-700 hover:bg-yellow-500 text-white py-1 px-2 rounded" onClick={() => handleStatusAdd(record.student_id, scheduleId, attendanceAddDate, 'late')}>Late</button>
                          <button className="bg-orange-700 hover:bg-orange-500 text-white py-1 px-2 rounded" onClick={() => handleStatusAdd(record.student_id, scheduleId, attendanceAddDate, 'excused')}>Excuse</button>
                          <button className="bg-red-700 hover:bg-red-500 text-white py-1 px-2 rounded" onClick={() => handleStatusAdd(record.student_id, scheduleId, attendanceAddDate, 'absent')}>Absent</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </>
              )}
              <div className="flex justify-start space-x-2 py-4">
                        <button onClick={handleSaveAttendance} className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded">
                           Save Attendance
                        </button>
                        <button type="button" onClick={handleCloseAddModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
              </div>
            </div>		
      </Modal>

      <Modal isOpen={editModalIsOpen} onRequestClose={handleCloseEditModal} contentLabel="Edit Attendance">
	        <div className="p-5">
	          <h1 className="text-4xl font-semibold p-5">Edit Attendance</h1>
            	<div className="flex items-center gap-4 mb-4">
                   <div className="w-1/4 pr-4">
                      <select id="classEdit" 
                          name="classEdit" 
                          className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                          value={selectedClassEdit}
                          onChange ={(e) => setSelectedClassEdit(e.target.value)}>
                          <option value="">All Courses</option>
                            {uniqueCoursesEdit.map((course, index) => (
                              <option key={index} value={course}>{course}</option>
                            ))}
                        </select>
		                </div>

		                <div className="w-1/4 pr-4">
                      <select 
                          id="dateEdit" 
                          name="dateEdit" 
                          className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                          value={selectedDateEdit}
                          onChange ={(e) => setSelectedDateEdit(e.target.value)}>
                          <option value="">All Date</option>
                            {uniqueDatesEdit.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                        </select>
		                </div>
		              </div>   
                  
          {selectedClassEdit && selectedDateEdit && (
            <>
            <div>
                <h2 className="text-xl font-bold mb-4">Attendance Details of {selectedClassEdit} on {selectedDateEdit} Date</h2>
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                  <thead className="bg-slate-500">
                    <tr>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">No</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Name</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Period</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Current Status</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">New Status</th>
                      <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Change Status To</th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredAttendanceEdit.map((record, index) => (
                      <tr key={record.attendance_id}>
                        <td className="px-6 py-2">{index + 1}</td>
                        <td className="px-6 py-2">{record.student_first_name} {record.student_middle_name} {record.student_last_name}</td>
                        <td className="px-6 py-2">Period - {record.period}</td>
                        <td className="px-6 py-2">{(record.status).charAt(0).toUpperCase() + (record.status).slice(1)}</td>
                        <td className="px-6 py-2">{localStatuses[record.attendance_id] || 'Not Changed'}</td>
                        <td className="py-2 pl-0 -ml-4 flex space-x-4">
                          <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-1 px-2 rounded" onClick={() => handleAttendanceStatusChange(record.attendance_id, 'present')}>Present</button>
                          <button className="bg-yellow-700 hover:bg-yellow-500 text-white py-1 px-2 rounded" onClick={() => handleAttendanceStatusChange(record.attendance_id, 'late')}>Late</button>
                          <button className="bg-orange-700 hover:bg-orange-500 text-white py-1 px-2 rounded" onClick={() => handleAttendanceStatusChange(record.attendance_id, 'excused')}>Excuse</button>
                          <button className="bg-red-700 hover:bg-red-500 text-white py-1 px-2 rounded" onClick={() => handleAttendanceStatusChange(record.attendance_id, 'absent')}>Absent</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </>
            )}
          <div className="flex justify-start space-x-2 py-4">
                <button onClick={handleStatusEdit} className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded">
                           Save Changes
                </button>
                <button type="button" onClick={handleCloseEditModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                          Cancel
                </button>
          </div>
        </div>
      </Modal>
  
    </div>
    )
}

export default teacherAttendance