import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);

	useEffect(() => {
		const storedUser = localStorage.getItem("user");
		if (storedUser) {
			const parsedUser = JSON.parse(storedUser);
			setUser(parsedUser);
			setToken(parsedUser.token);
		}
	}, []);

	return (
		<AuthContext.Provider value={{ user, setUser, token }}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthProvider };
