//icons
import { VscThreeBars } from "react-icons/vsc";
import { CiInboxIn } from "react-icons/ci";
import { FcAbout } from "react-icons/fc";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiStudent } from "react-icons/pi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TfiAnnouncement } from "react-icons/tfi";
import { TbReportAnalytics } from "react-icons/tb";
import { BsTable } from "react-icons/bs";
import { TbReport } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";

import Inbox from "./pages/inbox";

import AdminDashboard from "./pages/dashboard/admin";
import AdminAnnouncement from "./pages/announcement/admin";
import Students from "./pages/students/admin";
import Teachers from "./pages/teachers";
import AdminGradeReport from "./pages/grade/admin";
import Classes from "./pages/Classes/admin";
import Courses from "./pages/Courses/admin";
import StudentClassEnrollment from "./pages/enrollments/admin/studentClassEnrollment";
import AdminAttendance from "./pages/attendance/admin";
import EmployeeRoleManagement from "./pages/employee/employeeManagement";
import Guardian from "./pages/guardian/admin";
import AdminStudentTimetable from "./pages/timetable/admin/studentTimetable";
import AdminTeacherTimetable from "./pages/timetable/admin/teacherTimetable";
// import AdminScheduleTimetable from "./pages/timetable/admin/scheduleTimetable";
import Period from "./pages/period/period";
import AdminProfile from "./pages/profile/admin";

import TeacherDashboard from "./pages/dashboard/teacher";
import TeacherTimetable from "./pages/timetable/teacher";
import TeacherClasses from "./pages/Classes/teacher";
import TeacherClassDetail from "./pages/Classes/teacher/teacherClassDetail";
import TeacherAttendance from "./pages/attendance/teacher";
import TeacherGradeReport from "./pages/grade/teacher";
import TeacherAnnouncement from "./pages/announcement/teacher";
import TeacherCalendar from "./pages/calendar/teacher";
import TeacherProfile from "./pages/profile/teacher";

import StudentDashboard from "./pages/dashboard/student";
import StudentTimetable from "./pages/timetable/student";
import StudentClasses from "./pages/Classes/student";
import StudentClassDetail from "./pages/Classes/student/studentClassDetail";
import StudentAttendance from "./pages/attendance/student";
import StudentGradeReport from "./pages/grade/student";
import StudentAnnouncement from "./pages/announcement/student";
import StudentCalendar from "./pages/calendar/student";
import StudentProfile from "./pages/profile/student";

import ParentDashboard from "./pages/dashboard/guardian";

const DefaultViews = {
	"/inbox": {
		label: "Inbox",
		component: <Inbox />,
		icon: CiInboxIn,
	},
};

const RoleBasedViews = {
	"7fe7aa01-53de-40fd-93d6-3bd7f16b97e9": {
		name: "admin",
		routes: {
			"/": {
				label: "dashboard",
				component: <AdminDashboard />,
				icon: LuLayoutDashboard,
			},
			"/students": {
				label: "Students",
				component: <Students />,
				icon: PiStudent,
			},
			"/teachers": {
				label: "Teachers",
				component: <Teachers />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/guardian": {
				label: "Guardian",
				component: <Guardian />,
				icon: FaUsers,
			},
			"/classes": {
				label: "Classes",
				component: <Classes />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/courses": {
				label: "Courses",
				component: <Courses />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/studentClassEnrollment": {
				label: "Class Enrollment",
				component: <StudentClassEnrollment />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/adminPeriod": {
				label: "Period Management",
				component: <Period />,
				icon: BsTable,
			},
			// "/scheduleTimetable": {
			// 	label: "Schedule Timetable",
			// 	component: <AdminScheduleTimetable />,
			// 	icon: BsTable,
			// },
			"/adminStudentTimetable": {
				label: "Student Timetable",
				component: <AdminStudentTimetable />,
				icon: BsTable,
			},
			"/adminTeacherTimetable": {
				label: "Teacher Timetable",
				component: <AdminTeacherTimetable />,
				icon: BsTable,
			},
			"/announcement": {
				label: "Announcement",
				component: <AdminAnnouncement />,
				icon: TfiAnnouncement,
			},
			"/adminGradeReport": {
				label: "Grade Report",
				component: <AdminGradeReport />,
				icon: TbReportAnalytics,
			},
			"/adminAttendance": {
				label: "Attendance",
				component: <AdminAttendance />,
				icon: TbReport,
			},
			"/employeeManagement": {
				label: "Employee Management",
				component: <EmployeeRoleManagement />,
				icon: TbReport,
			},
			"/adminProfile": {
				label: "Profile",
				component: <AdminProfile />,
				icon: CgProfile,
			},
		},
	},

	"d8511520-ac9c-4535-b338-8922cf49a223": {
		name: "teacher",
		routes: {
			"/": {
				label: "dashboard",
				component: <TeacherDashboard />,
				icon: LuLayoutDashboard,
			},
			"/teacherTimetable": {
				label: "Timetable",
				component: <TeacherTimetable />,
				icon: BsTable,
			},
			"/teacherClasses": {
				label: "Classes",
				component: <TeacherClasses />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/teacherAttendance": {
				label: "Attendance",
				component: <TeacherAttendance />,
				icon: TbReport,
			},
			"/teacherClassDetail": {
				label: "Class Detail",
				component: <TeacherClassDetail />,
				icon: VscThreeBars,
			},
			"/teacherGradeReport": {
				label: "Grade Report",
				component: <TeacherGradeReport />,
				icon: TbReportAnalytics,
			},
			"/teacherAnnouncement": {
				label: "Announcement",
				component: <TeacherAnnouncement />,
				icon: TfiAnnouncement,
			},
			"/teacherCalendar": {
				label: "Calendar",
				component: <TeacherCalendar />,
				icon: IoCalendarNumberOutline,
			},
			"/teacherProfile": {
				label: "Profile",
				component: <TeacherProfile />,
				icon: CgProfile,
			},
		},
	},

	"cc4cbae1-057e-44bf-aee0-7d902e09f6c2": {
		name: "student",
		routes: {
			"/": {
				label: "dashboard",
				component: <StudentDashboard />,
				icon: LuLayoutDashboard,
			},
			"/studentTimetable": {
				label: "Timetable",
				component: <StudentTimetable />,
				icon: BsTable,
			},
			"/studentClasses": {
				label: "Classes",
				component: <StudentClasses />,
				icon: LiaChalkboardTeacherSolid,
			},
			"/studentClassDetail": {
				label: "Class Detail",
				component: <StudentClassDetail />,
				icon: VscThreeBars,
			},
			"/studentAttendance": {
				label: "Attendance",
				component: <StudentAttendance />,
				icon: TbReport,
			},
			"/studentGradeReport": {
				label: "Grade Report",
				component: <StudentGradeReport />,
				icon: TbReportAnalytics,
			},
			"/studentAnnouncement": {
				label: "Announcement",
				component: <StudentAnnouncement />,
				icon: TfiAnnouncement,
			},
			"/studentCalendar": {
				label: "Calendar",
				component: <StudentCalendar />,
				icon: IoCalendarNumberOutline,
			},
			"/adminProfile": {
				label: "Profile",
				component: <StudentProfile />,
				icon: CgProfile,
			},
		},
	},

	"64b8d9ae-74fd-439c-92f0-99cbbe4d1f73": {
		name: "guardian",
		routes: {
			"/": {
				label: "dashboard",
				component: <ParentDashboard />,
				icon: LuLayoutDashboard,
			},
		},
	},
};

export { DefaultViews, RoleBasedViews };
