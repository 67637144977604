import React from 'react'

function AdminDashboard() {
    
    return (
        <div className="p-5">
            <h1 className="text-4xl font-bold mb-8 p-5">Dashboard (School Admin View)</h1>

            {/* top cards */}
            <div className="flex justify-around">
                <div className="w-1/3 bg-red-200 p-4 text-center shadow-md mx-2 rounded-lg">
                    <p className="text-xl font-semibold">Total Teachers</p>
                    <p className="text-lg font-light">200</p>
                </div>
                <div className="w-1/3 bg-blue-200 p-4 text-center shadow-md mx-2 rounded-lg">
                    <p className="text-xl font-semibold">Total Students</p>
                    <p className="text-lg font-light">2000</p>
                </div>
                <div className="w-1/3 bg-green-200 p-4 text-center shadow-md mx-2 rounded-lg">
                    <p className="text-xl font-semibold">Total Parents</p>
                    <p className="text-lg font-light">1000</p>
                </div>
            </div>

            {/* Admin should see the number of boys and girls enrolled in school. This should be presented by a graph. */}

            {/* Admin should view attendance rates, academic performance, and other important statistics */}
        </div>
    );
}

export default AdminDashboard;

