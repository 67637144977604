import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../contexts/auth'
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Students() {
    //logged in user
    const {user, token } = useContext(AuthContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        user:{ first_name: '',
               middle_name: '',
               last_name: '',
               mobile: '',
               password: ''
           },
        student:{
            date_of_birth: '',
            grade: '',
            gender: '',
            student_number: ''
         }
    });
    const [updateMode, setUpdateMode] = useState(false); //to identify operation
    const [updateId, setUpdateId] = useState(null); //to identify record to be modified
    const [password, setPassword] = useState(''); //to store randomly generated password

    //fetching student data from provided API
    const [students, setStudents] = useState([]); //holds fetched student data from API
    const [selectedGrade, setSelectedGrade] = useState(''); //selected grades to display students detail of it
    const [eachGrade, setEachGrade] = useState([]); //holds each grade for select option
    const [searchTerm, setSearchTerm] = useState(''); //holds name of student to search its detail
    const [wantedStudents, setWantedStudents] = useState([]); //set of students fullfilling search condition

    const [loading, setIsLoading] = useState(true); // Add loading state

    //student data fetching part
    useEffect(()=>{
        const fetchStudent = async () =>{
            try{ 
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/students`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                    });
                const respondedStudent =  await response.json();
                setStudents(respondedStudent);

                const grades = [...new Set(respondedStudent.map(stud =>stud.student_grade))];
                setEachGrade(grades);
            }
            catch(error){
                console.log('Error:', error);
            } finally {
                setIsLoading(false); // Stop loading when data is fetched
            }
        };
        fetchStudent();  
    }, []);

    //filtering returned response based on first name, middle name or last name or grade
    const handleGradeSelected =(e) =>{
        const grade = e.target.value;
        setSelectedGrade(grade); //keeps selected grade
    };
  
    useEffect(()=>{
        let wanted = students || []; //assume all students are wanted
        if(selectedGrade){
            wanted = wanted.filter(student => student.student_grade=== Number(selectedGrade));
        }

        if(searchTerm){
            wanted = wanted.filter(student =>
            student.student_first_name.toLowerCase().includes(searchTerm.toLowerCase())||
            student.student_middle_name.toLowerCase().includes(searchTerm.toLowerCase())||
            student.student_last_name.toLowerCase().includes(searchTerm.toLowerCase())
            ); 
        } 
        setWantedStudents(wanted);
        setCurrentPage(1); //set current page to 1 when filtering done
    }, [students, selectedGrade, searchTerm]); 

    //generating random password
    const generateRandomPassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@';
        let generatedPassword = '';
        const passwordLength = 6;
        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            generatedPassword += characters[randomIndex];
        }
        setPassword(generatedPassword);
    };

    useEffect(() => {
            generateRandomPassword()
    }, []);

    //pagination state variables
    const itemsPerPage = 10; // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(wantedStudents.length / itemsPerPage);
    const indexOfLastStudent = currentPage * itemsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - itemsPerPage;
    const currentStudents = wantedStudents.slice(indexOfFirstStudent, indexOfLastStudent);

    const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    };

    //other events handling
    const handleAddStudentClick = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setFormData({
            user: { 
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    mobile:'',
                    password:''
                },
            student: {
                date_of_birth:'', 
                gender: '',
                grade: '',
                student_number:''
                }
        }); //clearing form
        setUpdateId(null);
        setUpdateMode(false);
        setModalIsOpen(false); 
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        if (name in formData.user) {
            setFormData((prevData) => ({
                ...prevData,
                user: {
                    ...prevData.user,
                    [name]: value
                }
            }));
        } else if (name in formData.student) {
            setFormData((prevData) => ({
                ...prevData,
                student: {
                    ...prevData.student,
                    [name]: value
                }
            }));
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try{
            if(updateMode) {
                const update = await fetch(`${process.env.REACT_APP_API_URL}/v1/students/${updateId}`,{
                            method:'PATCH',
                            headers:{
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                                },
                          body:JSON.stringify(formData)
                     });
                if (update.ok) {
                        alert('Student information modified successfully!');       
                    }
                else {
                        alert('Sorry, operation cancelled.');
                    }
                handleCloseModal();    
             }
            else {
                 const create = await fetch(`${process.env.REACT_APP_API_URL}/v1/students`,{
                        method: 'POST',
                        headers: {
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                           },
                        body:JSON.stringify(formData)
                    });
                if (create.ok) {
                        alert('Student information added successfully!');
                    } 
                else {
                        alert('Sorry, operation cancelled.');
                    }   
                handleCloseModal(); 
            }
            fetchStudent(); 
          } //end of try block
        catch(error){
                   console.error(error);
            }
    }; //end of inputHandling part

    //handling update
    const handleUpdate = (student) => {
        setUpdateId(student.student_user_id);
        setUpdateMode(true);
        setModalIsOpen(true);
        setFormData({   
            user:{
                first_name:student.student_first_name, 
                middle_name:student.student_middle_name, 
                last_name:student.student_last_name, 
                mobile:student.mobile,
                password:password
            },
            student:{ 
                date_of_birth:student.student_date_of_birth,
                gender:student.student_gender,
                grade:student.student_grade,
                student_number:student.student_number
            }
        });
    };

    //To ensure age value of student is minimum of five year. calculated from dob
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 5)).toISOString().split('T')[0];

    //loading state
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading list of students, please wait...
                    </p>
                </div>
            </div>
        );
    }

    //displaying student details
    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">Students Details</h1>      
            <div className="flex items-center gap-4 mb-4">
                {/* Dropdown for selecting grade */}
                <div className="w-1/4 pr-4">
                    <select id="gradeSelect" 
                        name="grade" 
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedGrade}
                        onChange ={handleGradeSelected}
                    >
                        <option value="">All Grades</option>
                        {eachGrade.map((grade) => (
                        <option key={grade} value={grade}>Grade {grade}</option>
                        ))}
                    </select>
                </div>

                {/* Search Bar */}
                <div className="w-1/4">
                    <input
                        type="text"
                        placeholder="Search student by name..."
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Add student button */}
                <div className="flex justify-end w-2/3">
                    <button onClick={handleAddStudentClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                        Add New Student
                    </button>
                </div>
            </div>

            {/* List of students */}
            <div className="mt-8">
                <div>
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-slate-200">
                            <tr>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> No. </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> First Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Middle Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Last Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Gender </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Grade </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Student No. </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> DoB </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">  </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentStudents.length === 0 ? (
                                <tr>
                                    <td colSpan="10" className="text-center py-4">No student available.</td>
                                </tr>
                                ) : (
                                    
                                    currentStudents.map((student, index) => (
                                        <tr className="border-b border-gray-200" key={student.student_id}>
                                            <td className="px-6 py-4">{indexOfFirstStudent + index + 1}</td>
                                            <td className="px-6 py-4">{student.student_first_name}</td>
                                            <td className="px-6 py-4">{student.student_middle_name}</td>
                                            <td className="px-6 py-4">{student.student_last_name}</td>
                                            <td className="px-6 py-4">{student.student_gender}</td>
                                            <td className="px-6 py-4">{student.student_grade}</td>
                                            <td className="px-6 py-4">{student.student_number}</td>
                                            <td className="px-6 py-4">{student.student_date_of_birth}</td>
                                            <td className="px-6 py-4">
                                                <button className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-1 px-2 rounded" 
                                                    onClick ={() => handleUpdate(student)} >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr> 
                                    ))
                                )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/*pegenation button*/}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`mx-1 px-4 py-2 border rounded ${
                            currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
                        }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add Student Modal" >
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <form onSubmit={handleFormSubmit}>
                        <h2 className="text-xl font-bold mb-4">{updateMode ? 'Edit Student':'Add New Student'}</h2>
                        <div className="p-3">
                            <label htmlFor="studentNumber">Student Number</label>
                            <input
                                id="student_number"
                                name="student_number"
                                type="text"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.student.student_number}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                id="first_name"
                                name="first_name"
                                type="text"
                                required
                                pattern="[A-Za-z\s]{2,}"
                                title='First name must contain characters only'
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.first_name}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="middleName">Middle Name</label>
                            <input
                                id="middle_name"
                                name="middle_name"
                                type="text"
                                required
                                pattern="[A-Za-z\s]{2,}"
                                title='Middle name must contain characters only'
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.middle_name}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                id="last_name"
                                name="last_name"
                                type="text"
                                required
                                pattern="[A-Za-z\s]{2,}"
                                title='Last name must contain characters only'
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.last_name}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="dateOfBirth">Date of Birth:</label>
                            <input
                                id="date_of_birth"
                                name="date_of_birth"
                                type="date"
                                max={maxDate}
                                required
                                title="Age must be minimum of five years"
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.student.date_of_birth}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label htmlFor="gender">Gender</label>
                            <select
                                id="gender"
                                name="gender"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.student.gender}
                                onChange={handleFormChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>

                        <div className="p-3">
                            <label htmlFor="grade">Grade</label>
                            <input
                                id="grade"
                                name="grade"
                                type="number"
                                min="1"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.student.grade}
                                onChange={handleFormChange}
                            />
                        </div>
                        {!updateMode &&(
                            <>
                            <div className="p-3">
                                <label htmlFor="grade">Mobile</label>
                                <input
                                    id="mobile"
                                    name="mobile"
                                    type="text"
                                    className="border border-gray-300 p-2 rounded block w-1/3"
                                    value={formData.user.mobile}
                                    onChange={handleFormChange} />
                            </div>
                            <div className="p-3">
                                <label htmlFor="grade">Password</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="text"
                                    className="border border-gray-300 p-2 rounded block w-1/3"
                                    value={password}
                                    onChange={handleFormChange} />
                            </div>
                                </>
                        )}
                            
                        <div className="p-3">
                            <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                              {updateMode ? 'Save Changes': 'Register Student'}
                            </button>
                            <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default Students;