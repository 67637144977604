import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect, useContext, useState } from 'react';
import Header from './header';
import Footer from './footer';
import NavigationSidebar from '../components/sidebar/navigation';
import { AuthContext } from '../contexts/auth';
import { SidebarProvider } from '../contexts/sidebar';
import { ProtectedRoute } from './protected_route';

import HttpStatusForbidden from '../pages/error/forbidden';
import SignIn from '../pages/signin';
// import About from '../pages/about';
import { useLocation, useNavigate } from "react-router-dom";


function Layout() {
    return (
        <Routes>
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/403" element={<HttpStatusForbidden />} />
            <Route path="/*" element={
                <SidebarProvider>
                    <AuthenticatedLayout />
                </SidebarProvider>
            } />
        </Routes>
    )
}

function AuthenticatedLayout() {

    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!user) {
            navigate("/signin");
            return;
        }
    }, [user]);

    if (!user) {
        return <div>loading...  {JSON.stringify(user)}</div>;
    }

    return (
        <div className='flex h-screen relative z-0'>
            <NavigationSidebar />
            <div className='flex flex-col flex-grow h-full'>
                <Header />
                <main className='flex-grow'>
                    <Routes>
                        <Route path="/" element={<ProtectedRoute />} />
                        <Route path="/languages" element={<ProtectedRoute />} />
                        <Route path="/inbox" element={<ProtectedRoute />} />

                        <Route path="/announcement" element={<ProtectedRoute />} />
                        <Route path="/students" element={<ProtectedRoute />} />
                        <Route path="/teachers" element={<ProtectedRoute />} />
                        <Route path="/classes" element={<ProtectedRoute />} />
                        <Route path="/courses" element={<ProtectedRoute />} />
                        <Route path="/studentClassEnrollment" element={<ProtectedRoute />} />
                        <Route path="/adminGradeReport" element={<ProtectedRoute />} />
                        <Route path="/adminAttendance" element={<ProtectedRoute />} />
                        <Route path="/employeeManagement" element={<ProtectedRoute />} />
                        <Route path="/guardian" element={<ProtectedRoute />} />
                        <Route path="/adminStudentTimetable" element={<ProtectedRoute />} />
                        <Route path="/adminTeacherTimetable" element={<ProtectedRoute />} />
                        <Route path="/scheduleTimetable" element={<ProtectedRoute />} />
                        <Route path="/adminPeriod" element={<ProtectedRoute />} />
                        <Route path="/adminProfile" element={<ProtectedRoute />} />

                        <Route path="/teacherTimetable" element={<ProtectedRoute />} />
                        <Route path="/teacherClasses" element={<ProtectedRoute />} />
                        <Route path="/teacherClassDetail" element={<ProtectedRoute />} />
                        <Route path="/teacherAttendance" element={<ProtectedRoute />} />
                        <Route path="/teacherGradeReport" element={<ProtectedRoute />} />
                        <Route path="/teacherAnnouncement" element={<ProtectedRoute />} />
                        <Route path="/teacherCalendar" element={<ProtectedRoute />} />
                        <Route path="/teacherProfile" element={<ProtectedRoute />} />

                        <Route path="/studentTimetable" element={<ProtectedRoute />} />
                        <Route path="/studentClasses" element={<ProtectedRoute />} />
                        <Route path="/studentClassDetail" element={<ProtectedRoute />} />
                        <Route path="/studentAttendance" element={<ProtectedRoute />} />
                        <Route path="/studentGradeReport" element={<ProtectedRoute />} />
                        <Route path="/studentAnnouncement" element={<ProtectedRoute />} />
                        <Route path="/studentCalendar" element={<ProtectedRoute />} />
                        <Route path="/studentProfile" element={<ProtectedRoute />} />

                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default Layout;