import React, { useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

function AdminAnnouncement() {
    const {user, token } = useContext(AuthContext);
    //for pop up modal
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [updateMode, setUpdateMode] = useState(false); //to identify operation
    const [updateId, setUpdateId] = useState(null); //to identify record to be modified
    const [roles, setRoles] = useState([]); //holds all roles responded from api
    const [selectedRoles, setSelectedRoles] = useState([]); //target audiences role(s)
    const [formData, setFormData] = useState({
    	  roles: [],
    	  subject: '',
    	  content: ''
  	});

    //announcments
    const [announcements, setAnnouncements] = useState([]);
    useEffect(() => {
        const fetchAnnouncements = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/announcements`,{
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const announcmentsData = await response.json();
            setAnnouncements(announcmentsData);
          } 
          catch (err) {
            console.error('Error fetching roles:', err);
          } 
        };
        fetchAnnouncements();
      }, []);

     //fetching roles data
     useEffect(() => {
        const fetchRoles = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/roles`, {
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            });
            const rolesData = await response.json();
            setRoles(rolesData);
          } catch (error) {
            console.error('Error fetching roles:', error);
          }
        };
    
        fetchRoles();
      }, []);

       //selected roles
       const handleRoleSelection = (role_id) => {
        setSelectedRoles((prevSelectedRoles) => {
            const newSelectedRoles = prevSelectedRoles.includes(role_id)
                ? prevSelectedRoles.filter((id) => id !== role_id)
                : [...prevSelectedRoles, role_id];
    
            setFormData((prevData) => ({
                ...prevData,
                roles: newSelectedRoles
            }));
            return newSelectedRoles;
        });
    };

    const handleAddAnnouncementClick = () => {
        setModalIsOpen(true);
    };
    const handleCloseModal = () => {
        setFormData({
            roles: [],
            subject: '',
            content: ''
          });
        setUpdateId(null);
        setUpdateMode(false);
        setModalIsOpen(false);
    };

    //accepting value from form
   const handleFormChange = (e) => {
    setFormData({ 
          ...formData, [e.target.name]: e.target.value 
     });
  };

    //performing operation
    const handleFormSubmit = async (e) => {
        e.preventDefault();
	      try{
             if(updateMode) {
                  const update = await fetch(`${process.env.REACT_APP_API_URL}/v1/announcements/${updateId}`,{
                            method:'PATCH',
                            headers:{
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                                },
                          body:JSON.stringify(formData)
                     });
                  if (update.ok) {
                        alert('Announcement updated successfully!');       
                    }
                  else {
                        alert('Sorry, operation cancelled.');
                    }
                 handleCloseModal();    
               }

            else {
	              const create = await fetch(`${process.env.REACT_APP_API_URL}/v1/announcements`,{
                        method: 'POST',
                        headers: {
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                           },
                        body:JSON.stringify(formData)
                    });
                if (create.ok) {
                        alert('Announcement added successfully!');
                    } 
                else {
                        alert('Sorry, operation cancelled.');
                    }   
                handleCloseModal(); 
              }
          }
       catch(error){
                   console.error(error);
            }
     };

    //handling updates
    const handleUpdate = (announcement) => {
        setUpdateId(announcement.announcement_id);
        setUpdateMode(true);
        setModalIsOpen(true);
        setFormData({
      		roles: selectedRoles,
      		subject: announcement.announcement_subject,
      		content: announcement.announcement_content
    	});
    };

    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold p-5">List of Announcements</h1>
            {/* Add Announcement button */}
            <div className="flex justify-end">
                <button onClick={handleAddAnnouncementClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Add New Announcement
                </button>
            </div>

            <div className="bg-slate-200 mt-2 p-4 shadow-md rounded-md">
                {/* List of announcements */}
                  <div className="flex flex-col gap-2 ">
                  { announcements.length === 0 ? (<h1>No Announcement available.</h1>): (announcements.map((announcement) => (
                    <div key={announcement.announcement_id} className="bg-slate-100 p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold">{announcement.announcement_subject}</h2>
                        <p className="text-gray-700">{announcement.announcement_content}</p>
                        <p className="text-md font-light text-gray-700">Posted by: {announcement.from_first_name} {announcement.from_middle_name}</p>
                          <div className="flex justify-end gap-2 mt-2">
                            <button className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-1 px-2 rounded"
                            onClick ={() => handleUpdate(announcement)} >
                                Edit
                            </button>
                            <button className="bg-red-700 hover:bg-red-500 text-white font-bold py-1 px-2 rounded">
                                Delete
                            </button>
                          </div>
                      </div>
                    ))
                  )
                }

                </div>
            </div>
            
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add announcement Modal" >
                <div className="bg-white p-5 rounded-md shadow-md">
                    <h1 className="text-xl font-bold mb-4">{updateMode ? 'Edit Announcement':'Add New Announcement'}</h1>
                    <form onSubmit={handleFormSubmit}>
                        <div className="p-3">
                            <label> Announcement Title:</label>
                            <input
                                id="subject"
                                name="subject"
                                type="text"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.subject}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label>Announcement Content:</label>
                            <textarea
                                id="content"
                                name="content"
                                className="p-3 rounded block w-full border border-gray-300"
                                value={formData.content}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="p-3">
                            <label>Target Audiences:</label>
                            <div>
        			                {roles.map((role) => (
          				              <div key={role.role_id}>
            					            <input 
							                        type="checkbox" 
							                        id={role.role_id}
              						            onChange={() => handleRoleSelection(role.role_id)}
              						            checked={selectedRoles.includes(role.role_id)}
            					            />
            					        <label htmlFor={role.role_id}>{role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}</label>
          				            </div>
        			               )
				                  )}
      			            </div>
                        </div>

                        <div className="p-3">
                            <button className="bg-cyan-950 text-white  py-2 px-4 rounded">
                                {updateMode ? 'Save Changes': 'Add Announcement'}
                            </button>
                            <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>    
        </div>
    );
}

export default AdminAnnouncement;