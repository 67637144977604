import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";

function StudentAnnouncement() {
    const { user, token } = useContext(AuthContext);
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        const fetchAnnouncements = async () =>{
            try{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/announcements`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                  });
               const respondedAnnouncements =  await response.json();
               setAnnouncements(respondedAnnouncements);
            }
            catch(error){
                console.log('Error fetching announcements:', error);
           } finally {
            setLoading(false);
        }
       };
       fetchAnnouncements();  
   }, []);

   if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading Announcement...
                    </p>
                </div>
            </div>
        );
    }

    //filtering out school announcement and class announcements
    const schAnnouncements = announcements.filter(announcement=>announcement.announcement_category ==='school');
    const clsAnnouncements = announcements.filter(announcement=>announcement.announcement_category !== 'school');
   
    return (
        <div className='p-2'>
            {/* School Announcement */}
            <div className="bg-slate-200 mt-2 p-4 shadow-md rounded-md">
                <h1 className="text-xl font-bold uppercase tracking-wider mb-2">School Announcements</h1>
                <div className="flex flex-col gap-2 h-64 overflow-y-auto">
                    {schAnnouncements.length === 0 ? (
                        <div className="text-center py-4">No school announcements available.</div>
                    ) : (
                        schAnnouncements.map((announcement, index) => (
                            <div className="bg-slate-100 p-4 shadow-md rounded-md" key={index}>
                                <h2 className="text-lg font-semibold">{index + 1}. {announcement.announcement_subject}</h2>
                                <p className="text-gray-700">{announcement.announcement_content}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* Class Announcement */}
            <div className="bg-slate-200 mt-4 p-4 shadow-md rounded-md">
                <h1 className="text-xl font-bold uppercase tracking-wider mb-2">Class Announcements</h1>
                <div className="flex flex-col gap-2 h-96 overflow-y-auto">
                    {clsAnnouncements.length === 0 ? (
                        <div className="text-center py-4">No class announcements available.</div>
                    ) : (
                        clsAnnouncements.map((announcement, index) => (
                            <div className="bg-slate-100 p-4 shadow-md rounded-md" key={index}>
                                <h2 className="text-lg font-semibold">{index + 1}. {announcement.announcement_category}</h2>
                                <p className="text-lg font-semibold text-gray-700">{announcement.announcement_subject}</p>
                                <p className="text-gray-700">{announcement.announcement_content}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}
export default StudentAnnouncement;