import React, {  useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

function TeacherClasses() {
    const {user, token } = useContext(AuthContext);
    const [loading, setLoading] =useState(true); //set loading status to true
    const [classData, setClassData] = useState([]); //to keep all possible class
    const [classId, setClassId] = useState('');
    
    /*fetching class list */
    useEffect(() => {
        const fetchClasses = async () => {
        try {
            const clssResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`,{
               headers: {
                   'Authorization':`Bearer ${token}`,
                   'Content-Type':'application/json'
               }
           });
        if (!clssResponse.ok) {
            throw new Error('Network response was not ok');
            }
        const clssData = await clssResponse.json();
        //const uniqueClasses = Array.from(new Set(classData.map(clss => clss.class_id))).map(id => classData.find(clss => clss.class_id === id));
        setClassData(clssData);
      } 
     catch (err) {
       console.log("Error:", err);
     } 
     finally{
         setLoading(false);
     }
   };
    fetchClasses();
   }, [token]);

   const classDetail = (clss) => {
     setClassId(clss);
     console.log("Class ID: ", classId);
    }

    const handleBackToClass =()=>{
        setClassId('');
    }

    // Modal - for pop up
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
    const [addFileModalIsOpen, setAddFileModalIsOpen] = useState(false);

    const [selectedAssignment, setSelectedAssignment] = useState(null);

    //state variables for assignments fetching
    const [assignments, setAssignments] = useState([]);
    const [upcomingAssignments, setUpcomingAssignments] = useState([]);
    const [pastAssignments, setPastAssignments] = useState([]);
    

    const [assignmentData, setAssignmentData] = useState({
        class_id: classId,
        name: '',
        description: '',
        total: '',
        type: '',
        due_date: ''
    });

    /*Candidate assignment of selected class to upload file*/
    const [candiateAssignments, setCandiateAssignments] = useState([]);
    const [assignmentUpload, setAssignmentUpload] =useState('');

    const [files, setFiles] = useState([]);
    const [descriptions, setDescriptions] = useState([])
    const [uploading, setUploading] = useState(false);

    //fetching files
    const [getFileModalIsOpen, setGetFileModalIsOpen] = useState(false);
    const [existingFiles, setExistingFiles] = useState([]);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [newFile, setNewFile] = useState(null);
    const [newDescription, setNewDescription] = useState('');
    const [assignmentId, setAssignmentId] = useState('');

    

    const handleOpenAddModal = () => {
        setAddModalIsOpen(true);
    }
 
    const handleOpenAddFileModal = () => {
        setAddFileModalIsOpen(true);
    }

    const handleCloseAddModal = () => {
        setAddModalIsOpen(false);
        resetForm();
    }

    const handleCloseAddFileModal =()=>{
        resetUploadForm();
        setAddFileModalIsOpen(false);
    }

    const resetUploadForm = () => {
        setFiles([]);
        setDescriptions([]);
       
    };

    const handleCloseViewModal = () => {
        resetForm(); //added
        setSelectedAssignment(null);
        setViewModalIsOpen(false);  
    }

    const resetForm = () => {
        setAssignmentData({
            class_id: classId,
            name: '',
            description: '',
            total: '',
            type: '',
            due_date: ''
        });
    };

    /*Start of fetching assignments and identifying upcoming as well as past assignments*/
    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/assignments`, {
                'headers': {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                },
            });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log("Assignment Data:",data);
                setAssignments(data);
            } 
            catch (err) {
                console.log(err);
            } 
            finally {
                setLoading(false);
            }
        };
        fetchAssignments();
    }, [token]);

    useEffect(() => {
        const currentDate = new Date();

        const filteredAssignments = assignments.filter(assignment => assignment.class_id === classId); //assignment of class selected

        const upcoming = filteredAssignments.filter(assignment => new Date(assignment.assignment_due_date) >= currentDate);
        const past = filteredAssignments.filter(assignment => new Date(assignment.assignment_due_date) < currentDate);
        setUpcomingAssignments(upcoming);
        setPastAssignments(past);
        console.log("upcoming:", upcomingAssignments);
        console.log("Past assignments:",pastAssignments);
    }, [assignments, classId]);

    /*End of fetching assignments and identifying upcoming as well as past assignments*/

    /*Starting of new assignment creation*/
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAssignmentData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddAssignment = async (e) => {
        e.preventDefault();

        const { name, type, due_date, total, description } = assignmentData;
        if (!name || !type || !due_date || !total || !description) {
            alert('All fields are required.');
            return;
        }
        const newAssignment = {
            name: name,
            type: type,
            due_date: new Date(due_date).toISOString(),
            total: parseInt(total, 10),
            description: description,
            class_id: classId,
        };

        try {
            const addAssignmentResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/assignments`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newAssignment),
            });

            if (!addAssignmentResponse.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to add assignment: ${errorText}`);
            }
            alert('Assignment added successfully!');
            resetForm();
            handleCloseAddModal();
        } 
        catch (err) {
            console.log("Error:", err);
        }
    }
    /*End of new assignment creation*/

    /*Starting of assignment update*/
    const handleOpenViewModal = (assignment) => {
        setSelectedAssignment(assignment);
        setAssignmentData({
            class_id: assignment.class_id,
            name: assignment.assignment_name,
            description: assignment.assignment_description,
            total: assignment.assignment_total,
            type: assignment.assignment_type,
            due_date: assignment.assignment_due_date.split('T')[0],
        });
        setViewModalIsOpen(true);
    }

    const handleUpdateAssignment =async(e) => {
        e.preventDefault();
        const { assignment_id } = selectedAssignment;
        
        try{
            const update = await fetch(`${process.env.REACT_APP_API_URL}/v1/assignments/${assignment_id}`,{
                method:'PATCH',
                headers:{
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                 },
                 body: JSON.stringify({
                    ...assignmentData,
                    due_date: new Date(assignmentData.due_date).toISOString(),
                    total: parseInt(assignmentData.total),
                })
            });
            if (update.ok) {
                alert('Assignment updated successfully!'); 
                      
            }
            else {
                alert('Sorry, operation cancelled.');
            } 
            resetForm();
            handleCloseViewModal();  
         }
        catch(error){
            console.error(error);
         }  
    }
    /*End of assignment update*/

    /*Start of filtering set of assignment of selected class so as user can select one of it to upload assignment content*/
    useEffect(() => {
        const candidate = assignments.filter(assignment => assignment.class_id === classId);
        setCandiateAssignments(candidate);
    }, [classId, assignments]);
   
    const handleAssignmentChange = (event) => {
        const selectedAssignmentId = event.target.value;
        const selectedAssignment = candiateAssignments.find(assignment => assignment.assignment_id === selectedAssignmentId);
        setAssignmentUpload(selectedAssignment);
      };
    
     /*End of filtering set of assignment of selected class so as user can select one of it to upload assignment content*/

    /*Start of uploading new file for selected assignments*/
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
        setDescriptions(selectedFiles.map(() => ''));
      };

      const handleDescriptionChange = (index, event) => {
        const newDescriptions = [...descriptions];
        newDescriptions[index] = event.target.value;
        setDescriptions(newDescriptions);
      };

      const handleUpload = async (e) => {
        e.preventDefault();

        setUploading(true);
        const formData = new FormData();
        
        files.forEach((file, index) => {
            formData.append('files', file);
            formData.append('descriptions', descriptions[index] || '');
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/assets/assignments/${assignmentUpload.assignment_id}`, {
              method: 'POST',
              headers: {
                'Authorization':`Bearer ${token}`
              },
              body: formData,
            });
      
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`Failed to upload files: ${errorText}`);
            }
           
            alert("Selected document uploaded successfully");
          } 
          catch (error) {
            console.log("error", error);
          } 
          finally {
            setUploading(false);
          }
        };

    /*End of uploading new file for selected assignments*/

    /*Starting of fetching uploaded files for selected assignments */
    const handleOpenGetFileModal =(ass_id)=>{
        setAssignmentId(ass_id);
        console.log("Assignment ID:",assignmentId );
        
        setGetFileModalIsOpen(true); // to be change to its modal
    }

    const handleCloseGetFileModal =()=>{
        setGetFileModalIsOpen(false);
    }

    useEffect(() => {
        const fetchUploadedFiles = async () => {
          try {
                const assetResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/assignments/${assignmentId}/assets`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                    }
                  });
                  if(!assetResponse.ok){
                    const errorText = await assetResponse.text();
                    throw new Error(`Failed to load uploaded files: ${errorText}`);
                  }
            const assetData = await assetResponse.json();
            console.log("This is your asset", assetData);
            setExistingFiles(assetData);  
          } 
         catch (error) {
            console.error('Error fetching uploaded files: ', error);
          }
        };
        if (assignmentId && token) {
            fetchUploadedFiles();
        }
      }, [assignmentId, token]);

      /*End of fetching uploaded files for selected assignments*/

      /* Starting of file download*/
      const handleDownload = async(assetPath) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/assets/${assetPath}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                alert("Unable to download!");
            }
            const blob = await response.blob();
            if (!blob.size) {
                  throw new Error('Downloaded file is empty.');
            }
            const url = window.URL.createObjectURL(blob);
            const fileName = assetPath.split('/').pop();
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } 
        catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
      /*End of selected file download*/

      /*Start of file change section */
      const handleReplaceFile = (assetId) => {
        setSelectedAssetId(assetId);
      };

      const handleFileChanger = (event) => {
        setNewFile(event.target.files[0]);
      };

      const handleDescriptionChanger = (event) => {
        setNewDescription(event.target.value);
      };
    
     const handleFileReplace = async () => {
        if (!newFile || !newDescription) {
          alert('Please select file and provide description.');
          return;
        }

        const formDataLater = new FormData();
        formDataLater.append('file', newFile);
        formDataLater.append('description', newDescription);
    
        try {
            const changeFileResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/assets/assignments/${selectedAssetId}`, {
            method: 'PATCH',
            headers: {
                'Authorization':`Bearer ${token}`,
             },
            body: formDataLater,
          });
    
          if (changeFileResponse.ok) {
                alert('File changed successfully');
                setSelectedAssetId(null);
                setNewFile(null);
                setNewDescription('');
          }
        }
        catch(error){
            console.error('Error changing file: ', error);
         }
      }; 
    /*End of file change section*/  
  
    // array of colors to make each card show different colors
	const colors = ["bg-sky-200", "bg-pink-200", "bg-blue-300", "bg-indigo-200", "bg-cyan-200", "bg-purple-300", "bg-green-200", "bg-red-200", "bg-yellow-200"];

   if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center">
                <svg
                    className="animate-spin h-10 w-10 text-blue-500 mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                </svg>
                <p className="text-lg font-semibold text-gray-600">
                    Loading classes, please wait...
                </p>
            </div>
        </div>
    );
}

if(!classId){
    return (
    <div className="p-5">
        <h1 className="text-4xl font-semibold mb-8 p-5">All Classes</h1>
        {/* Display the list of classes */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-8 p-4">
            {classData.map((item, index) => (
                <div
                    key={`${item.class_id}-${index}`} //key={item.class_id}
                    className={`text-lg p-4 shadow-md rounded-lg ${colors[index % colors.length]}`}
                >
                    <p className="font-bold">Subject: {item.course_name}</p>
                    <p>Room Number: {item.room_number}</p>
                    <div key={item.class_id} className="flex justify-center mt-4">
                        <button
                            className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 text-lg rounded"
                            onClick={() => classDetail(item.class_id)}
                        >
                            View Class
                        </button>
                    </div>
                </div>
            ))}
        </div>
    </div>
  ); 
}  
else {
    return (
    <div className="p-5">
        <h1 className="text-3xl font-bold mb-6 p-5">{classData.course_name}</h1>

        <div className="flex justify-end p-5 space-x-4">
            <button onClick={handleOpenAddModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                Add New Assignment
            </button>

            <button onClick={handleOpenAddFileModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                Upload New Document
            </button>

            <button onClick={handleBackToClass} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                Back to Classes
            </button>
            
        </div>

        {/* Add New Assignment popup */}
        <Modal isOpen={addModalIsOpen} onRequestClose={handleCloseAddModal} contentLabel="Assignment Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4 text-center">Add New Assignment</h1>
                <form onSubmit={handleAddAssignment}>
                <div className="mb-4">
                    <label className="block mb-2">Category:</label>
                    <select className="block w-1/3 p-2 border border-gray-300 rounded"
                            name="type"
                            value={assignmentData.type}
                            onChange={handleChange}
                    >
                        <option value="">Select a category</option>
                        <option value="classwork">Class Work</option>
                        <option value="exam">Exam</option>
                        <option value="homework">Homework</option>
                        <option value="quiz">Quiz</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block mb-2">Assignment Title:</label>
                    <input
                         type="text"
                         name="name"
                         value={assignmentData.name}
                         onChange={handleChange}
                        className="block w-1/3 p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2">Due Date:</label>
                    <input 
                        type="date"
                        name="due_date"
                        min={new Date().toISOString().split('T')[0]}
                        value={assignmentData.due_date}
                        onChange={handleChange}
                        className="block w-1/3 p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2">Maximum Point:</label>
                    <input 
                        type="number"
                        name="total"
                        value={assignmentData.total}
                        onChange={handleChange}
                        className="block w-1/3 p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2">Content:</label>
                    <textarea 
                        name="description"
                        value={assignmentData.description}
                        onChange={handleChange}
                        className="block w-full p-2 border border-gray-300 rounded h-40"
                    />
                </div>

                <div className="flex justify-start space-x-2 py-4">
                    <button 
                        type="submit"
                        className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded"
                    >
                        Add Assignment
                    </button>
                    
                    <button type="button" onClick={handleCloseAddModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                        Cancel
                    </button>
                </div>
                </form>
            </div>      
        </Modal>

        {/* Up Coming Assignment */}
        <div className="p-5">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                <thead className="bg-gray-100">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                            Up Coming Assignments
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                
                {upcomingAssignments.length === 0 ? (
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                            <div>
                                <p>No Upcoming Assignments for {classData.course_name}</p>
                            </div>
                        </td>
                    </tr>
                    ) : ( upcomingAssignments.map(assignment => (
                    <tr key={assignment.assignment_id}>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                            <div>
                                <p>{assignment.assignment_name}</p>
                                <p className='text-sm text-gray-600'>Due Date: {new Date(assignment.assignment_due_date).toLocaleString()} | {assignment.assignment_total} pts</p>
                            </div>
                            <div>
                            <button onClick={() => handleOpenViewModal(assignment)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                View
                            </button>
                            <button onClick={() => handleOpenGetFileModal(assignment.assignment_id)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 ml-4 rounded">
                                Attachments
                            </button>
                            </div>
                        </td>
                    </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>

        {/* Past Assignment */}
        <div className="p-5">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                <thead className="bg-gray-100">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                            Past Assignments
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {pastAssignments.length === 0 ? (
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                            <div>
                                <p>No past assignments for {classData.course_name}</p>
                            </div>
                        </td>
                    </tr>
                ) : ( pastAssignments.map(assignment => (
                    <tr key = {assignment.assignment_id}>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                        <div>
                            <p>{assignment.assignment_name}</p>
                            <p className='text-sm text-gray-600'>Due Date: {assignment.assignment_due_date} | {assignment.assignment_total} pts</p>
                        </div>

                        <div>
                            <button onClick={() => handleOpenViewModal(assignment)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                View
                            </button>
                            <button onClick={() => handleOpenGetFileModal(assignment.assignment_id)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 ml-4 rounded">
                                Attachments
                            </button>
                        </div>
                        </td>
                    </tr>
                 ))
                )}
                </tbody>
            </table>
        </div>

        {/* View Assignment popup */}
        <Modal isOpen={viewModalIsOpen} onRequestClose={handleCloseViewModal} contentLabel="View Assignment Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4 text-center">Assignment Detail With Edit Mode</h1>
                {selectedAssignment ? (
                    <>
                    <form onSubmit={handleUpdateAssignment}>
                    <div className="mb-4">
                        <label className="block mb-2">Category:</label>
                        <select className="block w-1/3 p-2 border border-gray-300 rounded"
                                name="type"
                                value={assignmentData.type}
                                onChange={handleChange}
                        >
                            <option value="">Select a category</option>
                            <option value="classwork">Class Work</option>
                            <option value="exam">Exam</option>
                            <option value="homework">Homework</option>
                            <option value="quiz">Quiz</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Assignment Title:</label>
                        <input
                            type="text"
                            name="name"
                            value={assignmentData.name}
                            onChange={handleChange}
                            className="block w-1/3 p-2 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Due Date:</label>
                        <input 
                            type="date"
                            name="due_date"
                            min={new Date().toISOString().split('T')[0]}
                            value={assignmentData.due_date}
                            onChange={handleChange}
                            className="block w-1/3 p-2 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Maximum Point:</label>
                        <input 
                            type="number"
                            name="total"
                            value={assignmentData.total}
                            onChange={handleChange}
                            className="block w-1/3 p-2 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">Content:</label>
                        <textarea 
                            name="description"
                            value={assignmentData.description}
                            onChange={handleChange}
                            className="block w-full p-2 border border-gray-300 rounded h-40"
                        />
                    </div>
                    <div className="flex justify-start space-x-2 py-4">
                    <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded"
                            type="submit"
                    >
                        Edit Assignment
                    </button>
                    <button type="button" onClick={handleCloseViewModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                        Cancel
                    </button>
                </div>
                </form>
                 </>
                     ) : (
                        <p>Loading assignment details...</p>
                    )}
            </div>      
        </Modal>

        {/*Modal to upload files*/}
        <Modal isOpen={addFileModalIsOpen} onRequestClose={handleCloseAddFileModal} contentLabel="File Upload Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4 text-center">File Upload</h1>

                {candiateAssignments.length > 0 && (
                    <form onSubmit={handleUpload}>

                        <div className="mb-4">
                          <select   id="assignmentSelect" onChange={handleAssignmentChange}
                                    className="block w-1/3 p-2 border border-gray-300 rounded"
                          >
                            <option value="">Select Assignment</option>
                            {candiateAssignments.map(assignment => (
                                <option key={assignment.assignment_id} value={assignment.assignment_id}>
                                    {assignment.assignment_name}
                                </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="assignmentSelect">Browse File:</label>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="block w-1/3 p-2 border border-gray-300 rounded"
                            />
                        </div>

                        {files.map((file, index) => (
                            <div key={index}>
                                <label>
                                    Description for {file.name}:
                                    <input
                                        type="text"
                                        value={descriptions[index]}
                                        onChange={(event) => handleDescriptionChange(index, event)}
                                        required
                                        className="block w-1/3 p-2 border border-gray-300 rounded"
                                    />
                                </label>
                            </div>
                         ))}

                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded" 
                            type="submit"
                            disabled={uploading}>
                            {uploading ? 'Uploading...' : 'Upload'}
                        </button>

                        <button type="button" onClick={handleCloseAddFileModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </form>
               )}
            </div>      
        </Modal>

        <Modal isOpen={addFileModalIsOpen} onRequestClose={handleCloseAddFileModal} contentLabel="File Upload Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4 text-center">File Upload</h1>

                {candiateAssignments.length > 0 && (
                    <form onSubmit={handleUpload}>

                        <div className="mb-4">
                          <label htmlFor="assignmentSelect">Select Assignment:</label>
                          <select   id="assignmentSelect" onChange={handleAssignmentChange}
                                    className="block w-1/3 p-2 border border-gray-300 rounded"
                          >
                            <option value="">--Select Assignment--</option>
                            {candiateAssignments.map(assignment => (
                                <option key={assignment.assignment_id} value={assignment.assignment_id}>
                                    {assignment.assignment_name}
                                </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="assignmentSelect">Browse File:</label>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="block w-1/3 p-2 border border-gray-300 rounded"
                            />
                        </div>

                        {files.map((file, index) => (
                            <div key={index}>
                                <label>
                                    Description for {file.name}:
                                    <input
                                        type="text"
                                        value={descriptions[index]}
                                        onChange={(event) => handleDescriptionChange(index, event)}
                                        required
                                        className="block w-1/3 p-2 border border-gray-300 rounded"
                                    />
                                </label>
                            </div>
                         ))}

                    <div className="flex justify-start space-x-2 py-4">
                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded" 
                            type="submit"
                            disabled={uploading}>
                            {uploading ? 'Uploading...' : 'Upload'}
                        </button>

                        <button type="button" onClick={handleCloseAddFileModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </form>
               )}
            </div>      
        </Modal>

        <Modal isOpen={getFileModalIsOpen} onRequestClose={handleCloseGetFileModal} contentLabel="File Upload Modal" >
            <div className="bg-white p-5">
                <h1 className="text-xl font-bold mb-4 text-center">My Files</h1>
                    <div className="flex items-center gap-4 mb-4">
                           <table className="w-full">
                            <thead>
                                  <tr>
                                     <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">No</th>
                                    <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Description</th>
                                     {/*<th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Path</th>*/} 
                                     <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Operations</th>
                                   </tr>
                            </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {existingFiles.length === 0 ? (
                                <tr><td colSpan="10" className="text-center py-4">No uploaded assignment found.</td></tr>):(existingFiles.map((asset, index) => (
                                  <tr key={asset.asset_id} className="odd:bg-slate-100 even:bg-white">
                                    <td className="py-3 p-2">{index+1}</td>
                                    <td className="py-3">{asset.asset_description}</td>
                                    {/*<td className="py-3">{asset.asset_path}</td>*/}
                                    <td className="py-3">
                                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded" onClick={() => handleReplaceFile(asset.asset_id)}>Change</button>
                                        <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded ml-4" onClick={() => handleDownload(asset.asset_path)}>Download</button>
                                     </td>
                                  </tr>
                                 ))
                              )}
                           </tbody>
                    </table>
                </div>

                <div>
                        {selectedAssetId && (
                        <>
                        <div>
                            <h1 className="text-xl font-bold mb-4 text-left">New File Upload</h1>
                            <label htmlFor="newSelect">Browse File:</label>
                            <input
                                type="file"
                                onChange={handleFileChanger}
                                className="block w-1/3 p-2 border border-gray-300 rounded" />
                            <label htmlFor="newDescription">Provide Description:</label>
                            <input
                                type="text"
                                required
                                className="block w-1/3 p-2 border border-gray-300 rounded"
                                onChange={handleDescriptionChanger} />
                        </div>
                        <div className="flex justify-start space-x-2 py-4">
                                <button onClick={handleFileReplace}
                                    className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded"
                                >
                                    Change File
                                </button>
                        </div>
                        </>
                        )}
                        
                </div>
            </div>
            <div>
                <button type="button" onClick={handleCloseGetFileModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                    Cancel
                </button>
            </div>
    </Modal>
    </div>
 )
}
}
export default TeacherClasses