import React, { useState, useEffect, useContext} from 'react';
import Modal from 'react-modal';
import { AuthContext } from "../../../contexts/auth";

function AdminAttendance() {
  const {user, token } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] =useState(true); //set loading status to true
  const [attendance, setAttendance] = useState([]); //capture responded attendance data
  const [filteredAttendance, setFilteredAttendance] = useState([]); //holds filtered response based on different criteria
  const [courses, setCourses] = useState([]); //holds unique courses to dynamically populates filtering criteria for attendance
  const [gradeLevels, setGradeLevels] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGradeLevel, setSelectedGradeLevel] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentUniqueCourses, setStudentUniqueCourses] = useState([]);
  const [selectedCourseAttendance, setSelectedCourseAttendance] = useState(null);
  const [totals, setTotals] = useState({totalPresent: 0, 
                                          totalAbsent: 0, 
                                          totalExcuse: 0, 
                                          totalLate: 0, 
                                          presentPercentage: 0, 
                                          absentPercentage: 0, 
                                          excusePercentage: 0, 
                                          latePercentage: 0});
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/attendances`, {
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
              });
        const respondedData = await response.json();
        setAttendance(respondedData);

	      const uniqueCourses = Array.from(new Set(respondedData.map((course) => course.course_name)));
    	  setCourses(uniqueCourses);

    	  const uniqueGradeLevels = Array.from(new Set(respondedData.map((grade) => grade.student_grade_level)));
    	  setGradeLevels(uniqueGradeLevels);

        const uniqueStudents = Array.from(new Set(respondedData.map((student) => student.student_id)));
        setStudents(uniqueStudents);
      } 
     catch (error) {
        console.error('Error:', error);
      }
     finally{
	      setLoading(false);
	    }
    };
    fetchAttendance();
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [filteredAttendance]);

 useEffect(() => {
    let filtered = attendance;
    if (selectedCourse) {
      	    filtered = filtered.filter((course) => course.course_name === selectedCourse);
        }
    if (selectedGradeLevel) {
      	     filtered = filtered.filter((grade) => grade.student_grade_level === Number(selectedGradeLevel));
         }
    setFilteredAttendance(filtered);
  });

  /* attendance status for groups those satisfy selection criteria */
  const calculateTotals = () => {
    const totalPresent = filteredAttendance.filter((attendance) => attendance.status === 'present').length;
    const totalAbsent = filteredAttendance.filter((attendance) => attendance.status === 'absent').length;
    const totalExcuse = filteredAttendance.filter((attendance) => attendance.status === 'excused').length;
    const totalLate = filteredAttendance.filter((attendance) => attendance.status === 'late').length;

    const totalRecords = filteredAttendance.length;
    const presentPercentage = totalPresent > 0 ? (((totalPresent/totalRecords) * 100).toFixed(2)) : 0;
    const absentPercentage = totalAbsent > 0 ? (((totalAbsent / totalRecords) * 100).toFixed(2)): 0;
    const excusePercentage = totalExcuse > 0 ? (((totalExcuse / totalRecords) * 100).toFixed(2)) :0;
    const latePercentage = totalLate >0 ? ((totalLate / totalRecords) * 100).toFixed(2) : 0;
    setTotals({ totalPresent, totalAbsent, totalExcuse, totalLate, presentPercentage, absentPercentage, 
      excusePercentage, latePercentage});
    };

  /* attendance status for individuals those satisfy selection criteria */
  const calculateTotalsForStudents = () => {
    const studentTotals = {};

    filteredAttendance.forEach((record) => {
      const { student_id, status, student_first_name, student_middle_name, student_last_name } = record;
      if (!studentTotals[student_id]) {
        studentTotals[student_id] = {
          studentId: student_id,
          firstName: student_first_name,
          middleName: student_middle_name,
          lastName: student_last_name,
          totalPresent: 0,
          totalAbsent: 0,
          totalExcuse: 0,
          totalLate: 0,
        };
      }

      switch (status) {
        case 'present':
          studentTotals[student_id].totalPresent++;
          break;
        case 'absent':
          studentTotals[student_id].totalAbsent++;
          break;
        case 'excused':
          studentTotals[student_id].totalExcuse++;
          break;
        case 'late':
          studentTotals[student_id].totalLate++;
          break;
        default:
          break;
      }
    });

    return Object.values(studentTotals);
  };

  const uniqueStudentTotals = calculateTotalsForStudents();

  /* To show students detail attendance record per courses*/
  const showStudentDetails = (studentId) => {
    setModalIsOpen(true);
    const student = attendance.find((record) => record.student_id === studentId);
    setSelectedStudent(student);
    
     const studentUniqueCourses = [...new Set(attendance.filter(record => record.student_id === studentId).map(record => record.course_name))];
     setStudentUniqueCourses(studentUniqueCourses);
    }

    const countStudentStatusInCourse = (studentId, courseName) => {
      const studentRecords = attendance.filter(record => record.student_id === studentId && record.course_name === courseName);
      
      const courseTotalPresent = studentRecords.filter(record => record.status === 'present').length;
      const courseTotalAbsent = studentRecords.filter(record => record.status === 'absent').length;
      const courseTotalExcuse = studentRecords.filter(record => record.status === 'excused').length;
      const courseTotalLate = studentRecords.filter(record => record.status === 'late').length;

      const courseTotal = studentRecords.length;
      const coursePresentPercentage = courseTotalPresent >0 ? (((courseTotalPresent/courseTotal)*100).toFixed(2)):0;
      const courseAbsentPercentage = courseTotalAbsent >0 ? (((courseTotalAbsent/courseTotal)*100).toFixed(2)):0;
      const courseExcusePercentage = courseTotalExcuse >0 ? (((courseTotalExcuse/courseTotal)*100).toFixed(2)):0;
      const courseLatePercentage = courseTotalLate >0 ? (((courseTotalLate/courseTotal)*100).toFixed(2)):0;
  
      return { courseTotalPresent, courseTotalAbsent, courseTotalExcuse, courseTotalLate,  
                coursePresentPercentage, courseAbsentPercentage, courseExcusePercentage, courseLatePercentage};
    };
  
   /* Show detailed attendance record for a specific course */
    const showCourseDetails = (selectedStudent, course) => {
        const courseAttendance = attendance.filter(record => record.student_id === selectedStudent && record.course_name === course);
        setSelectedCourseAttendance(courseAttendance); 
     }

  /* code to change attendance status - to be addeded later*/
    const handleStatusChange = async(recordId, newStatus) => {
       //alert(newStatus);

       try {
             const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/attendances/${recordId}`, {
                  method:'PATCH',
                  headers: {
                      'Authorization':`Bearer ${token}`,
                      'Content-Type':'application/json'
                    },
                  body: JSON.stringify({status: newStatus }),
              });
              if (!response.ok) {
                  throw new Error('Failed to update attendance status');
                }
              else {
                alert("Attendance status changed successfully");
              }
      }
    catch (error) {
        console.error('Error updating attendance status:', error);
      }
  };

  const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedStudent(null);
        setSelectedCourseAttendance(null);
    };

    //keep users informed
  if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center">
                <svg
                    className="animate-spin h-10 w-10 text-blue-500 mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                </svg>
                <p className="text-lg font-semibold text-gray-600">
                    Loading list of attendance, please wait...
                </p>
            </div>
        </div>
    );
}

  return (
     <div className="p-5">
	      <h1 className="text-4xl font-semibold p-5">Attendance Summary</h1>
            <div className="flex items-center gap-4 mb-4">
                <div className="w-1/4 pr-4">
                    <select id="course" 
                        name="course" 
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedCourse}
                        onChange ={(e) => setSelectedCourse(e.target.value)}>
                      <option value="">All Courses</option>
                        {courses.map((course, index) => (
                            <option key={index} value={course}>{course}</option>
                        ))}
                    </select>
                  </div>

                <div className="w-1/4 pr-4">
                    <select id="gradeSelect" 
                        name="grade" 
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedGradeLevel}
                        onChange={(e) => setSelectedGradeLevel(Number(e.target.value))}>
                        <option value="">Select Grade</option>
                        {gradeLevels.map((grade) => (
                            <option key={grade} value={grade}>Grade {grade}</option>
                        ))}
                    </select>
                </div>
	          </div>

           <div className="mt-8">
                <div className="overflow-x-auto h-[65vh]">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-slate-200">
                            <tr>
				                      <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Student Name</th>
            			            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Present</th>
            			            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Late</th>
                              <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Excused</th>
                              <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Total Absent</th>
	    			                  <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">More</th>
          		              </tr>
        	                </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
          		              
                            {uniqueStudentTotals.map((student) => (
                                <tr key={student.firstName + student.lastName}>
                                  <td className="px-6 py-4">{student.firstName} {student.middleName} {student.lastName}</td>
                                  <td className="px-6 py-4">{student.totalPresent}({((student.totalPresent/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                                  <td className="px-6 py-4">{student.totalLate}({((student.totalLate/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                                  <td className="px-6 py-4">{student.totalExcuse}({((student.totalExcuse/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                                  <td className="px-6 py-4">{student.totalAbsent}({((student.totalAbsent/(student.totalPresent + student.totalLate + student.totalExcuse + student.totalAbsent))*100).toFixed(2)}%)</td>
                                  <td className="px-6 py-4">
                                      <button onClick={() => showStudentDetails(student.studentId)}>View Detail</button>
                                  </td>
                                </tr>
                              ))}

          			              <tr>
            			                <td className="px-6 py-4">Total</td>
            			                <td className="px-6 py-4">{totals.totalPresent}({totals.presentPercentage}%)</td>
            			                <td className="px-6 py-4">{totals.totalLate}({totals.latePercentage}%)</td>
            			                <td className="px-6 py-4">{totals.totalExcuse}({totals.excusePercentage}%)</td>
            			                <td className="px-6 py-4">{totals.totalAbsent}({totals.absentPercentage}%)</td>
           			                </tr>
          	              </tbody>
                      </table>
                  </div>
              </div>

         {selectedStudent && (
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Student Attendance Details">
                <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
                    <h1 className="text-xl font-bold mb-4">Student Name: {selectedStudent.student_first_name} {selectedStudent.student_middle_name} {selectedStudent.student_last_name}</h1>
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-slate-500">
                            <tr>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Course</th>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Present</th>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Late</th>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Excused</th>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Absent</th>
                                <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Action</th>
                            </tr>
                          </thead>
                          
                          <tbody className="bg-white divide-y divide-gray-200">

                              {studentUniqueCourses.map(courseName => {
                                  const { courseTotalPresent, courseTotalAbsent, courseTotalExcuse, courseTotalLate, 
                                            coursePresentPercentage, courseAbsentPercentage, 
                                            courseLatePercentage, courseExcusePercentage } = countStudentStatusInCourse(selectedStudent.student_id, courseName);
                                  return (
                                      <tr key={courseName}>
                                          <td className="px-6 py-4">{courseName}</td>
                                          <td className="px-6 py-4">{courseTotalPresent}({coursePresentPercentage}%)</td>
                                          <td className="px-6 py-4">{courseTotalLate}({courseLatePercentage}%)</td>
                                          <td className="px-6 py-4">{courseTotalExcuse}({courseExcusePercentage}%)</td>
                                          <td className="px-6 py-4">{courseTotalAbsent}({courseAbsentPercentage}%)</td>
                                          <td className="px-6 py-4">
                                              <button onClick={() => showCourseDetails(selectedStudent.student_id, courseName)}>View Detail</button>
                                          </td>
                                        </tr>
                                  )})}
                	            </tbody>
                        </table>
                  
                        {selectedCourseAttendance && (
                          <div>
            	                <h2 className="text-xl font-bold mb-4">Attendance Details of {selectedStudent.student_first_name} {selectedStudent.student_middle_name} on {selectedCourseAttendance[0].course_name} Course</h2>
                              <table className="min-w-full divide-y divide-gray-200 border border-gray-300">

                                  <thead className="bg-slate-500">
                                      <tr>
                            	            <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Date</th>
                            	            <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Period</th>
                            	            <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Current Status</th>
			    	                              <th className="px-6 py-3 text-left text-lg text-white font-medium tracking-wider">Change Status To</th>
                                        </tr>
                    	            </thead>
                    	  
                                  <tbody className="bg-white divide-y divide-gray-200">
                        	              {selectedCourseAttendance.map((attendanceRecord) => (
                            	              <tr key={attendanceRecord.attendance_id}>
                                	              <td className="px-6 py-2">{attendanceRecord.date}</td>
                                	              <td className="px-6 py-2">{attendanceRecord.period}</td>
					                                      <td className="px-6 py-2">{(attendanceRecord.status).charAt(0).toUpperCase() + (attendanceRecord.status).slice(1)}</td>
                                	              <td className="py-2 pl-0 -ml-4 flex space-x-4"> 
                                                  <button className="bg-cyan-800 hover:bg-cyan-950 text-white py-1 px-2 rounded" onClick={() => handleStatusChange(attendanceRecord.attendance_id, 'present')}>Present</button>
                                                  <button className="bg-yellow-700 hover:bg-yellow-500 text-white py-1 px-2 rounded" onClick={() => handleStatusChange(attendanceRecord.attendance_id, 'late')}>Late</button>
                                                  <button className="bg-orange-700 hover:bg-orange-500 text-white py-1 px-2 rounded" onClick={() => handleStatusChange(attendanceRecord.attendance_id, 'excused')}>Excuse</button>
                                                  <button className="bg-red-700 hover:bg-red-500 text-white py-1 px-2 rounded" onClick={() => handleStatusChange(attendanceRecord.attendance_id, 'absent')}>Absent</button>
                                                </td>
                            	                </tr>
                        	                ))}
                    	              </tbody>
                                </table>
                            </div>
                          )}
                  <button type="button" onClick={handleCloseModal} className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6">Cancel</button>
                </div>
         </Modal>
     )}    
   </div>
  )
}
export default AdminAttendance;