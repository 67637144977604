import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { AuthContext } from '../../contexts/auth';

// Toast component
function Toast({ message, visible, onClose }) {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [visible, onClose]);

    return visible ? (
        <div className="fixed top-16 right-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
            {message}
        </div>
    ) : null;
}

function EmployeeRoleManagement() {
    const { user } = useContext(AuthContext);  // Get user from context
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [editingEmployeeId, setEditingEmployeeId] = useState(null);
    const [toastVisible, setToastVisible] = useState(false); 
    const [toastMessage, setToastMessage] = useState(''); 
    const [newEmployee, setNewEmployee] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        mobile: '',
        password: '',
        title: '',
        position: '',
        department: '',
        gender: '',
    });
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile: '',
        title: '',
        position: '',
        department: '',
        gender: '',
        password: ''
    });


    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/employees`, {
                headers: { Authorization: `Bearer ${user.token}` }
            });
            setEmployees(response.data);
            setFilteredEmployees(response.data);
            console.log("🚀 ~ fetchEmployees ~ response:", response)
        } catch (error) {
            setError('Failed to fetch Employees');
            console.error('Error fetching employees:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleAddEmployee = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const body = {
                user: {
                    first_name: newEmployee.firstName,
                    middle_name: newEmployee.middleName,
                    last_name: newEmployee.lastName,
                    mobile: newEmployee.mobile,
                    password: newEmployee.password,
                },
                employee: {
                    title: newEmployee.title,
                    position: newEmployee.position,
                    department: newEmployee.department,
                    gender: newEmployee.gender,
                }
            };
            await axios.post(`${process.env.REACT_APP_API_URL}/v1/employees`, body, {
                headers: { Authorization: `Bearer ${user.token}` }
            });
            fetchEmployees();  // Refresh the list after adding
            handleCloseModal();

              // Show success toast
              setToastMessage('Employee added successfully');
              setToastVisible(true);
        } catch (error) {
            setError('Failed to add the Employee');
        }finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setNewEmployee({
            firstName: '',
            middleName: '',
            lastName: '',
            mobile: '',
            password: '',
            title: '',
            position: '',
            department: '',
            gender: '',
        });
    };

    const handleFilter = () => {
        let filtered = employees;
        if (departmentFilter) {
            filtered = filtered.filter(employee => employee.employee_department === departmentFilter);
        }
        if (searchTerm) {
            filtered = filtered.filter(employee =>
                (`${employee.employee_first_name} ${employee.employee_last_name}`.toLowerCase()).includes(searchTerm.toLowerCase())
            );
        }
        setFilteredEmployees(filtered);
    };

  // Set the editing employee's ID and prefill the form with the employee's data
  const handleEditClick = (employee) => {
    setEditingEmployeeId(employee.employee_user_id); // Set the employee ID being edited
    setFormData({
        first_name: employee.employee_first_name,
        middle_name: employee.employee_middle_name,
        last_name: employee.employee_last_name,
        mobile: employee.employee_mobile,
        title: employee.employee_title,
        position: employee.employee_position,
        department: employee.employee_department,
        gender: employee.employee_gender,
        password: '' // Optionally, you can keep this empty until the user sets a new password
    });
    setEditModalIsOpen(true); // Open the edit modal
};
    const handleEditModalClose = () => {
        setEditModalIsOpen(false);
    };

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle saving edited employee
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!editingEmployeeId) return;

        setLoading(true);
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/v1/employees/${editingEmployeeId}`, {
                user: {
                    first_name: formData.first_name,
                    middle_name: formData.middle_name,
                    last_name: formData.last_name,
                    mobile: formData.mobile,
                    password: formData.password
                },
                employee: {
                    title: formData.title,
                    position: formData.position,
                    department: formData.department,
                    gender: formData.gender
                }
            }, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            setEditModalIsOpen(false);
            fetchEmployees(); // Refresh employees after edit

               // Show success toast
               setToastMessage('Employee updated successfully');
               setToastVisible(true);

        } catch (error) {
            setError('Failed to update the employee');
        } finally {
            setLoading(false);
        }
    };

    const handleCloseEditModal = () => {
        setEditModalIsOpen(false);
    };

    useEffect(() => {
        handleFilter();
    }, [departmentFilter, searchTerm]);

    const handleAddEmployeeClick = () => {
        setModalIsOpen(true);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading Employee details, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 text-center">{error}</div>;
    }

    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">Employee Role Management</h1>
            <div className="flex items-center gap-4 mb-4">
                {/* Filter by department */}
                <div className="w-1/4 pr-4">
                    <select
                        value={departmentFilter}
                        onChange={(e) => setDepartmentFilter(e.target.value)}
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                    >
                        <option value="">Select Department</option>
                        <option value="academics">Academics</option>
                        <option value="administration">Administration</option>
                        <option value="athletics">Athletics</option>
                        <option value="counseling">Counseling</option>
                        <option value="health_services">Health Services</option>
                        <option value="library">Library</option>
                        <option value="maintenance">Maintenance</option>
                        <option value="office">Office</option>
                    </select>
                </div>

                {/* Search by employee name */}
                <div className="w-1/4">
                    <input
                        type="text"
                        placeholder="Search by First name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                    />
                </div>

                {/* Add employee button */}
                <div className="flex justify-end w-2/3">
                    <button onClick={handleAddEmployeeClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                        Add New Employee
                    </button>
                </div>
            </div>

            {/* List of employees */}
            <div className="mt-8 flex flex-col max-h-[54vh] overflow-scroll">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-slate-200 sticky top-0 h-full">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">First Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Middle Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Last Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Gender</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Mobile</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Title</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Position</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Department</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Action</th>
                        </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                       
                        {filteredEmployees.map((employee) => (
                            <tr key={employee.employee_user_id}>
                                <td className="px-6 py-4">{employee.employee_first_name}</td>
                                <td className="px-6 py-4">{employee.employee_middle_name}</td>
                                <td className="px-6 py-4">{employee.employee_last_name}</td>
                                <td className="px-6 py-4">{employee.employee_gender}</td>
                                <td className="px-6 py-4">{employee.employee_mobile}</td>
                                <td className="px-6 py-4">{employee.employee_title}</td>
                                <td className="px-6 py-4">{employee.employee_position}</td>
                                <td className="px-6 py-4">{employee.employee_department}</td>
                                <td className="px-6 py-4">
                                        <button
                                            onClick={() => handleEditClick(employee)}
                                            className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-1 px-2 rounded"
                                        >
                                            Edit
                                        </button>
                                    </td>
                            </tr>
                        ))}
                      
                    </tbody>
                </table>
            </div>

            
            {/* Edit Modal */}
            <Modal
    isOpen={editModalIsOpen}
    onRequestClose={handleCloseEditModal}
    contentLabel="Edit Employee Modal"
    className="flex justify-center items-center" // Center modal
    overlayClassName="fixed inset-0 bg-black bg-opacity-50" // Overlay styling
>
    <div className="bg-slate-100 p-6 shadow-md rounded-md w-full max-w-3xl">
        <form onSubmit={handleEditSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <h2 className="text-xl font-bold mb-4 col-span-2">Edit Employee</h2>

            <div className="p-3">
                <label>First Name</label>
                <input
                    type="text"
                    value={formData.first_name}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                />
            </div>
            <div className="p-3">
                <label>Middle Name</label>
                <input
                    type="text"
                    value={formData.middle_name}
                    onChange={(e) => setFormData({ ...formData, middle_name: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                />
            </div>
            <div className="p-3">
                <label>Last Name</label>
                <input
                    type="text"
                    value={formData.last_name}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                />
            </div>
            <div className="p-3">
                <label>Gender</label>
                <select
                    value={formData.gender}
                    onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
            </div>
            <div className="p-3">
                <label>Title</label>
                <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                />
            </div>
            <div className="p-3">
                <label>Position</label>
                <select
                    value={formData.position}
                    onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                >
                    <option value="">Select Position</option>
                    <option value="custodian">Custodian</option>
                    <option value="administrator">Administrator</option>
                    <option value="nurse">Nurse</option>
                    <option value="coach">Coach</option>
                    <option value="teacher">Teacher</option>
                    <option value="librarian">Librarian</option>
                    <option value="counselor">Counselor</option>
                    <option value="secretary">Secretary</option>
                </select>
            </div>
            <div className="p-3">
                <label>Department</label>
                <select
                    value={formData.department}
                    onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                >
                    <option value="">Select Department</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="administration">Administration</option>
                    <option value="health_services">Health Services</option>
                    <option value="athletics">Athletics</option>
                    <option value="academics">Academics</option>
                    <option value="library">Library</option>
                    <option value="counseling">Counseling</option>
                    <option value="office">Office</option>
                </select>
            </div>
            <div className="p-3">
                <label>Password</label>
                <input
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    className="border border-gray-300 p-2 rounded block w-full"
                />
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="col-span-2 flex justify-end mt-4">
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save Changes
                </button>
                <button onClick={handleCloseEditModal} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2">
                    Cancel
                </button>
            </div>
        </form>
        {error && <div className="text-red-500 mt-4">{error}</div>}
    </div>
</Modal>


            {/* Add Employee Modal */}
            <Modal 
    isOpen={modalIsOpen} 
    onRequestClose={handleCloseModal} 
    contentLabel="Add Employee Modal" 
    className="flex justify-center items-center" // Center modal
    overlayClassName="fixed inset-0 bg-black bg-opacity-50" // Overlay styling
>
    <div className="bg-slate-100 p-8 shadow-md rounded-md max-w-lg mx-auto">
        <form onSubmit={handleAddEmployee}>
            <h2 className="text-xl font-bold mb-4 text-center">Add New Employee</h2>
            <div className="grid grid-cols-2 gap-4">
                <div className="p-3">
                    <label>First Name</label>
                    <input
                        type="text"
                        value={newEmployee.firstName}
                        onChange={(e) => setNewEmployee({ ...newEmployee, firstName: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Middle Name</label>
                    <input
                        type="text"
                        value={newEmployee.middleName}
                        onChange={(e) => setNewEmployee({ ...newEmployee, middleName: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Last Name</label>
                    <input
                        type="text"
                        value={newEmployee.lastName}
                        onChange={(e) => setNewEmployee({ ...newEmployee, lastName: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Mobile</label>
                    <input
                        type="text"
                        value={newEmployee.mobile}
                        onChange={(e) => setNewEmployee({ ...newEmployee, mobile: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Password</label>
                    <input
                        type="password"
                        value={newEmployee.password}
                        onChange={(e) => setNewEmployee({ ...newEmployee, password: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Gender</label>
                    <select
                        value={newEmployee.gender}
                        onChange={(e) => setNewEmployee({ ...newEmployee, gender: e.target.value })}
                        className="block w-full border border-gray-300 rounded p-2"
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="p-3">
                    <label>Title</label>
                    <input
                        type="text"
                        value={newEmployee.title}
                        onChange={(e) => setNewEmployee({ ...newEmployee, title: e.target.value })}
                        className="border border-gray-300 p-2 rounded block w-full"
                    />
                </div>
                <div className="p-3">
                    <label>Position</label>
                    <select
                        value={newEmployee.position}
                        onChange={(e) => setNewEmployee({ ...newEmployee, position: e.target.value })}
                        className="block w-full border border-gray-300 rounded p-2"
                    >
                        <option value="">Select Position</option>
                        <option value="custodian">Custodian</option>
                        <option value="administrator">Administrator</option>
                        <option value="nurse">Nurse</option>
                        <option value="coach">Coach</option>
                        <option value="teacher">Teacher</option>
                        <option value="librarian">Librarian</option>
                        <option value="counselor">Counselor</option>
                        <option value="secretary">Secretary</option>
                    </select>
                </div>
                <div className="p-3">
                    <label>Department</label>
                    <select
                        value={newEmployee.department}
                        onChange={(e) => setNewEmployee({ ...newEmployee, department: e.target.value })}
                        className="block w-full border border-gray-300 rounded p-2"
                    >
                        <option value="">Select Department</option>
                        <option value="maintenance">Maintenance</option>
                        <option value="administration">Administration</option>
                        <option value="health_services">Health Services</option>
                        <option value="athletics">Athletics</option>
                        <option value="academics">Academics</option>
                        <option value="library">Library</option>
                        <option value="counseling">Counseling</option>
                        <option value="office">Office</option>
                    </select>
                </div>
            </div>

            <div className="flex justify-end mt-4">
                <button type="submit" className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Add Employee
                </button>
                <button onClick={handleCloseModal} className="ml-2 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Cancel
                </button>
            </div>
        </form>
    </div>
</Modal>

             {/* Toast Notification */}
             <Toast message={toastMessage} visible={toastVisible} onClose={() => setToastVisible(false)} />
        </div>
    );
}

export default EmployeeRoleManagement;
