import { VscThreeBars } from "react-icons/vsc";

const HttpStatusForbidden = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center h-screen">
                <VscThreeBars className="w-32 text-yellow-500 animate-pulse" />
                <div className="text-center">
                    <p className="font-bold">Whoops! You seem to have reached a restricted area.</p>
                    <span>Let's get you back to your <a href="/" className="text-blue-600 font-bold">dashboard</a>.</span>
                </div>
            </div>
        </>

    )
}

export default HttpStatusForbidden