import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import axios from 'axios';
import Modal from 'react-modal';

// Helper function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

function AdminProfile() {
    const {user, token} = useContext(AuthContext);
    const [profile, setProfile] = useState(null);

    // Fetch profile data
    useEffect(() => {
        const fetchProfile = async () => {
            if (user && token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type':'application/json'
                            },
                        },
                    );
                    const data = await response.json();
                    setProfile(data);
                } 
                catch (error) {
                    console.error("Failed to fetch profile data. Please try again later.");
                } 
            }
        };

        fetchProfile();
    }, [user, token]);

    //School logo upload 
    const [addLogoModalIsOpen, setAddLogoModalIsOpen] = useState(false);
    const [logo, setLogo] = useState('');

    const handleOpenAddLogoModal = () => {
        setAddLogoModalIsOpen(true);
    }

    const chosenLogo = (event) => {
        setLogo(event.target.files[0]);
      };

    const handleAddLogo =async ()=>{
        if (!logo) {
            alert('Please select logo');
            return;
          }
        
          const formDataLater = new FormData();
          formDataLater.append('file', logo);

          try {
            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/assets/logos`, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
             },
            body: formDataLater,
          });
    
          if (logoResponse.ok) {
                alert('Logo changed successfully');
                setLogo(null);
                setAddLogoModalIsOpen(false);
          }
        }
        catch(error){
            console.error('Error changing logo: ', error);
         }
    }

    const handleCloseAddLogoModal = () => {
        setAddLogoModalIsOpen(false);
    }

    if (!profile) {
        return (
			<div className="flex items-center justify-center min-h-screen">
                <p className="text-lg font-semibold text-gray-600">
                    Loading Profile...
                </p>
			</div>
		);
    }

    return (
        
        <>
        <div className="p-5">
            <div className="flex justify-end p-5 space-x-4">
                <button onClick={handleOpenAddLogoModal} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Add School Logo
                </button>
            </div>
        </div>
        
        <div className="p-5">
                <div className="p-5 bg-slate-100 text-center mt-8 p-4 shadow-md rounded-md">
                    <h2 className="text-2xl font-semibold mt-4">{profile.first_name} {profile.middle_name}</h2>
                    <p className="text-md text-gray-600">Position: {capitalizeFirstLetter(profile.position)}</p>
                </div>

                <div className="flex flex-col md:flex-row gap-4 mt-8">
                    <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1">
                        <h2 className="text-xl mt-4"><strong className="font-semibold">First Name:</strong> {profile.first_name}</h2>
                        <h2 className="text-xl mt-4"><strong className="font-semibold">Middle Name:</strong> {profile.middle_name}</h2>
                        <h2 className="text-xl mt-4"><strong className="font-semibold">Last Name:</strong> {profile.last_name}</h2>
                        <p className="text-xl mt-4"><strong className="font-semibold">Gender:</strong> {profile.gender}</p>
                        <p className="text-xl mt-4"><strong className="font-semibold">Phone Number:</strong> {profile.mobile}</p>
                    </div>

                    <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1">
                        <p className="text-xl mt-4"><strong className="font-semibold">Title:</strong> {capitalizeFirstLetter(profile.title)}</p>
                        <p className="text-xl mt-4"><strong className="font-semibold">Position:</strong> {capitalizeFirstLetter(profile.position)}</p>
                        <p className="text-xl mt-4"><strong className="font-semibold">Department:</strong> {capitalizeFirstLetter(profile.department)}</p>
                    </div>
                </div>
            </div>
            
            <Modal isOpen={addLogoModalIsOpen} onRequestClose={handleCloseAddLogoModal} contentLabel="Assignment Modal">
                <div className="bg-white p-5">
                        <div>
                            <h1 className="text-xl font-bold mb-4 text-left">Add School Logo</h1>
                            <label htmlFor="newSelect">Browse Logo:</label>
                            <input
                                type="file"
                                id="logo"
                                name="logo"
                                onChange={chosenLogo}
                                className="block w-1/3 p-2 border border-gray-300 rounded" />
                        </div>

                        <div className="flex justify-start space-x-2 py-4">
                                <button onClick={handleAddLogo}
                                    className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded"
                                >
                                    Add Logo
                                </button>

                                <button type="button" onClick={handleCloseAddLogoModal} className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded">
                                    Cancel
                                </button>
                        </div>
                    </div>
            </Modal>
        </>
    );
}

export default AdminProfile;