import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import axios from 'axios';

function TeacherProfile() {
    const {user, token} = useContext(AuthContext);
    const [profile, setProfile] = useState(null);

    // Capitalize first letter of a string
    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Fetch profile data
    useEffect(() => {
        const fetchProfile = async () => {
            if (user && token) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    setProfile(response.data);
                } catch (error) {
                    console.error("Failed to fetch profile data. Please try again later.");
                } 
            }
        };

        fetchProfile();
    }, [user, token]);


    if (!profile) {
        return (
			<div className="flex items-center justify-center min-h-screen">
                <p className="text-lg font-semibold text-gray-600">
                    Loading Profile...
                </p>
			</div>
		);
    }

    return (
        <div className="p-5">
            <div className="p-5 bg-slate-100 text-center mt-8 p-4 shadow-md rounded-md"> 
                <h2 className="text-2xl font-semibold mt-4"> {capitalizeFirstLetter(profile.first_name)} {capitalizeFirstLetter(profile.middle_name)}</h2>
                <p className="text-md text-gray-600">Position: {capitalizeFirstLetter(profile.position)}</p>
            </div>
            
            <div className="flex flex-col md:flex-row gap-4 mt-8"> 
                <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1"> 
                    <h2 className="text-xl mt-4"><strong className="font-semibold">Name:</strong> {capitalizeFirstLetter(profile.first_name)} {capitalizeFirstLetter(profile.middle_name)} {capitalizeFirstLetter(profile.last_name)}</h2>
                    <p className="text-xl mt-4"><strong className="font-semibold">Gender:</strong> {capitalizeFirstLetter(profile.gender)} </p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Phone Number:</strong> {profile.mobile}</p>
                </div>

                <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1"> 
                    <p className="text-xl mt-4"><strong className="font-semibold">Title:</strong> {capitalizeFirstLetter(profile.title)}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Position:</strong> {capitalizeFirstLetter(profile.position)}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Department:</strong> {capitalizeFirstLetter(profile.department)}</p>
                </div>
            </div>
        </div>
    );
}

export default TeacherProfile;
