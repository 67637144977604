import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/auth";

function StudentDashboard() {
	const { user, token } = useContext(AuthContext);
	const [todayClasses, setTodayClasses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [upcomingAssignments, setUpcomingAssignments] = useState([]);
	const [gradedAssignments, setGradedAssignments] = useState([]);
	const [todayAttendance, setTodayAttendance] = useState([]);

	// Get the current date (e.g., '2023-10-02')
	const getCurrentDate = () => {
		const today = new Date();
		return today.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
	};

	// to get the current day of the week
	const getCurrentDay = () => {
		const days = [
			"sunday",
			"monday",
			"tuesday",
			"wednesday",
			"thursday",
			"friday",
			"saturday",
		];
		const currentDayIndex = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)
		return days[currentDayIndex];
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const fetchTodayClasses = async () => {
			if (user && token) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/classes`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);
					const currentDay = getCurrentDay();
					const todayClasses = response.data.filter(
						(cls) => cls.day_of_week.toLowerCase() === currentDay,
					);
					setTodayClasses(todayClasses);
					// Fetch upcoming assignments
					const assignmentResponse = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/assignments`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);
					setUpcomingAssignments(assignmentResponse.data);

					// Fetch recent graded assignments
					const gradeResponse = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/grades`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);
					// Sort assignments by grade_updated_at and limit to 5 recent ones
					const recentGradedAssignments = gradeResponse.data
						.sort(
							(a, b) =>
								new Date(b.grade_updated_at) - new Date(a.grade_updated_at),
						)
						.slice(0, 5); // Fetch the 5 most recent ones
					setGradedAssignments(recentGradedAssignments);

					// Fetch today's attendance
					const attendanceResponse = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/attendances`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);
					const today = getCurrentDate();
					const attendanceData = attendanceResponse.data.filter(
						(att) => att.date === today,
					);
					setTodayAttendance(attendanceData);
				} catch (error) {
					setError("Failed to fetch today's classes. Please try again.");
				} finally {
					setIsLoading(false);
				}
			}
		};

		fetchTodayClasses();
	}, [user, token]);

	if (isLoading) {
		return (
			<div className="flex items-center justify-center min-h-screen">
				<div className="flex flex-col items-center">
					{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
					<svg
						className="animate-spin h-10 w-10 text-blue-500 mb-4"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						/>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
						/>
					</svg>
					<p className="text-lg font-semibold text-gray-600">
						Loading today's classes...
					</p>
				</div>
			</div>
		);
	}

	if (error) {
		return <p className="text-center text-red-500">{error}</p>;
	}

	// array of colors to make each card show different colors
	const colors = ["bg-sky-200", "bg-pink-200", "bg-blue-300", "bg-indigo-200", "bg-cyan-200", "bg-purple-300", "bg-green-200", "bg-red-200", "bg-yellow-200"];

	return (
		<div className="p-5">
			<h1 className="text-4xl font-bold mb-8 p-5">Dashboard</h1>

			{/* Top Cards - Show the student's schedule only for the day */}
			<div className="flex justify-around">
				{todayClasses.length > 0 ? (
					todayClasses.map((cls, index) => (
						<div
							key={cls.class_id}
							className={`w-1/3 p-2 text-center shadow-md mx-2 rounded-lg ${colors[index % colors.length]}`}
						>
							<p className="text-md font-semibold">{cls.course_name}</p>
							<p className="text-sm font-light">Period: {cls.period}</p>
							<p className="text-sm font-light">Room: {cls.room_number}</p>
						</div>
					))
				) : (
					<p className="text-center text-lg">No classes scheduled for today</p>
				)}
			</div>

			<div className="flex flex-wrap md:flex-nowrap justify-around gap-4">
				{/* Recent Graded Assignments */}
				<div className="w-full md:w-1/2 mt-8 bg-slate-100 p-4 shadow-md rounded-md">
					<h2 className="text-lg font-bold mb-4">Recent Graded Assignments</h2>
					<table className="w-full">
						<thead>
							<tr className="text-left">
								<th>Assignment</th>
								<th>Subject</th>
								<th>Score</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{gradedAssignments.length > 0 ? (
								gradedAssignments.map((assignment, index) => (
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									<tr key={index} className="border-b border-gray-200">
										<td className="py-2">{assignment.assignment_name}</td>
										<td className="py-2">{assignment.course_name}</td>
										<td className="py-2">{assignment.grade_score}</td>
										<td className="py-2">{assignment.assignment_total}</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="4" className="py-2 text-center">
										No graded assignments found
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				{/* Today's Attendance */}
				<div className="w-full md:w-1/2 mt-8 bg-slate-100 p-4 shadow-md rounded-md">
					<h2 className="text-lg font-bold mb-4">Today's Attendance</h2>
					<table className="w-full">
						<thead>
							<tr className="text-left">
								<th>Course</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{todayAttendance.length > 0 ? (
								todayAttendance.map((att, index) => (
									<tr
										key={att.attendance_id}
										className="border-b border-gray-200"
									>
										<td className="py-2">{att.course_name}</td>
										<td className="py-2 capitalize">{att.status}</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="2" className="py-2 text-center">
										No attendance records for today
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{/* Up coming Assignment */}
			<div className="mt-8 bg-slate-100 p-4 shadow-md rounded-md">
				<h2 className="text-lg font-bold mb-4">Up Coming Assignments</h2>

				{/* Make the table container scrollable */}
				<div className="max-h-80 overflow-y-auto sticky top-0">
					<table className="w-full">
						<thead>
							<tr className="text-left bg-slate-100 sticky top-0 z-10">
								<th>Assignment Title</th>
								<th>Subject</th>
								<th>Due Date</th>
								<th>Point</th>
							</tr>
						</thead>
						<tbody>
							{upcomingAssignments.length > 0 ? (
								upcomingAssignments.map((assignment, index) => (
									<tr
										key={assignment.assignment_id}
										className="border-b border-gray-200"
									>
										<td className="py-2">{index + 1}. {assignment.assignment_name}</td>
										<td className="py-2">{assignment.course_name}</td>
										<td className="py-2">
											{new Date(
												assignment.assignment_due_date,
											).toLocaleDateString()}
										</td>
										<td className="py-2">{assignment.assignment_total} pts</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="5" className="py-2 text-center">
										No upcoming assignments
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default StudentDashboard;
