import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { AuthContext } from '../../../contexts/auth'; 

// Toast component
function Toast({ message, visible, onClose }) {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [visible, onClose]);

    return visible ? (
        <div className="fixed top-16 right-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
            {message}
        </div>
    ) : null;
}

function Guardian() {
    const { user } = useContext(AuthContext); 
    
    const navigate = useNavigate();
    const [guardians, setGuardians] = useState([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [addDependentModalIsOpen, setAddDependentModalIsOpen] = useState(false);
    const [editGuardianModalIsOpen, setEditGuardianModalIsOpen] = useState(false);
    const [editDependentModalIsOpen, setEditDependentModalIsOpen] = useState(false);
    const [filteredGuardians, setFilteredGuardians] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // For search input
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [grades, setGrades] = useState([]); // For dropdown of grades
    const [students, setStudents] = useState([]); // For dropdown of students filtered by grade
    const [selectedGrade, setSelectedGrade] = useState('');
    const [relationship, setRelationship] = useState('');
    const [selectedStudentId, setSelectedStudentId] = useState('');
    const [selectedGuardianId, setSelectedGuardianId] = useState(''); // New state to track selected guardian id
    const [selectedDependentId, setSelectedDependentId] = useState('');
   

    const [guardianFormData, setGuardianFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile: '',
        relationship: '',
        student_id: '',
        password: '',
    });

    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        password:'',
       mobile:'',
        gender: '',
        dateOfBirth: '',
        relationship: '',
        studentId: '', // For dependents
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [toastVisible, setToastVisible] = useState(false); 
    const [toastMessage, setToastMessage] = useState(''); 

       // Relationship enum types
       const relationshipOptions = [
        "father",
        "mother",
        "grandfather",
        "grandmother",
        "aunt",
        "uncle",
        "sister",
        "brother",
        "stepfather",
        "stepmother",
        "guardian",
        "relative"
    ];


      // Fetch guardians on component mount
      useEffect(() => {
    

        fetchGuardians();
    }, [user.user_id, user.token])
    const fetchGuardians = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/guardians`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            const data = response.data;
            // Group guardians by their ID and combine student names
            const guardianMap = {};
            data.forEach((guardian) => {
                const key = guardian.guardian_user_id;

                if (!guardianMap[key]) {
                    guardianMap[key] = {
                        ...guardian,
                        studentNames: []
                    };
                }  if (guardian.student_first_name && guardian.student_last_name) {
                    guardianMap[key].studentNames.push(`${guardian.student_first_name} ${guardian.student_last_name}`);
                }
            });

            const groupedGuardians = Object.values(guardianMap);
            setGuardians(groupedGuardians);
            setFilteredGuardians(groupedGuardians);
        } catch (error) {
            setError('Failed to fetch guardians');
            console.error('Failed to fetch guardians:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (dependentId) => {
        setSelectedDependentId(dependentId);
        setDeleteModalIsOpen(true);
      };

      const handleDeleteDependent = async () => {
        if (!selectedDependentId) return;
        setLoading(true);
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/guardians/${user.user_id}/dependents/${selectedDependentId}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
           // Show success toast
           setToastMessage('Dependent Deleted successfully');
           setToastVisible(true);
          fetchGuardians(); // Refresh guardians after deletion
        } catch (error) {
          console.error('Failed to delete dependent:', error);
        } finally {
          setLoading(false);
          setDeleteModalIsOpen(false); // Close the modal
        }
      };

  // Handle search by guardian name
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = guardians.filter((guardian) =>
        guardian.guardian_first_name.toLowerCase().includes(value) ||
        guardian.guardian_middle_name.toLowerCase().includes(value) ||
        guardian.guardian_last_name.toLowerCase().includes(value)
    );
    setFilteredGuardians(filtered);
};

 // Fetch unique student grades
 const fetchGrades = () => {
    const uniqueGrades = [...new Set(guardians.map(guardian => guardian.student_grade))];
    setGrades(uniqueGrades);
};


const fetchStudentsByGrade = async (grade) => {
    console.log("🚀 ~ fetchStudentsByGrade ~ grade:", grade);
    if (!grade) return; // Ensure grade is selected
    setError(null);
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/students`, {
            headers: {
                'Authorization': `Bearer ${user.token}`,
            }
        });
        
        const data = response.data;
        console.log("🚀 ~ fetchStudentsByGrade ~  data :", data);

        // Filter students based on the selected grade
        const filteredStudents = data.filter(student => student.student_grade === parseInt(grade));
        console.log("🚀 ~ Filtered Students:", filteredStudents);

        // Set filtered students to the state
        setStudents(filteredStudents);
    } catch (error) {
        setError('Failed to fetch students');
        console.error('Failed to fetch students:', error);
    } finally {
        setLoading(false);
    }
};

  // Function to handle edit button click for guardian info
  const handleEditGuardianClick = (guardian) => {
    setSelectedGuardianId(guardian.guardian_user_id); // Set the selected guardian ID
    setGuardianFormData({
        first_name: guardian.guardian_first_name,
        middle_name: guardian.guardian_middle_name,
        last_name: guardian.guardian_last_name,
        relationship: guardian.dependent_relationship,
        mobile:guardian.guardian_mobile,
        student_id: guardian.student_id,
    });
    setEditGuardianModalIsOpen(true);
};

// Function to handle form submission for editing guardian info
const handleSaveGuardianInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/v1/guardians/${selectedGuardianId}`, {
            user: {
                first_name: guardianFormData.first_name,
                middle_name: guardianFormData.middle_name,
                last_name: guardianFormData.last_name,
                mobile: guardianFormData.mobile,
                password: guardianFormData.password // Optional field
            }
        }, {
            headers: {
                'Authorization': `Bearer ${user.token}`,
            },
        });
         // Show success toast
         setToastMessage('Guardian information updated successfully');
         setToastVisible(true);
        setEditGuardianModalIsOpen(false); 
        setGuardianFormData({ first_name: '', middle_name: '', last_name: '', mobile: '', relationship: '', student_id: '',password:'' });// Close modal after saving

          // Fetch the updated list of guardians
          const updatedGuardians = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/guardians`, {
            headers: { 'Authorization': `Bearer ${user.token}` },
        });
        const data = updatedGuardians.data;
        // Group guardians by their ID and combine student names
        const guardianMap = {};
        data.forEach((guardian) => {
            const key = guardian.guardian_user_id;

            if (!guardianMap[key]) {
                guardianMap[key] = {
                    ...guardian,
                    studentNames: []
                };
            }  if (guardian.student_first_name && guardian.student_last_name) {
                guardianMap[key].studentNames.push(`${guardian.student_first_name} ${guardian.student_last_name}`);
            }
        });

        const groupedGuardians = Object.values(guardianMap);
        setGuardians(groupedGuardians);
        setFilteredGuardians(groupedGuardians);
    } catch (error) {
        setError('Failed to update Guardian info')
        console.error("Failed to update guardian info:", error);
        alert("Failed to update guardian info.");
    } finally {
        setLoading(false);
    }
};

// Function to handle edit button click for dependent
const handleEditDependentClick = (guardian) => {
    setSelectedGuardianId(guardian.guardian_user_id);
    setSelectedDependentId(guardian.dependent_id); 
    setRelationship(guardian.dependent_relationship);
    setSelectedStudentId(guardian.student_id);
    fetchGrades(); // Fetch grades when opening the modal
    setEditDependentModalIsOpen(true);
};

// Function to handle form submission for editing dependent
const handleSaveDependentInfo = async () => {
    if (!selectedStudentId || !relationship || !selectedGuardianId || !selectedDependentId) {
        alert("Please select a student and relationship.");
        return;
    }

    setLoading(true);
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/v1/guardians/${selectedGuardianId}/dependents/${selectedDependentId}`, {
            student_id: selectedStudentId,
            relationship: relationship
        }, {
            headers: {
                'Authorization': `Bearer ${user.token}`,
            },
        });
         // Show success toast
         setToastMessage('Dependent information updated successfully!');
         setToastVisible(true);
        setEditDependentModalIsOpen(false); // Close modal after saving
    } catch (error) {
        console.error("Failed to update dependent info:", error);
        alert("Failed to update dependent info.");
    } finally {
        setLoading(false);
    }
};


    const handleAddGuardianClick = () => {
        fetchGrades();
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        fetchGuardians();
        setModalIsOpen(false);
    };

    const handleAddDependentClick = (guardianId) => {
        setSelectedGuardianId(guardianId); // Set the selected guardian id here
        setAddDependentModalIsOpen(true);
        fetchGrades(); // Fetch grades when opening the modal
    };

    const handleCloseDependentModal = () => {
        setAddDependentModalIsOpen(false);
        setSelectedGrade('');
        setStudents([]); // Reset students when closing modal
        setSelectedStudentId('');
        setRelationship('');
        setSelectedGuardianId(''); // Reset selected guardian id
    };

    const handleAssignDependent = async () => {
        if (!selectedStudentId || !relationship || !selectedGuardianId) {
            setToastMessage('Please select a student, relationship, and guardian.')
            setToastVisible(true);
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/v1/guardians/${selectedGuardianId}/dependents`, {
                student_id: selectedStudentId,
                relationship: relationship
            }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            handleCloseDependentModal();
            fetchGuardians()
            setToastMessage('Dependent assigned successfully!');
            setToastVisible(true);
          
        } catch (error) {
            console.error("Failed to assign dependent:", error);
            alert("Failed to assign dependent.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        // Construct request body
        const requestBody = {
            user: {
                first_name: formData.firstName,
                middle_name: formData.middleName,
                last_name: formData.lastName,
                mobile: formData.mobile,
                password: formData.password,
            },
            dependents: formData.studentId ? [
                {
                    student_id: formData.studentId,
                    relationship: formData.relationship,
                }
            ] : [],
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/guardians`, requestBody, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            console.log('Guardian created:', response.data);
            handleCloseModal();
             // Show success toast
             setToastMessage('Guardian created successfully');
             setToastVisible(true);
        } catch (error) {
            setError('Failed to create guardian');
            console.error('Error creating guardian:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading Guardian details, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 text-center">{error}</div>;
    }

    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">Guardian Details</h1>
            <div className="flex items-center gap-4 mb-4">
                {/* Search by name */}
                <div className="w-1/4">
                    <input
                        type="text"
                        placeholder="Search guardian by name..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                    />
                </div>
                 {/* Add guardian button */}
                 <div className="flex justify-end w-2/3">
                    <button onClick={handleAddGuardianClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                        Add New Guardian
                    </button>
                </div>
            </div>
              {/* List of guardians */}
              <div className="mt-8">
                <div className="overflow-x-auto h-[65vh]">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-slate-200">
                            <tr>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> First Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Middle Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Last Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Mobile </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Relationship </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Student Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">  </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredGuardians.map((guardian) => (
                                     <tr key={guardian.guardian_user_id}>
                                     <td className="px-6 py-4">{guardian.guardian_first_name}</td>
                                     <td className="px-6 py-4">{guardian.guardian_middle_name}</td>
                                     <td className="px-6 py-4">{guardian.guardian_last_name}</td>
                                     <td className="px-6 py-4">{guardian.guardian_mobile}</td>
                                     <td className="px-6 py-4">{guardian.dependent_relationship}</td>
                                     <td className="px-6 py-4">
                                       {Array.isArray(guardian.studentNames) && guardian.studentNames.length > 0
                    ? guardian.studentNames.join(", ")
                    : "No students assigned"}
                                        </td>
                                     <td className="px-2 py-1 gap-2 flex h-12">
                                         <button className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold text-xs px-1 py-2 w-20 rounded" onClick={() => handleAddDependentClick(guardian.guardian_user_id)}>
                                             Assign
                                         </button>
                                         <button
                                            className="bg-blue-500 hover:bg-blue-600  py-1 text-xs text-white font-bold px-2 rounded "
                                            onClick={() => handleEditGuardianClick(guardian)}
                                        >
                                            Edit Guardian
                                        </button>
                                        <button
                                            className="bg-yellow-500 hover:bg-yellow-600 text-white text-xs font-bold py-1 px-2 rounded"
                                            onClick={() => handleEditDependentClick(guardian)}
                                        >
                                            Edit Dependent
                                        </button>
                                        <button
                            onClick={() => handleDeleteClick(guardian.dependent_id)}
                            className="bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-2 rounded"
                          >
                            Delete
                          </button>
                                     </td>
                                 </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

             {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        contentLabel="Confirm Delete"
      >
        <div className="p-5">
          <h2 className="text-xl font-bold mb-4">Are you sure you want to delete this dependent?</h2>
          <div className="flex justify-end gap-4">
            <button
              onClick={handleDeleteDependent}
              className="bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded"
            >
              {loading ? 'Deleting...' : 'Delete'}
            </button>
            <button
              onClick={() => setDeleteModalIsOpen(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

               {/* Edit Guardian Info Modal */}
               <Modal isOpen={editGuardianModalIsOpen} onRequestClose={() => setEditGuardianModalIsOpen(false)} contentLabel="Edit Guardian Info">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <h2 className="text-2xl font-semibold mb-4">Edit Guardian Info</h2>
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                        type="text"
                        id="first_name"
                        value={guardianFormData.first_name}
                        onChange={(e) => setGuardianFormData({ ...guardianFormData, first_name: e.target.value })}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    />
                    <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">Middle Name</label>
                    <input
                        type="text"
                        id="middle_name"
                        value={guardianFormData.middle_name}
                        onChange={(e) => setGuardianFormData({ ...guardianFormData, middle_name: e.target.value })}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    />
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input
                        type="text"
                        id="last_name"
                        value={guardianFormData.last_name}
                        onChange={(e) => setGuardianFormData({ ...guardianFormData, last_name: e.target.value })}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    />
                    <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile</label>
                    <input
                        type="text"
                        id="mobile"
                        value={guardianFormData.mobile}
                        onChange={(e) => setGuardianFormData({ ...guardianFormData, mobile: e.target.value })}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    />
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={guardianFormData.password}
                        onChange={(e) => setGuardianFormData({ ...guardianFormData, password: e.target.value })}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    />
                    <div className="flex justify-end">
                        <button onClick={() => setEditGuardianModalIsOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2">
                            Cancel
                        </button>
                        <button onClick={handleSaveGuardianInfo} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Save Changes
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Edit Dependent Info Modal */}
            <Modal isOpen={editDependentModalIsOpen} onRequestClose={() => setEditDependentModalIsOpen(false)} contentLabel="Edit Dependent Info">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <h2 className="text-2xl font-semibold mb-4">Edit Dependent Info</h2>
                    
                    <label htmlFor="grade" className="block text-sm font-medium text-gray-700">Grade</label>
                    <select
                        id="grade"
                        value={selectedGrade}
                        onChange={(e) => {
                            setSelectedGrade(e.target.value);
                            fetchStudentsByGrade(e.target.value);
                        }}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Grade</option>
                        {grades.map((grade, index) => (
                            <option key={index} value={grade}>{grade}</option>
                        ))}
                    </select>

                    <label htmlFor="student" className="block text-sm font-medium text-gray-700">Student</label>
                    <select
                        id="student"
                        value={selectedStudentId}
                        onChange={(e) => setSelectedStudentId(e.target.value)}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Student</option>
                        {students.map((student) => (
                            <option key={student.student_id} value={student.student_id}>
                                {`${student.student_first_name} ${student.student_last_name}`}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="relationship" className="block text-sm font-medium text-gray-700">Relationship</label>
                    <select
                        id="relationship"
                        value={relationship}
                        onChange={(e) => setRelationship(e.target.value)}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Relationship</option>
                        {relationshipOptions.map((option) => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>

                    <div className="flex justify-end">
                        <button onClick={() => setEditDependentModalIsOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2">
                            Cancel
                        </button>
                        <button onClick={handleSaveDependentInfo} className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded">
                            Save Changes
                        </button>
                    </div>
                </div>
            </Modal>
            
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add Guardian Modal">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <form onSubmit={handleFormSubmit}>
                        <h2 className="text-xl font-bold mb-4">Add New Guardian</h2>
                        
                        {/* Form Fields */}
                        <div className="p-3">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" name="firstName" className="border border-gray-300 p-2 rounded block w-1/3"
                                   onChange={handleInputChange} />
                        </div>

                        <div className="p-3">
                            <label htmlFor="middleName">Middle Name</label>
                            <input type="text" name="middleName" className="border border-gray-300 p-2 rounded block w-1/3"
                                   onChange={handleInputChange} />
                        </div>

                        <div className="p-3">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" name="lastName" className="border border-gray-300 p-2 rounded block w-1/3"
                                   onChange={handleInputChange} />
                        </div>
                        <div className="p-3">
                <label htmlFor="mobile">Mobile</label>
                <input type="text" name="mobile" className="border border-gray-300 p-2 rounded block w-1/3"
                       onChange={handleInputChange} />
            </div>
                        
                        <div className="p-3">
                            <label htmlFor="password">Password</label>
                            <input type="text" name="password" className="border border-gray-300 p-2 rounded block w-1/3"
                                   onChange={handleInputChange} />
                        </div>

                        <div className="p-3">
                            <label htmlFor="grade">Grade</label>
                            <select name="grade" className="border border-gray-300 p-2 rounded block w-1/3"
                                    value={selectedGrade}  onChange={(e) => {
                                        setSelectedGrade(e.target.value);
                                        fetchStudentsByGrade(e.target.value);
                                    }}>
                                <option value="">Select Grade</option>
                                {grades.map((grade, index) => (
                            <option key={index} value={grade}>{grade}</option>
                        ))}
                            </select>
                        </div>

                        <div className="p-3">
                            <label htmlFor="studentId">Student</label>
                            <select name="studentId" className="border border-gray-300 p-2 rounded block w-1/3"
                                    onChange={handleInputChange}>
                                <option value="">Select Student</option>
                                {students.map(student => (
                                    <option key={student.student_id} value={student.student_id}>
                                        {`${student.student_first_name} ${student.student_last_name}`}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="p-3">
                            <label htmlFor="relationship">Relationship</label>
                            <select name="relationship" className="border border-gray-300 p-2 rounded block w-1/3"
                                    onChange={handleInputChange}>
                                <option value="">Select Relationship</option>
                                {[
                                    "father", "mother", "grandfather", "grandmother", "aunt", "uncle",
                                    "sister", "brother", "stepfather", "stepmother", "guardian", "relative"
                                ].map(rel => (
                                    <option key={rel} value={rel}>{rel}</option>
                                ))}
                            </select>
                        </div>

                        <div className="p-3">
                            <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded" disabled={loading}>
                                {loading ? 'Saving...' : 'Save'}
                            </button>
                            <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                    </form>
                </div>
                {loading && <p>Loading...</p>}
                {error && <p className="text-red-500">{error}</p>}
            </Modal>

             {/* Add Dependent Modal */}
             <Modal isOpen={addDependentModalIsOpen} onRequestClose={handleCloseDependentModal} contentLabel="Add Dependent Modal">
                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <h2 className="text-2xl font-semibold mb-4">Add Dependent</h2>
                    
                    <label htmlFor="grade" className="block text-sm font-medium text-gray-700">Grade</label>
                    <select
                        id="grade"
                        value={selectedGrade}
                        onChange={(e) => {
                            setSelectedGrade(e.target.value);
                            fetchStudentsByGrade(e.target.value);
                        }}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Grade</option>
                        {grades.map((grade, index) => (
                            <option key={index} value={grade}>{grade}</option>
                        ))}
                    </select>

                    <label htmlFor="student" className="block text-sm font-medium text-gray-700">Student</label>
                    <select
                        id="student"
                        value={selectedStudentId}
                        onChange={(e) => setSelectedStudentId(e.target.value)}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Student</option>
                        {students.map((student) => (
                            <option key={student.student_id} value={student.student_id}>
                                {`${student.student_first_name} ${student.student_last_name}`}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="relationship" className="block text-sm font-medium text-gray-700">Relationship</label>
                    <select
                        id="relationship"
                        value={relationship}
                        onChange={(e) => setRelationship(e.target.value)}
                        className="block w-full border border-gray-300 rounded-md p-2 mb-4"
                    >
                        <option value="">Select Relationship</option>
                        {relationshipOptions.map((option) => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>

                    <div className="flex justify-end">
                        <button onClick={handleCloseDependentModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2">
                            Cancel
                        </button>
                        <button onClick={handleAssignDependent} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                            Assign Dependent 
                        </button>
                    </div>
                </div>
            </Modal>
            {/* Toast Notification */}
<Toast message={toastMessage} visible={toastVisible} onClose={() => setToastVisible(false)} />
        </div>
    );
}

export default Guardian;
