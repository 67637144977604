import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/auth'
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Teachers() {
    const {user, token } = useContext(AuthContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const [formData, setFormData] = useState({
        user: { 
                first_name: '',
                middle_name: '',
                last_name: '',
                mobile: '',
                password:''
              },
        teacher: { 
                  gender: ''
                } 
        });

    const [updateMode, setUpdateMode] = useState(false); //to identify operation
    const [updateId, setUpdateId] = useState(null); //to identify record to be modified
    
    //setting up state variables for fetching teacher data
    const [teachers, setTeachers] = useState([]);; //holds fetched teachers data from API
    const [selectedCourse, setSelectedCourse] = useState(''); //selected subject to display its teachers detail
    const [searchName, setSearchName] = useState(''); //to enable search of teachers by name
    
    //request processing indicator
    const [loading, setLoading] = useState(true);

    //Teachers data fetching
    useEffect(()=>{
        const fetchTeacher = async () =>{
            try{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/teachers`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                  });
               const respondedTeacher =  await response.json();
               setTeachers(respondedTeacher);  

               //const subjects = Array.from(new Set(respondedTeacher.map(teacher => teacher.course_name)));
               //setUniqueSubjects(subjects); 
            }
            catch(error){
                console.log('Error:', error);
           }
           finally {
                setLoading(false);
            }
       };
       fetchTeacher();  
   }, []);

   const filteredTeachers = teachers.filter(
    (teacher) =>
      (selectedCourse === '' || teacher.course_name === selectedCourse) &&
      (searchName === '' ||
        teacher.teacher_first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        teacher.teacher_middle_name.toLowerCase().includes(searchName.toLowerCase()) ||
        teacher.teacher_last_name.toLowerCase().includes(searchName.toLowerCase())
      )
  );

//pagination state variables
const itemsPerPage = 10; // Number of items per page
const [currentPage, setCurrentPage] = useState(1);

const totPages = Math.ceil(filteredTeachers.length / itemsPerPage);
const indexOfLastTeacher = currentPage * itemsPerPage;
const indexOfFirstTeacher = indexOfLastTeacher - itemsPerPage;

  const currentTeachers = filteredTeachers.slice(indexOfFirstTeacher, indexOfLastTeacher);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
 };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(1);
  };

  const handleNameChange = (event) => {
    setSearchName(event.target.value);
    setCurrentPage(1);
  };

  const uniqueCourses = Array.from(new Set(teachers.map((teacher) => teacher.course_name)));

//Handling other events
 const handleAddTeacherClick = () => {
    setModalIsOpen(true);
 };

 const handleCloseModal = () => {
    setFormData({
            user: { 
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    mobile:'',
                    password:''
                },
            teacher: { 
                gender: ''
                }
        }); //clearing form
        setUpdateId(null);
        setUpdateMode(false);
        setModalIsOpen(false);  
    };

 const handleFormChange = (e) => {
    const { name, value } = e.target;
        if (name in formData.user) {
            setFormData((prevData) => ({
                ...prevData,
                user: {
                    ...prevData.user, 
                    [name]: value
                }
            }));
        } 
        else if (name in formData.teacher) {
            setFormData((prevData) => ({
                ...prevData,
                teacher: {
                    ...prevData.teacher,
                    [name]: value
                }
            }));
        }
  };

 //creating and updating teacher
  const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(formData);
        try{
            if(updateMode) {
                const update = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${updateId}`,{
                            method:'PATCH',
                            headers:{
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                                },
                            body:JSON.stringify(formData)
                     });
                if (update.ok) {
                        alert('Teacher information modified successfully!');
                    }
                else {
                        alert('Sorry, operation cancelled.');
                    }
            } 
            else {
                 const create = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers`,{
                        method: 'POST',
                        headers: {
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                           },
                        body:JSON.stringify(formData)
                    });
                if (create.ok) {
                        alert('Teacher information created successfully!');
                    } 
                else {
                      alert('Sorry, operation cancelled.');
                 }            
            }
            handleCloseModal(); 
          } 
        catch(error){
            console.error(error);
        }
        finally{
            setLoading(false);
        }
    };

 const handleUpdate = (teacher) => {
      setUpdateMode(true);
      setUpdateId(teacher.teacher_user_id);
      setModalIsOpen(true);
      setFormData({ 
            user: {  
                    first_name:teacher.teacher_first_name,
                    middle_name:teacher.teacher_middle_name, 
                    last_name:teacher.teacher_last_name,
                    mobile:teacher.teacher_mobile
                    //password: teacher.password
            },
            teacher:{
                    gender:teacher.teacher_gender
            }
        });
  };
  //keep users informed
  if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center">
                <svg
                    className="animate-spin h-10 w-10 text-blue-500 mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                </svg>
                <p className="text-lg font-semibold text-gray-600">
                    Loading list of teachers, please wait...
                </p>
            </div>
        </div>
    );
}

//Displaying results
 return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">Teachers Details</h1>
            <div className="flex items-center gap-4 mb-4">
                {/* Dropdown for selecting subject */}
                <div className="w-1/4 pr-4">
                    <select id="course" 
                            name="course" 
                            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                            value={selectedCourse}
                            onChange={handleCourseChange}
                    >
                        <option value="">All Subject</option>
                        {uniqueCourses.map((course) => (
                            <option key={course} value={course}>{course}</option>
                        ))}
                    </select>
                </div>

                {/* Search Bar */}
                <div className="w-1/4">
                    <input
                        type="text"
                        placeholder="Search teacher by name..."
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={searchName}
                        onChange={handleNameChange}
                    />
                </div>

                {/* Add teacher button */}
                <div className="flex justify-end w-2/3">
                    <button onClick={handleAddTeacherClick} type="submit" className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                        Add New Teacher
                    </button>
                </div>
            </div>

            {/* List of teachers */}
            <div className="mt-8">
              <div>
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-slate-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">No.</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">First Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Middle Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Last Name</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Gender</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Subject</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Contact Number</th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">Edit</th>
                        </tr>
                    </thead>
                    <tbody className="border-b border-gray-200">
                    {currentTeachers.length === 0 ? (
                        <tr>
                            <td colSpan="" className="text-center py-4">No teacher available.</td>
                        </tr>
                         ) : (

                        currentTeachers.map((teacher, index) => (
                            <tr className="border-b border-gray-200" key={`${teacher.teacher_id}-${index}`}> {/*duplicated teacher_id found and handled adding index*/}
                                <td className="px-6 py-4">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td className="px-6 py-4">{teacher.teacher_first_name}</td>
                                <td className="px-6 py-4">{teacher.teacher_middle_name}</td>
                                <td className="px-6 py-4">{teacher.teacher_last_name}</td>
                                <td className="px-6 py-4">{teacher.teacher_gender}</td>
                                <td className="px-6 py-4">{teacher.course_name}</td>
                                <td className="px-6 py-4">{teacher.teacher_mobile}</td>
                                <td className="px-6 py-4">
                                    <button className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-1 px-2 rounded"
                                        onClick ={() => handleUpdate(teacher)}>
                                            Edit
                                    </button>
                                </td>
                        </tr>
                         ))
                        )}
                    </tbody>
                </table>
              </div>
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`mx-1 px-4 py-2 border rounded ${
                            currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
                        }`} >
                        {index + 1}
                    </button>
                ))}
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Add Teacher Modal" >

                <div className="bg-slate-100 mt-8 p-4 shadow-md rounded-md">
                    <form onSubmit={handleFormSubmit}>
                        <h2 className="text-xl font-bold mb-4">{updateMode ? 'Edit Teacher':'Add New Teacher'}</h2>
                        <div className="p-3">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                id="first_name"
                                name="first_name"
                                type="text"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.first_name}
                                onChange={handleFormChange}
                            /> 
                        </div>

                        <div className="p-3">
                            <label htmlFor="middleName">Middle Name</label>
                            <input
                                id="middle_name"
                                name="middle_name"
                                type="text"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.middle_name}
                                onChange={handleFormChange}
                            />  
                        </div>

                        <div className="p-3">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                id="last_name"
                                name="last_name"
                                type="text"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.user.last_name}
                                onChange={handleFormChange}
                            /> 
                        </div>

                        <div className="p-3">
                            <label htmlFor="gender">Gender</label>
                            <select
                                id="gender"
                                name="gender"
                                required
                                className="border border-gray-300 p-2 rounded block w-1/3"
                                value={formData.teacher.gender}
                                onChange={handleFormChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="p-3">
                                 <label htmlFor="contactNumber">Contact Number</label>
                                 <input
                                     id="mobile"
                                     name="mobile"
                                     type="text"
                                     required
                                     className="border border-gray-300 p-2 rounded block w-1/3"
                                     value={formData.user.mobile}
                                     onChange={handleFormChange} />
                        </div>

                         {!updateMode && (
                           <>   
                            <div className="p-3">
                                 <label htmlFor="Password">Password</label>
                                 <input
                                     id="password"
                                     name="password"
                                     type="text"
                                     required
                                     className="border border-gray-300 p-2 rounded block w-1/3"
                                     value={formData.user.password}
                                     onChange={handleFormChange} />
                            </div> 
                          </>
                        )}
                        <div className="p-3">
                            <button type="submit" className="bg-cyan-950 text-white py-2 px-4 rounded">
                            {updateMode ? 'Save Changes': 'Register Teacher'}
                            </button>
                            <button type="button" onClick={handleCloseModal} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
export default Teachers;