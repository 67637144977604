import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/auth';
import Modal from 'react-modal';

function StudentClassDetail() {
    const location = useLocation();
    const { classId } = location.state;

    const { user, token } = useContext(AuthContext);
    const [upcomingAssignments, setUpcomingAssignments] = useState([]);
    const [pastAssignments, setPastAssignments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const navigate = useNavigate();

    // Fetch upcoming and past assignments for the class
    useEffect(() => {
        const fetchClassDetails = async () => {
            if (user && token) {
                try {
                    setLoading(true);

                    // Fetch assignments (grades API)
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/grades`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );

                    const assignments = response.data.filter(
                        (assignment) => assignment.class_id === classId
                    );

                    // Separate upcoming and past due assignments
                    const currentDate = new Date();
                    const upcoming = assignments.filter(
                        (assignment) =>
                            !assignment.grade_score &&
                            new Date(assignment.assignment_due_date) >= currentDate
                    );

                    const past = assignments.filter(
                        (assignment) => new Date(assignment.assignment_due_date) < currentDate
                    );

                    setUpcomingAssignments(upcoming);
                    setPastAssignments(past);
                } catch (error) {
                    setError('Failed to fetch class details. Please try again later.');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchClassDetails();
    }, [user, token, classId]);

    // Modal functions
    const handleOpenViewModal = (assignment) => {
        setSelectedAssignment(assignment);
        setViewModalIsOpen(true);
    };

    const handleCloseViewModal = () => {
        setViewModalIsOpen(false);
        setSelectedAssignment(null);
    };

    // Navigate back to StudentClasses
    const goBack = () => {
        navigate('/studentClasses');
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading class details, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <p className="text-red-500 text-center">{error}</p>;
    }

    return (
        <div className="p-5">
            <h1 className="text-3xl font-bold mb-6 p-5">
                Class Details: {upcomingAssignments[0]?.course_name || pastAssignments[0]?.course_name || 'Class'}
            </h1>

            {/* Back to classes button */}
            <button
                onClick={goBack}
                className="mb-4 bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded"
            >
                Back to Classes
            </button>

            {/* Up Coming Assignments */}
            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                                Up Coming Assignments
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {upcomingAssignments.length > 0 ? (
                            upcomingAssignments.map((assignment) => (
                                <tr key={assignment.assignment_id}>
                                    <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                                        <div>
                                            <p>{assignment.assignment_name}</p>
                                            <p className="text-sm text-gray-600">
                                                Due Date: {new Date(assignment.assignment_due_date).toLocaleDateString()} |{' '}
                                                {assignment.assignment_total} pts
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => handleOpenViewModal(assignment)}
                                            className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="px-6 py-4 text-center">No upcoming assignments</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Past Assignments */}
            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider">
                                Past Assignments
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {pastAssignments.length > 0 ? (
                            pastAssignments.map((assignment) => (
                                <tr key={assignment.assignment_name}>
                                    <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                                        <div>
                                            <p>{assignment.assignment_name}</p>
                                            <p className="text-sm text-gray-600">
                                                Due Date: {new Date(assignment.assignment_due_date).toLocaleDateString()} |{' '}
                                                {assignment.grade_score !== null
                                                    ? `${assignment.grade_score}/${assignment.assignment_total} pts`
                                                    : 'Not received or graded'}
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => handleOpenViewModal(assignment)}
                                            className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="px-6 py-4 text-center">No past assignments</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* View Assignment popup */}
<Modal
    isOpen={viewModalIsOpen}
    onRequestClose={handleCloseViewModal}
    contentLabel="View Assignment Modal"
>
    <div className="bg-white p-5 rounded-md shadow-md">
        <h1 className="text-xl font-bold mb-4 text-center">{selectedAssignment?.assignment_name}</h1>

        <div className="flex items-center space-x-2 mb-6">
            <p className="font-bold">Assignment Title:</p>
            <p>{selectedAssignment?.assignment_name}</p>
        </div>

        <div className="flex items-center space-x-2 mb-6">
            <p className="font-bold">Due Date:</p>
            <p>{new Date(selectedAssignment?.assignment_due_date).toLocaleDateString()}</p>
        </div>

        <div className="flex items-center space-x-2 mb-6">
            <p className="font-bold">Point:</p>
            <p>{selectedAssignment?.assignment_total} pts</p>
        </div>

        <div className="flex items-center space-x-2 mb-6">
            <p className="font-bold">Score:</p>
            {/* Display score if available, otherwise show 'Not graded' */}
            <p>
                {selectedAssignment?.grade_score !== null
                    ? `${selectedAssignment?.grade_score}/${selectedAssignment?.assignment_total} pts`
                    : 'Not graded'}
            </p>
        </div>

        <div className="flex items-center space-x-2 mb-8">
            <p className="font-bold">Assignment Detail: </p>
            <p>{selectedAssignment?.assignment_description}</p>
        </div>

        <div className="flex justify-start space-x-2 py-4">
            <button
                type="button"
                onClick={handleCloseViewModal}
                className="bg-gray-500 hover:bg-gray-800 text-white py-2 px-4 rounded"
            >
                Close
            </button>
        </div>
    </div>
</Modal>

        </div>
    );
}

export default StudentClassDetail;
