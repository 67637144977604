import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/auth";

function StudentTimetable() {
    const { user, token } = useContext(AuthContext); // Fetching user and token from AuthContext
    const [classes, setClasses] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    // Fetch class schedule on component mount
    useEffect(() => {
		const fetchClasses = async () => {
			if (user && token) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/classes`,
						{
							headers: {
								Authorization: `Bearer ${token}`, // Set Authorization header
							},
						},
					);
					setClasses(response.data);
				} catch (error) {
					setError("Failed to fetch class schedule. Please try again later.");
				} finally {
					setIsLoading(false); // Stop loading when data is fetched
				}
			}
		};

        fetchClasses();
    }, [user, token]);

    // Define all the periods and their respective time ranges based on the response
	const periods = [
		{ period: "1", time: "8:00 - 9:00 AM" },
		{ period: "2", time: "9:30 - 10:20 AM" },
		{ period: "2A", time: "9:50 - 10:40 AM" },
		{ period: "3", time: "10:30 - 11:20 AM" },
		{ period: "4", time: "11:30 - 12:20 PM" },
		{ period: "5A", time: "12:30 - 1:20 PM" },
		{ period: "5", time: "1:30 - 2:20 PM" },
		{ period: "6", time: "2:30 - 3:20 PM" },
	];

    const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ];

    // Helper function to format class data for each day and period
    const getClassesForDayAndPeriod = (day, period) => {
        const classesForPeriod = classes.filter(
            (cls) => cls.day_of_week === day && cls.period === period,
        );

        // If there are multiple classes for the same period, we return them all
        if (classesForPeriod.length > 0) {
            return classesForPeriod.map((classForPeriod) => (
                <div key={classForPeriod.class_id} className="mt-2 bg-sky-50 p-4 w-full text-center">
                    <p>Period {classForPeriod.period}</p>
                    <p className="font-bold">{classForPeriod.course_name}</p>
                    <p className="text-sm font-light text-gray-600">
                        {classForPeriod.start_time} - {classForPeriod.end_time}
                    </p>
                    <p className="text-sm font-light text-gray-600">{classForPeriod.teacher_first_name} {classForPeriod.teacher_middle_name}</p>
                </div>
            ));
        } else {
            return (
                <div className="mt-2 bg-sky-50 p-4 w-full text-center">
                    <p>No class</p>
                </div>
            );
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        />
                    </svg>
                    <p className="text-lg font-semibold text-gray-600">
                        Loading classes, please wait...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return <p className="text-red-500 text-center">{error}</p>;
    }

    return (
        <div className="mt-8 p-5">
            <h2 className="text-4xl text-center font-bold mb-12">Class Schedule</h2>
            <div className="flex flex-row flex-nowrap gap-4 overflow-x-auto">
                {daysOfWeek.map((day) => (
                    <div key={day} className="flex flex-col items-center bg-sky-200 p-4 w-1/6 min-w-[150px]">
                        <div className="font-bold text-lg capitalize">{day}</div>
                        {periods.map((period) => {
                            return (
                                <React.Fragment key={period.period}>
                                    {getClassesForDayAndPeriod(day, period.period)}
                                </React.Fragment>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StudentTimetable;