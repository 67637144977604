import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from "../../../contexts/auth";

function AdminGradeReport() {
    //login credentials
    const {user, token } = useContext(AuthContext);

    const [students, setStudents] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [studentDetails, setStudentDetails] = useState(null);
    const [overallScores, setOverallScores] = useState({ totalScoreSum: 0, maxScoreSum: 0, averagePercentage: 0 });

    useEffect(() => {
        // Simulate fetching students details
        const fetchStudentData = async () => {

            try{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/students`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                  });
               const studentData =  await response.json();
               setStudents(studentData);
               console.log(studentData);

               // Extract unique grades
                const uniqueGrades = [...new Set(studentData.map(student => student.student_grade))];
                setGrades(uniqueGrades);
            }
            catch(error){
                console.log('Error:', error);
           }
       };
       fetchStudentData();  
    }, []);

    //fetching students grade details
    useEffect(()=>{
        const fetchAssignmentsScore = async () =>{
            try{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/grades`,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                  });
               const scores =  await response.json();
               setAssignments(scores);
               console.log(scores);
            }
            catch(error){
                console.log('Error:', error);
           }
       };
       fetchAssignmentsScore();  
    }, []);

    useEffect(() => {
        if (selectedGrade) {
            setFilteredStudents(students.filter(student => student.student_grade === Number(selectedGrade)));
        } 
        else {
            setFilteredStudents(students);
        }
    }, [selectedGrade, students]);

    const calculateScores = (student) => {
        const studentAssignments = assignments.filter(assign => assign.student_user_id === student.student_user_id);

        const totalScore = studentAssignments.reduce((sum, assign) => sum + assign.grade_score, 0);
        const maxScore = studentAssignments.reduce((sum, assign) => sum + assign.assignment_total, 0);
        const percentage = maxScore > 0 ? ((totalScore / maxScore) * 100).toFixed(2) : 0;

        return { totalScore, maxScore, percentage };
    };

    const calculateCourseScores = (student) => {
        const studentAssignments = assignments.filter(assign => assign.student_user_id === student.student_user_id);
        
        const courseScores = {};

        studentAssignments.forEach(assign => {
            const {course_name, grade_score, assignment_total} = assign;
            if (!courseScores[course_name]) {
                courseScores[course_name] = {
                    totalScore: 0,
                    maxScore: 0,
                };
            }
            courseScores[course_name].totalScore += grade_score;
            courseScores[course_name].maxScore += assignment_total;
        });

        return Object.entries(courseScores).map(([courseName, scores]) => ({
            courseName,
            totalScore: scores.totalScore,
            maxScore: scores.maxScore,
            percentage: scores.maxScore > 0 ? ((scores.totalScore / scores.maxScore) * 100).toFixed(2) : 0,
        }));
    };

    // Calculate overall scores
    useEffect(() => {
        //let targettedStudents = students;
        const targettedStudents = selectedGrade ? 
                                    students.filter(student => student.student_grade === Number(selectedGrade))
                                        : students;

        const scores = targettedStudents.map(student => calculateScores(student));

        const totalScoreSum = scores.reduce((sum, score) => sum + score.totalScore, 0);
        const maxScoreSum = scores.reduce((sum, score) => sum + score.maxScore, 0);
        const classPercentage = maxScoreSum>0? ((totalScoreSum / maxScoreSum) * 100).toFixed(2) : 0; 
        setOverallScores({ totalScoreSum, maxScoreSum, classPercentage });
    }, [selectedGrade, students, assignments]);

    const handleSendClick = (student) => {
       const courseScores = calculateCourseScores(student);
        setStudentDetails({
            studentName: `${student.student_first_name} ${student.student_middle_name} ${student.student_last_name}`,
            studentGrade: `${student.student_grade}`,
            courseScores,
        });
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setStudentDetails(null);
    };

    return (
        <div className="p-5">
            <h1 className="text-4xl font-semibold mb-8 p-5">Sample Grade Report</h1>
        
            <div className="flex items-center gap-4 mb-4">
                {/* Dropdown for selecting grade */}
                <div className="w-1/4 pr-4">
                    <select id="gradeSelect" 
                        name="grade" 
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(Number(e.target.value))}>
                        <option value="">Select Grade</option>
                        {grades.map((grade) => (
                            <option key={grade} value={grade}>Grade {grade}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/*Grade Summary by class*/}
            <div className="mt-8">
                <div className="overflow-x-auto h-[65vh]">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-slate-200">
                            <tr>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> N<u>o</u>:</th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Student Name </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Grade </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Total Score </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Maximum Score </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Percentage </th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">  Details</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {filteredStudents.map((student, index) => {
                                const { totalScore, maxScore, percentage } = calculateScores(student);
                                return(
                            <tr key={student.student_user_id}>
                                <td className="px-6 py-4">{index + 1}.</td>
                                <td className="px-6 py-4">{`${student.student_first_name} ${student.student_middle_name} ${student.student_last_name}`}</td>
                                <td className="px-6 py-4">{student.student_grade}</td>
                                <td className="px-6 py-4">{totalScore}</td>
                                <td className="px-6 py-4">{maxScore}</td>
                                <td className="px-6 py-4">{percentage}%</td>
                                <td className="px-6 py-4">
                                    <button onClick={() => handleSendClick(student)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                        View
                                    </button>
                                </td>
                            </tr>
                          );
                       })}
                            <tr>
                                <td className="px-6 py-6 font-bold"> - </td>
                                <td className="px-6 py-6 font-bold"> Summary </td>
                                <td className="px-6 py-6 font-bold"> - </td>
                                <td className="px-6 py-6 font-bold">{overallScores.totalScoreSum}</td>
                                <td className="px-6 py-6 font-bold">{overallScores.maxScoreSum}</td>
                                <td className="px-6 py-6 font-bold">{overallScores.classPercentage}%</td>
                                <td className="px-6 py-6 font-bold">-</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Detailed Grade Popup per student*/}
            {studentDetails && (
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Inbox Modal" >
                <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
                    <h1 className="text-xl font-bold mb-4"> {studentDetails.studentName} - Grade {studentDetails.studentGrade}</h1>
                    <table className="w-full" >
                        <thead>
                            <tr>
                                <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Subject </th>
                                <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Point </th>
                                <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Max Point </th>
                                <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Percentage </th>
                            </tr>                                    
                        </thead>

                        <tbody>
                        {studentDetails.courseScores.map((course) => (
                            <tr key={course.courseName} className="border-b border-gray-300">
                                <td className="py-2 pl-0 -ml-4">{course.courseName}</td>
                                <td className="py-2">{course.totalScore}</td>
                                <td className="py-2">{course.maxScore}</td>
                                <td className="py-2">{course.percentage}%</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    <button type="button" onClick={handleCloseModal} className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6">
                        Cancel
                    </button>
                </div>
            </Modal>
            )}
        </div>
    );
}
export default AdminGradeReport;