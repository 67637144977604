import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";

function StudentClasses() {
	const navigate = useNavigate();
	const { user, token } = useContext(AuthContext);
	const [classes, setClasses] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

    // Function to navigate to class detail page and pass the class_id
    const navigateToClassDetail = (classId) => {
        console.log("🚀 ~ navigateToClassDetail ~ classId:", classId)
		navigate("/studentClassDetail", { state: { classId } }); // Pass class_id as a route parameter
    };


	// Fetch the student's classes
	useEffect(() => {
		const fetchClasses = async () => {
			if (user && token) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/classes`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);
					setClasses(response.data);
				} catch (error) {
					setError("Failed to fetch classes. Please try again later.");
				} finally {
					setLoading(false); // Set loading to false when data is fetched
				}
			}
		};

		fetchClasses();
	}, [user, token]);

	if (loading) {
		return (
			<div className="flex items-center justify-center min-h-screen">
				<div className="flex flex-col items-center">
					{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
					<svg
						className="animate-spin h-10 w-10 text-blue-500 mb-4"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						/>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
						/>
					</svg>
					<p className="text-lg font-semibold text-gray-600">
						Loading classes, please wait...
					</p>
				</div>
			</div>
		);
	}

	if (error) {
		return <p className="text-red-500 text-center">{error}</p>;
	}
	
	// array of colors to make each card show different colors
	const colors = ["bg-sky-200", "bg-pink-200", "bg-blue-300", "bg-indigo-200", "bg-cyan-200", "bg-purple-300", "bg-green-200", "bg-red-200", "bg-yellow-200"];

	return (
		<div className="p-5">
			<h1 className="text-4xl font-bold mb-6 p-5">All Classes</h1>

			{/* Display the list of classes */}
			<div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-8 p-4">
				{classes.length > 0 ? (
					classes.map((classData, index) => (
						<div
							key={classData.class_id}
							className={`text-lg p-4 shadow-md rounded-lg ${colors[index % colors.length]}`}
						>
							<p className="font-bold">Subject: {classData.course_name}</p>
							<p>Room Number: {classData.room_number}</p>
							<p>
								Teacher:{" "}
								{`${classData.teacher_first_name} ${classData.teacher_middle_name}`}
							</p>
							<div className="flex justify-center mt-4">
								{/* biome-ignore lint/a11y/useButtonType: <explanation> */}
								<button
									onClick={() => navigateToClassDetail(classData.class_id)}
									className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 text-lg rounded"
								>
									View Class
								</button>
							</div>
						</div>
					))
				) : (
					<p>No classes found</p>
				)}
			</div>
		</div>
	);
}

export default StudentClasses;
