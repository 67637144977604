import React from "react";
function Footer() {
    return (
        <footer className="bg-cyan-950 text-white p-5">
            <div className="flex justify-center">
                <p>© {new Date().getFullYear()} Temariko - All rights reserved</p>
            </div>
        </footer>
    );
}

export default Footer;