import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'
import Modal from 'react-modal';

function Inbox() {
    const [send, setSend] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    // Modal - for pop up
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleSendClick = () => {
        setModalIsOpen(true);
    };
    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    // Example 
    const sendTo = ['Send to all Users', 'Send to all Teachers', 'Send to all Students', ' Mr. Smith', 'Ms. Jane', 'John Max'];

    const sendMessage = () => {
        // Implement message sending logic here
        console.log({ send, title, content });
    };

    return (
        <div className="p-5">
            {/* Send new message button */}
            <div className="flex justify-start mt-8">
                <button onClick={handleSendClick} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                    Send New Message
                </button>
            </div>

            <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md"> 
                <h1 className="text-3xl font-bold mb-4">Inbox</h1>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Inbox Modal" >
                <div className="bg-white p-5 rounded-md shadow-md">
                    <h1 className="text-2xl font-bold mb-4 text-center">Send Message</h1>
                    <div className="mb-4">
                        <label htmlFor="send" className="block mb-2">Select an Option:</label>
                        <select
                            id="send"
                            value={send}
                            onChange={(e) => setSend(e.target.value)}
                            className="block w-full p-2 border border-gray-300 rounded"
                        >
                            <option value="">Select an Option</option>
                            {sendTo.map((send, index) => (
                                <option key={index} value={send}>{send}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="title" className="block mb-2">Title:</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="content" className="block mb-2">Content:</label>
                        <textarea
                            id="content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="block w-full p-2 border border-gray-300 rounded h-40"
                        />
                    </div>
                    <button
                        onClick={sendMessage}
                        className="bg-cyan-800 hover:bg-cyan-950 text-white py-2 px-4 rounded"
                    >
                        Send Message
                    </button>
                    <button type="button" onClick={handleCloseModal} className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded ml-2">
                        Cancel
                    </button>
                </div>      
            </Modal>
            
        </div>
    );
}

export default Inbox;