import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { DefaultViews, RoleBasedViews } from "../views";

const ProtectedRoute = () => {
	const { user } = useContext(AuthContext);
	const location = useLocation();

	if (!user) {
		return <Navigate to="/signin" />;
	}

	try {
		return RoleBasedViews[user.role_id].routes[location.pathname].component;
	} catch {
		try {
			return DefaultViews[location.pathname].component;
		} catch {
			return <Navigate to="/403" />;
		}
	}
};

export { ProtectedRoute };
