import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function StudentCalendar() {
    const { user, token } = useContext(AuthContext);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [upcomingAssignments, setUpcomingAssignments] = useState([]);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const firstDayOfMonth = () => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    };

    const renderDaysOfWeek = () => {
        return dayNames.map(day => (
            <div key={day} className="border p-4 font-semibold bg-slate-200">
                {day}
            </div>
        ));
    };

    const renderCalendarDays = () => {
        const days = [];
        const numDays = daysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDay = firstDayOfMonth();

        for (let i = 0; i < firstDay; i++) {
            days.push(<div key={`empty-${i}`} className="border p-4 bg-gray-50"></div>);
        }
        for (let day = 1; day <= numDays; day++) {
            days.push(
                <div key={day} className={`border p-4 ${day === currentDate.getDate() ? 'bg-blue-200' : 'bg-white'}`}>
                    {day}
                </div>
            );
        }
        return days;
    };

    const handlePreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    useEffect(() => {
        const fetchUpcomingAssignments = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/assignments`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Sorry, data fetching unsuccessful');
                }

                const calendarResponse = await response.json();
                setUpcomingAssignments(calendarResponse);
               console.log(calendarResponse);
            } catch (error) {
                console.error("Error fetching assignments:", error);
            }
        };

        fetchUpcomingAssignments();
    }, []);

    /*Filtering assignment with selected months due date */
    // Filter assignments for the current month
    const filteredAssignments = upcomingAssignments.filter(assignment => {
        const dueDate = new Date(assignment.assignment_due_date);
            return dueDate.getFullYear() === currentDate.getFullYear() && dueDate.getMonth() === currentDate.getMonth();
        });
    
    return (
        <div>
            <div className="flex flex-col items-center">
                <div className="mt-12 w-full max-w-4xl">
                    <div className="flex justify-between items-center mb-4">
                        <button onClick={handlePreviousMonth} className="p-4 bg-slate-200 rounded"> <FaArrowLeft size={20} /> </button>
                        <h2 className="text-4xl font-semibold text-center">
                            {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
                        </h2>
                        <button onClick={handleNextMonth} className="p-4 bg-slate-200 rounded"> <FaArrowRight size={20} /> </button>
                    </div>
                    <div className="grid grid-cols-7 gap-4 mt-3">
                        {renderDaysOfWeek()}
                        {renderCalendarDays()}
                    </div>
                </div>
            </div> 

            {/* Up coming Exam for that month */}
            <div className="bg-slate-200 mt-8 ml-8 mr-8 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold tracking-wider mb-4">Upcoming Exam/Quiz for This Month</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 max-h-[200px] overflow-y-auto">
                    {filteredAssignments.length === 0 ? (
                        <div className="col-span-full text-center py-4">No upcoming assignments for {currentDate.toLocaleString('default', { month: 'long' })} of {currentDate.getFullYear()} Academic year</div>
                    ) : (
                        filteredAssignments.map((assignment, index) => (
                            <div className="bg-slate-100 p-4 shadow-md rounded-md" key={index}>
                                <p className="text-gray-700 mb-1"><strong>{index + 1}. Subject:</strong> {assignment.course_name}</p>
                                <p className="text-gray-700 mb-1"><strong>Assignment Name:</strong> {assignment.assignment_name}</p>
                                <p className="text-gray-700 mb-1">
                                    <strong>Due Date:</strong> {new Date(assignment.assignment_due_date).toLocaleDateString()}
                                    <strong>  Point:</strong> {assignment.assignment_total}
                                </p>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default StudentCalendar;