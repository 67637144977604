import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";
import axios from 'axios';

function AdminTeacherTimetable() {
    const {user, token} = useContext(AuthContext);
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [timetable, setTimetable] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTeachers = async () => {
            if (user && token) {
                setLoading(true);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/teachers`, 
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setTeachers(response.data);
                    console.log("Fetched Teachers:", response.data);
                } catch (error) {
                    console.error("Error fetching teachers:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchTeachers();
    }, [user, token]);

    const handleTeacherChange = (event) => {
        const teacherId = event.target.value;
        setSelectedTeacher(teacherId);

        // Fetch timetable for the selected teacher
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/v1/administrators/${user.user_id}/classes`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            const filteredTimetable = response.data.filter(
                item => item.teacher_id === teacherId
            );
            setTimetable(filteredTimetable);
        })
        .catch(error => {
            console.error('Error fetching timetable:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="mt-8 p-5">
            <h2 className="text-4xl text-center font-bold mb-12">Teacher Timetable</h2>

            {/* Select/search teacher by name */}
            <div className="w-1/4 pr-4 mb-4">
                <select
                    id="teacherSelect"
                    name="teacher"
                    className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded"
                    onChange={handleTeacherChange}
                    value={selectedTeacher}
                >
                    <option value="">Select Teacher</option>
                    {teachers.map(teacher => (
                        <option key={teacher.teacher_id} value={teacher.teacher_id}>
                            {`${teacher.teacher_first_name} ${teacher.teacher_middle_name} ${teacher.teacher_last_name}`}
                        </option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="text-center">
                    <p className="text-lg font-semibold text-gray-600">
                        Loading Timetable...
                    </p>
                </div>
            ) : (
                <div className="flex flex-row flex-nowrap gap-4 overflow-x-auto">
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map(day => (
                        <div key={day} className="flex flex-col items-center bg-sky-200 p-4 w-1/6 min-w-[150px]">
                            <div className="font-bold text-lg">{day}</div>
                            {timetable.filter(item => item.day_of_week && item.day_of_week.toLowerCase() === day.toLowerCase()).map(item => (
                                <div key={item.schedule_id} className="mt-2 bg-sky-50 p-4 w-full text-center">
                                    <p className='font-bold'>{item.period}. {item.course_name}</p>
                                    <p className="text-sm font-light">{item.start_time} - {item.end_time}</p>
                                    <p className='text-sm font-light'>Room: {item.room_number}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AdminTeacherTimetable;