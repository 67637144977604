import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import axios from 'axios';

function StudentProfile() {
    const { user, token } = useContext(AuthContext);
    const [profile, setProfile] = useState(null);

    // Fetch profile data
    useEffect(() => {
        const fetchProfile = async () => {
            if (user && token) {
                try {
                    console.log('Fetching profile data...');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    console.log('Profile data:', response.data);
                    setProfile(response.data[0]); // Access the first element of the array
                } catch (error) {
                    console.error("Failed to fetch profile data. Please try again later.", error);
                }
            }
        };

        fetchProfile();
    }, [user, token]);

    useEffect(() => {
        console.log('Profile state updated:', profile);
    }, [profile]);

    if (!profile) {
        return (
			<div className="flex items-center justify-center min-h-screen">
                <p className="text-lg font-semibold text-gray-600">
                    Loading Profile...
                </p>
			</div>
		);
    }

    return (
        <div className="p-5">
            <div className="p-5 text-center bg-slate-100 mt-8 p-4 shadow-md rounded-md"> 
                <h2 className="text-2xl font-semibold mt-4">{profile.first_name} {profile.middle_name}</h2>
                <p className="text-gray-600">Student Number: {profile.student_number}</p>
            </div>

            <div className="flex flex-col md:flex-row gap-4 mt-8">
                <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1">
                    <h2 className="text-2xl font-semibold text-center mt-4">Student Information</h2>
                    <h2 className="text-xl mt-4"><strong className="font-semibold">Name:</strong> {profile.first_name} {profile.middle_name} {profile.last_name}</h2>
                    <p className="text-xl mt-4"><strong className="font-semibold">Gender:</strong> {profile.gender}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Grade:</strong> {profile.grade}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Date of Birth:</strong> {profile.date_of_birth}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Student Number:</strong> {profile.student_number}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Phone Number:</strong> {profile.mobile}</p>
                </div>

                <div className="p-5 bg-slate-100 p-4 shadow-md rounded-md flex-1">
                    <h2 className="text-2xl font-semibold text-center mt-4">Guardian Information</h2>
                    <p className="text-xl mt-4"><strong className="font-semibold">Name:</strong> {profile.guardian_first_name} {profile.guardian_middle_name} {profile.guardian_last_name}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Guardian Mobile:</strong> {profile.guardian_mobile}</p>
                    <p className="text-xl mt-4"><strong className="font-semibold">Guardian Relationship:</strong> {profile.guardian_relationship}</p>
                </div>
            </div>
        </div>
    );
}

export default StudentProfile;
