import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";

function TeacherDashboard() {
    const { user, token } = useContext(AuthContext);
    //class of the day
    const [classes, setClasses] = useState([]);
    const [todaySchedule, setTodaySchedule] = useState([]);
    const [uniquePeriods, setUniquePeriods] = useState([]);
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const today = new Date();
    const todayDay = daysOfWeek[today.getDay()]; 
   
    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const ttResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/classes`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!ttResponse.ok) {
                    throw new Error('Failed to fetch classes');
                }
                const ttData = await ttResponse.json();
                setClasses(ttData);
                organizeTodaySchedule(ttData);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };
        fetchClasses();
    }, [token, user.user_id]);

    const organizeTodaySchedule = (classData) => {
        const periods = new Set();
        const todayClasses = classData.filter(cls => cls.day_of_week.toLowerCase() === todayDay.toLowerCase());

        todayClasses.forEach(cls => {
            periods.add(cls.period);
        });

        const uniquePeriodsArray = Array.from(periods).sort((a, b) => a.localeCompare(b));
        setUniquePeriods(uniquePeriodsArray);

        const structuredSchedule = uniquePeriodsArray.map(period => {
            return todayClasses.filter(cls => cls.period === period);
        });

        setTodaySchedule(structuredSchedule);
    };

    //upcoming assignments
    const [assignments, setAssignments] = useState([]);
    const [upcomingAssignments, setUpcomingAssignments] = useState([]);
    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/assignments`, {
                'headers': {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                },
            });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setAssignments(data);
            } 
            catch (err) {
                console.log(err);
            } 
        };
        fetchAssignments();
    }, [token, user.user_id]);

    useEffect(() => {
        const currentDate = new Date();
        const upcoming = assignments.filter(assignment => new Date(assignment.assignment_due_date) >= currentDate);
        setUpcomingAssignments(upcoming);
    }, [assignments]);
    

    //attendance rate
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [totals, setTotals] = useState({totalPresent: 0, 
        totalAbsent: 0, 
        totalExcuse: 0, 
        totalLate: 0, 
        presentPercentage: 0, 
        absentPercentage: 0, 
        excusePercentage: 0, 
        latePercentage: 0});

    useEffect(() => {
        const fetchAttendanceRecords = async () => {
         try{ 
            const attendanceResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/attendances`,{
                        headers: {
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                        }
                });
          const attData = await attendanceResponse.json();
          setAttendanceRecords(attData); 
        }
        catch (error) {
          console.error('Error:', error);
        }
      };
        fetchAttendanceRecords();
      }, [token, user.user_id]);


      const calculateTotals = () => {
        const totalPresent = attendanceRecords.filter((attendance) => attendance.status === 'present').length;
        const totalAbsent = attendanceRecords.filter((attendance) => attendance.status === 'absent').length;
        const totalExcuse = attendanceRecords.filter((attendance) => attendance.status === 'excused').length;
        const totalLate = attendanceRecords.filter((attendance) => attendance.status === 'late').length;

        const totalRecords = attendanceRecords.length;
        const presentPercentage = totalPresent > 0 ? (((totalPresent/totalRecords) * 100).toFixed(2)) : 0;
        const absentPercentage = totalAbsent > 0 ? (((totalAbsent / totalRecords) * 100).toFixed(2)): 0;
        const excusePercentage = totalExcuse > 0 ? (((totalExcuse / totalRecords) * 100).toFixed(2)) :0;
        const latePercentage = totalLate >0 ? ((totalLate / totalRecords) * 100).toFixed(2) : 0;
        setTotals({ totalPresent, totalAbsent, totalExcuse, totalLate, presentPercentage, absentPercentage, 
          excusePercentage, latePercentage});
      };

      useEffect(() => {
        calculateTotals();
      }, [attendanceRecords]);

    //student performance
    const [grades, setGrades] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [totalMaximumPoint, setTotalMaximumPoint] = useState(0);
    const [scorePercentage, setScorePercentage] = useState(0);

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/teachers/${user.user_id}/grades`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch grades');
                const gradeData = await response.json();
                setGrades(gradeData);
            } 
            catch (error) {
                console.error('Error fetching grades:', error);
            }
        };
        fetchGrades();
    }, [user.user_id, token]);

    useEffect(() => {
        const total = grades.reduce((acc, curr) => acc + curr.grade_score, 0);
        const maximumPoint = grades.reduce((acc, curr) => acc + curr.assignment_total, 0);

        setTotalScore(total);
        setTotalMaximumPoint(maximumPoint);

        if (maximumPoint > 0) {
            const percentage = ((total /maximumPoint) * 100).toFixed(2);
            setScorePercentage(percentage);
        } 
        else {
            setScorePercentage(0);
        }
    }, [grades]);
    
    return (
        <div className="p-5">
            <h1 className="text-4xl font-bold mb-8 p-5">Dashboard (Teachers View)</h1>

            {/* top cards 
                Show the teacher's schedule only for the day
            */}
            <div className="mt-8 bg-slate-100 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold mb-4">Classes of the day</h2>
                    <div className="flex justify-around">
                        {todaySchedule.length === 0 ? (
                            <div className="w-1/3 bg-sky-200 p-4 text-center shadow-md mx-2 rounded-lg">
                                <p className="text-xl font-semibold">No Classes Today: {todayDay}</p>
                            </div>
                            ) : (
                            uniquePeriods.map((period, index) => (
                                todaySchedule[index].map((classDetail, classIndex) => (
                                    <div className="w-1/3 bg-cyan-200 p-4 text-center shadow-md mx-2 rounded-lg">
                                        <p className="text-xl font-semibold">Subject: {classDetail.course_name}</p>
                                        <p className="text-lg font-light">Room Number:{classDetail.room_number}</p>
                                        <p className="text-lg font-light">Period: {period}</p>
                                        <p className="text-lg font-light">{classDetail.start_time} - {classDetail.end_time}</p>
                                    </div>
                                ))
                            ))
                        )}
                </div>
            </div>

            <div className="mt-8 bg-slate-100 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold mb-4">Attendance Rate</h2> 
                    <div className="flex justify-around mb-4">
             
                        <div className="w-1/4 bg-red-200 p-4 text-center shadow-md mx-2 rounded-lg">
                            <p className="text-xl font-semibold">Present Percent</p>
                            <p className="text-xl font-semibold">{totals.presentPercentage}%</p>
                    
                        </div>
                
                        <div className="w-1/4 bg-purple-200 p-4 text-center shadow-md mx-2 rounded-lg h-full">
                            <p className="text-xl font-semibold">Late Percent</p>
                            <p className="text-xl font-semibold">{totals.latePercentage}%</p>
                        </div>

                        <div className="w-1/4 bg-red-200 p-4 text-center shadow-md mx-2 rounded-lg">
                            <p className="text-xl font-semibold">Execused Percent</p>
                            <p className="text-xl font-semibold">{totals.excusePercentage}%</p>
                        </div>

                        <div className="w-1/4 bg-purple-200 p-4 text-center shadow-md mx-2 rounded-lg h-full">
                            <p className="text-xl font-semibold">Absent Percent</p>
                            <p className="text-xl font-semibold">{totals.absentPercentage}%</p>
                        </div>
                    </div>
             </div>

             <div className="mt-8 bg-slate-100 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold mb-4">Student Performance</h2> 
                    <div className="flex justify-around mb-4">
             
                        <div className="w-1/3 bg-red-200 p-4 text-center shadow-md mx-2 rounded-lg">
                            <p className="text-xl font-semibold">Total Score</p>
                            <p className="text-xl font-semibold">{totalScore}</p>
                    
                        </div>
                
                        <div className="w-1/3 bg-purple-200 p-4 text-center shadow-md mx-2 rounded-lg h-full">
                            <p className="text-xl font-semibold">Total Weight</p>
                            <p className="text-xl font-semibold">{totalMaximumPoint}</p>
                        </div>

                        <div className="w-1/3 bg-red-200 p-4 text-center shadow-md mx-2 rounded-lg">
                            <p className="text-xl font-semibold">Percentage Score</p>
                            <p className="text-xl font-semibold">{scorePercentage}%</p>
                        </div>

                    </div>
             </div>

            {/* Up coming Assignment */}
            <div className="mt-8 bg-slate-100 p-4 shadow-md rounded-md">
                <h2 className="text-lg font-bold mb-4">Up Coming Assignments</h2>
                <table className="w-full">
                    <thead>
                        <tr className="text-left">
                            <th>No.</th>
                            <th>Assignment Title</th>
                            <th>Class</th>
                            <th>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {upcomingAssignments.length === 0 ? (
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-between items-center">
                            <div>
                                <p>No Upcoming Assignments </p>
                            </div>
                        </td>
                    </tr>
                    ) : ( upcomingAssignments.map((assignment, index) => (
                        <tr key={assignment.assignment_id} className="border-b border-gray-200">
                            <td className="py-2">{index +1}</td>
                            <td className="py-2">{assignment.assignment_name}</td>
                            <td className="py-2">{assignment.course_name}</td>
                            <td className="py-2">{new Date(assignment.assignment_due_date).toLocaleString()}</td>
                        </tr>
                         ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TeacherDashboard;