import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../contexts/sidebar";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { useTranslation } from "react-i18next";
import { DefaultViews, RoleBasedViews } from "../../views";

import { VscThreeBars } from "react-icons/vsc";

function NavigationSidebar() {
	const { isNavigationSidebarOpen, setNavigationSidebarOpen } =
		useContext(SidebarContext);
	const { user } = useContext(AuthContext);
	const { t } = useTranslation();
	const location = useLocation();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleLinkClick = () => {
		if (screenWidth <= 768) {
			setNavigationSidebarOpen(false);
		}
	};

	const roles_menu = user
	? Object.keys(RoleBasedViews[user.role_id].routes)
		  .filter((key) => key !== "/studentClassDetail") // Exclude "Class Detail" route
		  .map((key) => {
			  const { icon, label } = RoleBasedViews[user.role_id].routes[key];
			  return { Icon: icon, label, to: key };
		  })
	: [];

	const default_menu = Object.keys(DefaultViews).map((key) => {
		const { icon, label } = DefaultViews[key];
		return { Icon: icon, label, to: key };
	});

	default_menu.forEach((defaultRoute) => {
		const existsInRoles = roles_menu.find(
			(roleRoute) => roleRoute.to === defaultRoute.to,
		);
		if (!existsInRoles) {
			roles_menu.push(defaultRoute);
		}
	});

	//logo view
	const [logoUrl, setLogoUrl] = useState('');
	useEffect(() => {
		const fetchLogo = async () => {
		  try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/assets/logos/${user.school_id}.jpg`, {
				headers: {
					'Authorization': `Bearer ${user.token}`,
				},
		  });
			if (response.ok) {
			  const logoBlob = await response.blob();
			  setLogoUrl(URL.createObjectURL(logoBlob));
			} 
			else {
			  console.error('Failed to fetch logo');
			}
		  } 
		  catch (error) {
			console.error('Error fetching logo:', error);
		  }
		};
		fetchLogo();
	  }, [user]);
	

	return (
		isNavigationSidebarOpen && (
			<div className="fixed md:relative w-full sm:w-1/4 h-screen bg-cyan-950 overflow-auto">
				<img
					src={logoUrl}
					className="p-3 m-0"
					alt="siyaha logo"
				/>
				<button
					className="md:hidden text-white"
					onClick={() => setNavigationSidebarOpen(!isNavigationSidebarOpen)}
				>
					<VscThreeBars className="h-6 w-6" />
				</button>
				<ul className="space-y-3">
					{roles_menu.map((item) => (
						<Link
							key={item.to}
							to={item.to}
							onClick={handleLinkClick}
							className={`${location.pathname === item.to ? "text-white duration-300 bg-cyan-700 hover:bg-cyan-400" : "text-gray-400"} font-semibold transition-all hover:text-white px-6 rounded-md hover:bg-slate-400 py-2 flex items-center space-x-2`}
						>
							{item.Icon && <item.Icon className="h-5 w-5" />}
							<div className="flex truncate">{t(item.label)}</div>
						</Link>
					))}
				</ul>
			</div>
		)
	);
}

export default NavigationSidebar;
