import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../../contexts/auth";
import Modal from 'react-modal';

function StudentGradeReport() {
    const { user, token } = useContext(AuthContext);
    const [grades, setGrades] = useState([]);  //store api response data
    const [subjects, setSubjects] = useState([]); // holds uniques subject in response set
    const [selectedSubject, setSelectedSubject] = useState(''); //holds currently selected subject
    const [subjectDetails, setSubjectDetails] = useState(null); //store details of assessments for selected subject
    const [loading, setLoading] = useState(true);

    // Modal - for pop up
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleSendClick = (subject) => {
        setSelectedSubject(subject);
        const details = grades.filter(item => item.course_name === subject);
        setSubjectDetails(details);
        setModalIsOpen(true);
    };
    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/students/${user.user_id}/grades`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const respondedGrade = await response.json();
                console.log(respondedGrade);
                setGrades(respondedGrade);

                const uniqueSubjects = [...new Set(respondedGrade.map(item => item.course_name))];
                setSubjects(uniqueSubjects);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setLoading(false);
            }
        };
        fetchGrades();
    }, []);

    const calculateSummary = () => {
        return subjects.map(subject => {
            const assessments = grades.filter(item => item.course_name === subject);
            const totalScore = assessments.reduce((total_score, curr_ass_score) => total_score + curr_ass_score.grade_score, 0);
            const totalMax = assessments.reduce((total_max_point, curr_ass_max_point) => total_max_point + curr_ass_max_point.assignment_total, 0);
            const percentage = totalMax > 0 ? ((totalScore / totalMax) * 100).toFixed(2) : 0;

            return {
                subject,
                totalScore,
                totalMax,
                percentage,
            };
        });
    };

    const summary = calculateSummary();

    if (loading) {
        return (
			<div className="flex items-center justify-center min-h-screen">
				<div className="flex flex-col items-center">
					{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
					<svg
						className="animate-spin h-10 w-10 text-blue-500 mb-4"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						/>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
						/>
					</svg>
					<p className="text-lg font-semibold text-gray-600">
						Loading Grade Report...
					</p>
				</div>
			</div>
		);
    }

    return (
        <div className="p-5">
            <h1 className="text-4xl font-bold mb-8 p-5">Grade Report</h1>

            {/*summary*/}
            <div className="p-5">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead className="bg-slate-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Subject </th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Total Point </th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Total Maximum Point </th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Total Score Percentage </th>
                            <th className="px-6 py-3 text-left text-lg font-medium text-gray-700 tracking-wider">  </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {summary.map(item => (
                            <tr key={item.subject} >
                                <td className="px-6 py-4">{item.subject}</td>
                                <td className="px-6 py-4">{item.totalScore}</td>
                                <td className="px-6 py-4">{item.totalMax}</td>
                                <td className="px-6 py-4">{item.percentage}%</td>
                                <td className="px-6 py-4">
                                    <button onClick={() => handleSendClick(item.subject)} className="bg-cyan-800 hover:bg-cyan-950 text-white font-bold py-2 px-4 rounded">
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Detailed Grade Popup*/}
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Inbox Modal" >
                {selectedSubject && subjectDetails && (
                    <div className="bg-slate-200 mt-8 p-4 shadow-md rounded-md">
                        <h1 className="text-xl font-bold mb-4">{selectedSubject} Grade Details</h1>
                        <table className="w-full" >
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Assignment </th>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Point </th>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Max Point </th>
                                    <th className="py-3 text-left text-lg font-medium text-gray-700 tracking-wider"> Percentage </th>
                                </tr>                                    
                            </thead>

                            <tbody>
                                {subjectDetails.map(item => {
                                    const percentage = item.assignment_total > 0 ? ((item.grade_score / item.assignment_total) * 100).toFixed(2) : 0;
                                    return (
                                        <tr key={item.assignment_name} className="border-b border-gray-300">
                                            <td className="py-2 pl-0 -ml-4">{item.assignment_name}</td>
                                            <td className="py-2">{item.grade_score}</td>
                                            <td className="py-2">{item.assignment_total}</td>
                                            <td className="py-2">{percentage}%</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <button type="button" onClick={handleCloseModal} className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded mt-6">
                            Cancel
                        </button>
                    </div>
                )}
            </Modal>

        </div>
    )
};

export default StudentGradeReport;
